import React from 'react';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

import image from 'ui/assets/popup_notify.svg';

interface ArchiveFolderModalProps {
  open: boolean;
  onArchive: () => void;
  onCancel: () => void;
}

export default function ArchiveFolderModal(props: ArchiveFolderModalProps) {
  const { open, onArchive, onCancel } = props;

  return (
    <ConfirmationModal
      open={open}
      title="Archive folder"
      titleOptions={{ color: 'purple' }}
      subtitle={[
        'Archived folders will be removed from the active job list.',
        'It can still be accessed from the Archive tab.',
      ]}
      subtitleOptions={{ color: 'black' }}
      img={{ src: image, alt: 'archive folder' }}
      primary={{ text: 'Archive', color: 'purple', onClick: onArchive }}
      ghost={{ text: 'Cancel', color: 'purple', onClick: onCancel }}
    ></ConfirmationModal>
  );
}
