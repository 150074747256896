import { amplitudeEvent } from 'helper/amplitude';
import moment from 'moment';
import React from 'react';
import { Text, Modal, Emoticon, Spacer, Input, Button, Container } from 'ravenry-ui';
import ConfirmationModal from '../Confirmation/Confirmation';

export default function Feedback(props) {
  const [feel, setFeel] = React.useState(0);
  const [feedback, setFeedback] = React.useState('');
  const { email, userName, domain, onClose, open } = props;
  const [success, setSuccess] = React.useState(false);
  const handleSubmit = () => {
    const sendFeedback = (data) => {
      // Add one line to the sheet
      const url = process.env.REACT_APP_CLIENT_FEEDBACK;
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((r) => r.json())
        .then(() => {
          amplitudeEvent('sent feedback');
          // The response comes here
          onClose();
          setSuccess(true);
          setFeedback('');
          setFeel(0);
        })
        .catch((error) => {
          // Errors are reported there
          console.error(error);
        });
    };

    const data = {
      Name: userName,
      Email: email,
      Domain: domain,
      Rating:
        feel === 1
          ? 'Very disatisfied'
          : feel === 2
          ? 'Disatisfied'
          : feel === 3
          ? 'Neutral'
          : feel === 4
          ? 'Satified'
          : feel === 5
          ? 'Very Satisfied'
          : 'Not measured',
      Feedback: feedback,
      Timestamp: moment.tz(new Date(), 'Asia/Jakarta').format('LLL'),
    };

    sendFeedback(data);
  };

  return (
    <>
      <Modal header="Send feedback" open={open} width="576">
        <Spacer size="24" />
        <Text color="black40">HOW DO YOU FEEL ABOUT OUR PLATFORM?</Text>
        <Spacer size="16" />

        <Emoticon value={feel} onChange={setFeel} />
        <Spacer size="16" />
        <Text color="black40">YOUR FEEDBACK</Text>
        <Input.Textarea fluid onChange={(e) => setFeedback(e.target.value)}>
          {feedback}
        </Input.Textarea>
        <Container flex end fluid>
          <Button color="blue" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Spacer size="16" />

          <Button color="blue" onClick={handleSubmit} disabled={feel === 0 || feedback === ''}>
            Submit
          </Button>
        </Container>
      </Modal>

      <ConfirmationModal
        success
        type="success"
        open={success}
        title="Feedback has been submitted"
        subtitle="Thank you for your feedback."
        onPrimaryClick={() => {
          setSuccess(false);
        }}
      />
    </>
  );
}
