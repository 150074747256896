import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { Col, Container, Dropdown, Icon, Input, Row, Spacer, Text } from 'ravenry-ui';
import { Fields } from 'ui/molecules';
import colors from 'ui/colors';
import { useOutsideClick } from 'hooks/useOutsideClick';

const Root = styled.div<{ width?: string | number }>`
  ${({ width }) => width && `width:${width};`}
`;

const AddTypeContainer = styled(Row)`
  cursor: pointer;
  margin-left: -2px;

  &:hover > p {
    color: ${colors.black};
  }

  &:hover > svg > path {
    fill: ${colors.black};
  }
`;

export interface Item {
  text: string;
  checked?: boolean;
  // any to force other items unchecked, add to add an additional item
  type?: 'any' | 'add' | 'dropdown';
}

interface CheckboxListProps {
  title?: string;
  items?: Item[];
  subItems?: {
    title?: string;
    items: Item[];
  }[];
  customOptions?: string[];
  customActionText?: string;
  customActionPlaceholder?: string;
  customOptionWithFreeText?: boolean;

  itemsStyle?: { col: number; width: string; justifyContent?: 'flex-start' | 'space-between' };
  subItemsStyle?: {
    row?: boolean;
    columnWidth?: string;
    wrap?: boolean;
    justifyContent?: 'flex-start' | 'space-between';
  };

  onChange: ({
    updatedItems,
    updatedSubItems,
  }: {
    updatedItems?: Item[];
    updatedSubItems?: {
      title?: string;
      items: Item[];
    }[];
  }) => void;

  width?: string | number;
}

export default function CheckboxList(props: CheckboxListProps) {
  const {
    title,
    items = [],
    subItems = [],
    customOptions = [],
    customActionText = '',
    customActionPlaceholder = '',
    customOptionWithFreeText = false,
    onChange,
    itemsStyle = { col: 1, width: 'inherit', justifyContent: 'space-between' },
    subItemsStyle = {},
    width,
  } = props;

  const [search, setSearch] = useState('');
  const [dropdown, setDropdown] = useState({ open: false });
  const ref = useRef(null);

  const handleChange = (clickedItem: Item) => {
    if (clickedItem.type === 'any') {
      onChange({
        updatedItems: items.map((i) => ({ ...i, checked: i.type === 'any' })),
        updatedSubItems: subItems.map((s) => ({
          ...s,
          items: s.items.map((i) => ({ ...i, checked: false })),
        })),
      });
    } else if (clickedItem.type === 'add') {
      onChange({
        updatedItems: items.map((i) => (i.type === 'add' ? { ...i, type: 'dropdown' } : i)),
      });
    } else {
      let updatedItems = items.map((i) => ({
        ...i,
        checked: i.type === 'any' ? false : i.text === clickedItem.text ? !i.checked : i.checked,
      }));

      if (updatedItems.filter((i) => i.checked).length === 0) {
        updatedItems = updatedItems.map((i) => ({ ...i, checked: i.type === 'any' }));
      }

      onChange({
        updatedItems,
      });
    }
  };

  const handleChangeSubItem = (clickedSubItem: Item) => {
    const updatedSubItems = subItems.map((s) => ({
      ...s,
      items: s.items.map((i) => ({
        ...i,
        checked: i.text === clickedSubItem.text ? !i.checked : i.checked,
      })),
    }));

    const noSubItemsChecked =
      updatedSubItems
        .map((subItem) => subItem.items)
        .reduce((prev, curr) => [...prev, ...curr], [])
        .filter((i) => i.checked).length === 0;

    const updatedItems = noSubItemsChecked
      ? items.map((i) => ({ ...i, checked: i.type === 'any' }))
      : items.map((i) => ({ ...i, checked: i.type === 'any' ? false : i.checked }));

    onChange({
      updatedItems,
      updatedSubItems,
    });
  };

  const handleDropdownClick = (newItem: string) => {
    setSearch('');

    const firstUncheckedPosition = items.findIndex(
      (i) => !['any', 'dropdown', 'add'].includes(i.type || '') && !i.checked,
    );

    let updatedItems = items;

    if (firstUncheckedPosition >= 0) {
      updatedItems = [
        ...items.map((i, index) =>
          i.type === 'any'
            ? { ...i, checked: false }
            : index === firstUncheckedPosition
            ? { checked: true, text: newItem }
            : i.type === 'dropdown'
            ? {
                type: 'add' as 'add' | 'dropdown' | 'any',
                text: customActionText,
              }
            : i,
        ),
      ];
    } else {
      updatedItems = [
        ...items.map((i) =>
          i.type === 'any'
            ? { ...i, checked: false }
            : i.type === 'dropdown'
            ? { checked: true, text: newItem }
            : i,
        ),
        {
          type: 'add',
          text: customActionText,
        },
      ];
    }

    onChange({
      updatedItems,
    });
  };

  useOutsideClick(ref, () => {
    setDropdown({ ...dropdown, open: false });
  });

  return (
    <Root width={width}>
      <Text _as="s5" bold>
        {title}
      </Text>

      <Spacer size="8" display="block" />

      <Row justifyContent={itemsStyle.justifyContent}>
        {Array.from({ length: itemsStyle.col }, (_, i) => i).map(
          (col, colIndex, { length: totalColumn }) => (
            <Col key={'col-type-index-' + colIndex} width={itemsStyle.width}>
              {items
                .slice(
                  colIndex * Math.ceil(items.length / totalColumn),
                  (colIndex + 1) * Math.ceil(items.length / totalColumn),
                )
                .map((item, i, { length }) => (
                  <>
                    {item.type === 'add' ? (
                      <AddTypeContainer alignItems="center" onClick={() => handleChange(item)}>
                        <Icon name="plus" />
                        <Spacer size="8" />
                        <Text _as="s5" color="black60">
                          {item.text}
                        </Text>
                      </AddTypeContainer>
                    ) : item.type === 'dropdown' ? (
                      <div>
                        <Input
                          placeholder={customActionPlaceholder || customActionText}
                          onChange={(e: React.SyntheticEvent) =>
                            setSearch((e.target as HTMLInputElement).value)
                          }
                          onMouseUp={() => {
                            setDropdown({ ...dropdown, open: true });
                          }}
                        />

                        {dropdown.open && (
                          <>
                            <Spacer size="8" display="block" />

                            <div ref={ref}>
                              <Dropdown
                                style={{
                                  width: '100%',
                                  zIndex: 1,
                                  maxHeight: '220px',
                                  overflowY: 'auto',
                                }}
                              >
                                {customOptions
                                  .filter((opt) => opt.toLowerCase().includes(search.toLowerCase()))
                                  .filter((opt) => !items.map((i) => i.text).includes(opt))
                                  .map((opt, i) => (
                                    <Dropdown.Item
                                      key={'dropdown-item-' + i}
                                      onClick={() => {
                                        handleDropdownClick(opt);

                                        setDropdown({ ...dropdown, open: false });
                                      }}
                                    >
                                      {opt}
                                    </Dropdown.Item>
                                  ))}

                                {customOptionWithFreeText && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      handleDropdownClick(search);

                                      setDropdown({ ...dropdown, open: false });
                                    }}
                                  >
                                    Add &quot;
                                    {search}
                                    &quot;
                                  </Dropdown.Item>
                                )}
                              </Dropdown>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <Fields.Check
                        id={item.text}
                        name={item.text}
                        checked={item.checked}
                        onChange={() => handleChange(item)}
                        htmlFor={item.text}
                        data-cy={item.text}
                      >
                        {item.text}
                      </Fields.Check>
                    )}

                    {i < length - 1 && <Spacer size="8" display="block" />}
                  </>
                ))}
            </Col>
          ),
        )}
      </Row>

      {subItems.length > 0 && (
        <>
          <Spacer size="8" display="block" />

          <Container
            flex
            fluid
            row={subItemsStyle.row}
            column={!subItemsStyle.row}
            justifyContent={subItemsStyle.justifyContent || 'space-between'}
            wrap={subItemsStyle.wrap}
          >
            {subItems.map((subitem, i, { length }) => (
              <Col key={'sub-item-' + i} width={subItemsStyle.columnWidth}>
                <Text _as="s6" bold>
                  {subitem.title}
                </Text>
                <Spacer size="8" display="block" />
                {subitem.items.map((item, i, { length }) => (
                  <>
                    <Fields.Check
                      id={item.text}
                      name={item.text}
                      checked={item.checked}
                      onChange={() => handleChangeSubItem(item)}
                      htmlFor={item.text}
                    >
                      {item.text}
                    </Fields.Check>

                    {i < length - 1 && <Spacer size="8" display="block" />}
                  </>
                ))}
                {i < length - 1 && <Spacer size="8" display="block" />}
              </Col>
            ))}
          </Container>
        </>
      )}
    </Root>
  );
}
