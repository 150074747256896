import React, { useState } from 'react';
import styled from 'styled-components';
import uuid from 'uuid';

import popup_cancel from 'ui/assets/popup_cancel.svg';
import { Button, Col, Container, Icon, Row, Segment, Spacer, Text } from 'ravenry-ui';
import { Fields, Modal, Search } from 'ui/molecules';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';
import colors from 'ui/colors';
import { amplitudeEvent } from 'helper/amplitude';

interface AddToFolderModalsProps {
  open: boolean;
  payload: {
    _id: string | null;
  };
  onAdd: (_id?: string, name?: string) => void;
  onQuit: () => void;
  // retrieved folders
  folders?: any[];
  hideNewButton?: boolean;
  mode?: 'move' | 'add';
}

const CustomizedContainer = styled(Container)<{ selected?: boolean }>`
  cursor: pointer;

  &:hover > p {
    color: ${({ selected }) => (selected ? colors.black : colors.black80)};
  }

  &:hover > svg > path {
    fill: ${({ selected }) => (selected ? colors.black : colors.black80)};
  }
`;

export default function AddToFolderModals(props: AddToFolderModalsProps) {
  const { open, onQuit, onAdd, folders = [], mode = 'add', hideNewButton = false } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [folderName, setFolderName] = useState('');
  const [error, setError] = useState('');
  const [modalCreateFolder, setModalCreateFolder] = useState({ open: false });
  const [modalQuit, setModalQuit] = useState({ open: false });
  const [modalAttention, setModalAttention] = useState({ open: false });

  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [foldersToCreate, setFoldersToCreate] = useState<
    {
      name: string;
      _id: string;
      jobs: any[];
    }[]
  >([]);

  const foldersDisplayed = [...folders, ...foldersToCreate].sort((a, b) =>
    (a.name?.toLowerCase() || '') > (b.name?.toLowerCase() || '') ? 1 : -1,
  );

  const title = mode === 'add' ? 'Add to folder' : 'Move to folder';

  return (
    <>
      <Modal open={open} padding="0" outerPadding="24px" width="552px">
        <Text _as="s3" bold>
          {title}
        </Text>
        <Spacer size="8" display="block" />
        <Text _as="b3" color="black80">
          Move this job to a different folder.
        </Text>
        <Spacer size="16" display="block" />

        <Text _as="s5" bold>
          SELECT A JOB FOLDER
        </Text>

        <Spacer size="8" display="block" />
        <Search
          placeholder="Search folder"
          width="100%"
          withoutMarginRight
          onSearch={(query: string) => setSearchQuery(query)}
        />
        <Spacer size="16" display="block" />

        <Segment
          color="backgroundLight"
          padding="0"
          borderRadius="5px"
          style={{ height: '274px', overflowY: 'auto' }}
        >
          {foldersDisplayed.length > 0 ? (
            foldersDisplayed
              .filter((f) => f.name?.toLowerCase().includes(searchQuery))
              .map((f) => (
                <React.Fragment key={`folder-${f._id}`}>
                  <CustomizedContainer
                    fluid
                    flex
                    alignItems="center"
                    padding="16px"
                    color={selectedFolderId === f._id ? 'black5' : 'backgroundWhite'}
                    hoverColor="black5"
                    selected={selectedFolderId === f._id}
                    onClick={() => setSelectedFolderId(f._id)}
                  >
                    <Icon name="folder" fill={selectedFolderId === f._id ? 'black' : 'black60'} />
                    <Spacer size="8" />
                    <Text
                      color={selectedFolderId === f._id ? 'black' : 'black60'}
                      bold={selectedFolderId === f._id}
                    >
                      {f.name}
                    </Text>
                  </CustomizedContainer>
                </React.Fragment>
              ))
          ) : (
            <Container fluid flex column justifyContent="center" alignItems="center" height="100%">
              <Text _as="s5" color="black60">
                You don&apos;t have any folders right now
              </Text>
              <Spacer size="16" display="block" />
              {!hideNewButton && (
                <Button
                  startIcon="plus"
                  iconFill="blue"
                  variant="outlined"
                  onClick={() => setModalCreateFolder({ ...modalCreateFolder, open: true })}
                >
                  New folder
                </Button>
              )}
            </Container>
          )}
        </Segment>

        <Spacer size="16" display="block" />

        <Row justifyContent="space-between">
          <Col>
            {foldersDisplayed.length > 0 && !hideNewButton && (
              <Button
                startIcon="plus"
                iconFill="blue"
                variant="outlined"
                onClick={() => setModalCreateFolder({ ...modalCreateFolder, open: true })}
              >
                New folder
              </Button>
            )}
          </Col>

          <Row>
            <Button
              color="blue"
              variant="outlined"
              onClick={() => {
                const anyFoldersWithNoJob = foldersToCreate.some((f) => f.jobs.length === 0);

                if (anyFoldersWithNoJob) {
                  setModalQuit({ ...modalQuit, open: true });
                } else {
                  setSelectedFolderId(null);
                  setFoldersToCreate([]);
                  onQuit();
                }
              }}
            >
              Quit
            </Button>
            <Spacer size="16" horizontal />
            <Button
              color="blue"
              disabled={!selectedFolderId}
              onClick={() => {
                if (mode === 'move') {
                  setModalAttention({ ...modalAttention, open: true });
                } else {
                  const isNewFolder = !!foldersDisplayed.find((f) => f._id === selectedFolderId)
                    ?.unsaved;

                  if (isNewFolder) {
                    const name =
                      foldersToCreate.find((f) => f._id === selectedFolderId)?.name || '';

                    onAdd(undefined, name);
                    amplitudeEvent('added new folder');
                  } else {
                    const id = folders.find((f) => f._id === selectedFolderId)._id;
                    amplitudeEvent('move to new folder');
                    onAdd(id);
                  }
                  setSelectedFolderId(null);
                  setFoldersToCreate([]);
                }
              }}
            >
              Move
            </Button>
          </Row>
        </Row>
      </Modal>

      {modalCreateFolder.open ? (
        <Modal
          open={modalCreateFolder.open}
          padding="0"
          outerPadding="24px"
          width="552px"
          header=""
        >
          <Text _as="s3" bold>
            Create a new folder
          </Text>
          <Spacer size="8" display="block" />
          <Text _as="b3" color="black80">
            Get organised by adding jobs into folders.
          </Text>
          <Spacer size="16" display="block" />

          <Fields.Text
            label="FOLDER NAME"
            _labelAs="s5"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            onFocus={() => setError('')}
            placeholder="Type in your folder name"
            error={error}
            fluid
          />
          <Spacer size="16" display="block" />

          <Row justifyContent="flex-end">
            <Button
              color="blue"
              variant="outlined"
              onClick={() => {
                setModalCreateFolder({ ...modalCreateFolder, open: false });
                setFolderName('');
                setSearchQuery('');
              }}
              width="110px"
            >
              Cancel
            </Button>
            <Spacer size="16" horizontal />
            <Button
              color="blue"
              onClick={() => {
                if (folderName.trim().length === 0) {
                  setError('Please create a name for this folder');
                } else {
                  setFoldersToCreate((latestState) => {
                    const updatedFolders = [
                      ...latestState,
                      { name: folderName, _id: uuid.v4(), jobs: [], unsaved: true },
                    ];

                    return updatedFolders.sort((a, b) =>
                      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
                    );
                  });
                  // onCreateFolder(folderName);
                  setModalCreateFolder({ ...modalCreateFolder, open: false });
                  setFolderName('');
                }
              }}
              width="150px"
            >
              Create folder
            </Button>
          </Row>
        </Modal>
      ) : null}

      {modalQuit.open && (
        <ConfirmationModal
          open={modalQuit.open}
          img={{ src: popup_cancel, alt: 'quit' }}
          title="Quit"
          titleOptions={{ color: 'red' }}
          subtitle="If the folder is empty, the folder will be automatically removed."
          subtitleOptions={{ color: 'black' }}
          primary={{
            text: 'Cancel',
            color: 'red',
            onClick: () => setModalQuit({ ...modalQuit, open: false }),
          }}
          ghost={{
            text: 'Yes, let me quit',
            color: 'red',
            onClick: () => {
              setModalQuit({ ...modalQuit, open: false });
              setSelectedFolderId(null);
              setFoldersToCreate([]);
              onQuit();
            },
          }}
        />
      )}

      {modalAttention.open && (
        <ConfirmationModal
          open={modalAttention.open}
          img={{ src: popup_cancel, alt: 'attention' }}
          title="Attention"
          titleOptions={{ color: 'red' }}
          subtitle={[
            'Are you sure you want to move the job?',
            'If the folder is empty, the folder will be automatically removed.',
          ]}
          subtitleOptions={{ color: 'black' }}
          primary={{
            text: 'Cancel',
            color: 'red',
            onClick: () => setModalAttention({ ...modalAttention, open: false }),
          }}
          ghost={{
            text: 'Yes, move it anyway',
            color: 'red',
            padding: '0 6px',
            onClick: () => {
              setModalAttention({ ...modalAttention, open: false });

              const isNewFolder = !!foldersDisplayed.find((f) => f._id === selectedFolderId)
                ?.unsaved;

              if (isNewFolder) {
                const name = foldersToCreate.find((f) => f._id === selectedFolderId)?.name || '';

                onAdd(undefined, name);
              } else {
                const id = folders.find((f) => f._id === selectedFolderId)._id;

                onAdd(id, undefined);
              }
            },
          }}
        />
      )}
    </>
  );
}
