import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isMobile } from 'react-device-detect';

import { Avatar, Icon, Text, Row, Divider, Col, Spacer, UnreadDot } from 'ravenry-ui';
import colors from 'ui/colors';
import { useOutsideClick } from 'hooks/useOutsideClick';
import Empty from '../Empty/Empty';
import IconButton from '../IconButton/IconButton';

/**
 * Notification
 *
 * @param {Object} props - React props
 * @param {import("react").CSSProperties} [props.style] - inline style
 * @param {[]} props.notifications - list of notifs
 * @param {Function} props.onReadAll - callback when mark all as read clicked
 * @param {Function} props.onReadSelected - callback when one notif clicked
 * @param {Function} props.onNext - callback when notif is scrolled
 * @param {Boolean} props.hasMoreNotif - flag to decide to fetch more notif or not
 * @param {Number} props.unreadNotifs - number of unread notifs
 */

const NotifContainer = styled.div`
  margin: auto;
  position: relative;
`;

const NotifHeader = styled(Row)`
  padding: 24px;
  justify-content: space-between;
  align-items: center;
`;

const NotifListWrapper = styled.div`
  overflow-y: auto;
  max-height: ${isMobile ? 'calc(100vh - 145px)' : '450px'};
`;

const NotifList = styled(Row)`
  padding: ${isMobile ? '24px' : '24px 16px'};
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background: ${colors.backgroundLight};
  }
`;

const NotifDropdown = styled.div`
  ${isMobile
    ? css`
        position: absolute;
        right: -30px;
        height: 100vh;
        top: -25px;
        width: 100vw;
        background-color: ${colors.backgroundWhite};
      `
    : css`
        position: absolute;
        right: 0;
        top: 35px;
        width: 450px;
        border: 1px solid ${colors.black5};
        background-color: ${colors.backgroundWhite};

        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
      `}
`;

const RedDot = styled.div`
  position: absolute;
  background-color: ${colors.red90};
  width: 8px;
  height: 8px;
  top: 9px;
  right: 8px;
  border-radius: 50%;
  z-index: 2;
`;

export default function Notification(props) {
  const { notifications, style, onReadAll, unreadNotifs, onReadSelected, hasMoreNotif, onNext } =
    props;

  const [dropdown, setDropdown] = useState({ open: false });
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    setDropdown({ ...dropdown, open: false });
  });

  return (
    <NotifContainer
      style={style}
      onClick={() => setDropdown({ ...dropdown, open: !dropdown.open })}
    >
      <div ref={ref} style={{ position: 'relative' }}>
        {unreadNotifs > 0 && <RedDot />}
        <IconButton name="bell" color="black" hoverColor="black" active={dropdown.open} />
      </div>
      {dropdown.open ? (
        <NotifDropdown>
          {isMobile ? (
            <NotifHeader>
              <div>
                <Text size="18px" lineHeight="24px" color="black100">
                  Notifications
                </Text>
                <Text
                  _as="b3"
                  color="black40"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onReadAll();
                    setDropdown({ ...dropdown, open: false });
                  }}
                >
                  Mark all as read
                </Text>
              </div>
              <Icon
                size="32px"
                name="x"
                onClick={() => setDropdown({ ...dropdown, open: false })}
              />
            </NotifHeader>
          ) : (
            <NotifHeader>
              <Text size="18px" lineHeight="24px" color="black100">
                Notifications
              </Text>
              <Text
                _as="b3"
                color="black40"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onReadAll();
                  setDropdown({ ...dropdown, open: false });
                }}
              >
                Mark all as read
              </Text>
            </NotifHeader>
          )}
          <Divider color="black10" margin="0" />
          {Array.isArray(notifications) ? (
            notifications.length ? (
              <NotifListWrapper>
                <InfiniteScroll
                  height={isMobile ? 'calc(100vh - 145px)' : '450px'}
                  dataLength={notifications.length}
                  next={onNext}
                  hasMore={hasMoreNotif}
                >
                  {notifications.map((notif) => (
                    <NotifList
                      noWrap
                      key={`notif-${notif._id}`}
                      onClick={() => {
                        onReadSelected(notif);
                        setDropdown({ ...dropdown, open: false });
                      }}
                    >
                      <Row noWrap>
                        <Avatar
                          url={notif.fromAvatar}
                          style={{ margin: '0' }}
                          name={notif.fromName}
                        />
                        <Spacer size="16" />
                        <Col style={{ flex: 1, minWidth: 0 }}>
                          <Text _as="b3" bold color="black100" fluid>
                            {notif.title}
                          </Text>
                          <Spacer size="2" />
                          <Text _as="b3" color="blue100" fluid>
                            {notif.subtitle}
                          </Text>
                          <Spacer size="2" />
                          <Text _as="b3" bold color="black40">
                            {moment(notif.createdAt).format('D MMM YYYY - hh:mm A')}
                          </Text>
                        </Col>
                      </Row>
                      <UnreadDot
                        isHidden={notif.isRead}
                        style={{ minWidth: '8px', marginLeft: '8px' }}
                      />
                    </NotifList>
                  ))}
                </InfiniteScroll>
              </NotifListWrapper>
            ) : (
              <Col alignItems="center">
                <Spacer size="24" />
                <Empty text="Currently, there's no notification for you" />
                <Spacer size="24" />
              </Col>
            )
          ) : (
            <Col alignItems="center" justifyContent="center">
              <Spacer size="16" />
              <Text _as="b3" color="black60">
                Loading notification data . . .
              </Text>
              <Spacer size="16" />
            </Col>
          )}
        </NotifDropdown>
      ) : null}
    </NotifContainer>
  );
}
