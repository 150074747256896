import React from 'react';
import { Divider, Icon, Row, Spacer, Text } from 'ravenry-ui';

export default function Header(props) {
  const { children, onClose } = props;

  return (
    <>
      <Row alignItems="center" justifyContent="space-between">
        <Text _as="s2" bold color="blue">
          {children}
        </Text>
        <Icon name="x" clickable onClick={onClose} fill="black" />
      </Row>

      <Spacer size="8" display="block" />
      <Divider offset="24" color="blue" />
    </>
  );
}
