import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Icon } from 'ravenry-ui';
import colors from 'ui/colors';

const Navigation = styled(Row)`
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 32px;

  background: ${colors.white};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  &:hover {
    background: ${colors.black5};

    box-shadow: px 2px 3px rgba(0, 0, 0, 0.1);
  }

  &:hover > svg {
    fill: ${colors.blue};
  }
`;

interface NextProps {
  onClick: () => void;
}

function Next({ onClick }: NextProps) {
  return (
    <Navigation onClick={onClick} data-cy="navigation-next">
      <Icon name="chevron right" />
    </Navigation>
  );
}
interface PrevProps {
  onClick: () => void;
}

function Prev({ onClick }: PrevProps) {
  return (
    <Navigation onClick={onClick} data-cy="navigation-prev">
      <Icon name="chevron left" />
    </Navigation>
  );
}

export default class Fields extends Component {
  static Prev = Prev;
  static Next = Next;

  render() {
    return <Navigation {...this.props} />;
  }
}
