import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
const env = process.env.REACT_APP_ENV;
const moment = require('moment');

if (env === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: env,
    release: moment().format('DDMMYYYYHH'),

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  ReactDOM.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary
        onError={() =>
          alert(
            'Sorry, something went wrong and we are working on it.\nPlease try refreshing the page.\nIf the problem still persists, please contact us at info@theravenry.com',
          )
        }
      >
        <App />
      </Sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
