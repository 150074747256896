import React from 'react';

import Responsive from 'helper/responsive';
import { Col, Modal, Text, Row, Spacer, Button } from 'ravenry-ui';

import popup_payment from '../../assets/popup_payment.svg';
import popup_success from '../../assets/popup_success.svg';
import popup_accept from '../../assets/popup_accept.svg';
import popup_decline from '../../assets/popup_decline.svg';
import popup_delete from '../../assets/popup_delete.svg';
import popup_edit from '../../assets/popup_edit.svg';
import popup_add from '../../assets/popup_add.svg';
import popup_cancel from '../../assets/popup_cancel.svg';
import popup_checkerboard from '../../assets/popup_checkerboard.svg';
import popup_notify from '../../assets/popup_notify.svg';
import popup_publish from '../../assets/popup_publish.svg';

/**
 * ConfirmationModal
 *
 * @param {Object} props
 * @param {Boolean} props.open - flag to open modal
 * @param {{src: String, alt: String}} [props.img] - data of image
 * @param {String} props.title - title of modal
 * @param {String | String[]} props.subtitle - copy of modal subtitle
 * @param {React.ReactNode} [props.infix] - element between subtitle and action buttons
 * @param {Object} props.primary -
 * @param {String} props.primary.color
 * @param {Function} props.primary.onClick
 * @param {String} props.primary.text
 * @param {Object} [props.ghost] -
 * @param {String} props.ghost.color
 * @param {Function} props.ghost.onClick
 * @param {String} props.ghost.text
 * @param {"add" | "delete" | "cancel_edit" |
 *         "edit" | "success" | "error" | "cancel_offer" |
 *         "submit_offer" | "payment" | "notify_client" |
 *         "start_job" | "complete_job" |
 *         "accept_job" | "decline_job" | "notify" |
 *         "publish"} [props.type] - type of the confirmation modal
 * @param {String} [props.name] - name of the modal
 * @param {Function} [props.onPrimaryClick] - function to handle click on primary button
 * @param {Function} [props.onGhostClick] - function to handle click on ghost button
 */
export default function ConfirmationModal(props) {
  const { open, img, name, type, color, onPrimaryClick, onGhostClick, infix } = props;
  const { isMobile } = Responsive();
  const srcOptions = {
    add: popup_add,
    edit: popup_edit,
    cancel_edit: popup_cancel,
    delete: popup_delete,
    success: popup_success,
    cancel_offer: popup_cancel,
    submit_offer: popup_accept,
    payment: popup_payment,
    summary: popup_checkerboard,
    notify_client: popup_notify,
    notify: popup_notify,
    start_job: popup_success,
    complete_job: popup_success,
    delete_job: popup_cancel,
    accept_job: popup_accept,
    decline_job: popup_decline,
    publish: popup_publish,
    error: popup_cancel,
  };
  const src = img?.src || srcOptions[type];

  const altOptions = {
    add: 'add',
    edit: 'edit',
    cancel_edit: 'cancel',
    delete: 'delete',
    success: 'success',
    error: 'error',
    cancel_offer: 'cancel',
    payment: 'payment',
    notify_client: 'notify client',
    start_job: 'start job',
    complete_job: 'complete job',
    accept_job: 'accept job',
    decline_job: 'decline job',
  };
  const alt = img?.alt || altOptions[type];

  const titleOptions = {
    add: `Add ${name}`,
    edit: `Save ${name}`,
    cancel_edit: 'Cancel Editing',
    delete: `Delete ${name}`,
    success: 'Success',
    error: 'Sorry, it’s not you. It’s us.',
    cancel_offer: 'Cancel Offer',
    payment: 'Make Payment',
    notify_client: 'Notify Client',
    start_job: 'Start the job',
    complete_job: 'Set as complete',
    accept_job: 'Interested in the job',
    decline_job: 'Not interested in the job',
  };
  const title = props.title || titleOptions[type];

  const subtitleOptions = {
    add: 'Do you want to add this info?',
    edit: 'Do you want to save this info?',
    cancel_edit: ['Are you sure?', 'All unsaved changes will be lost'],
    delete: [
      `Are you sure you want to delete this ${name?.toLowerCase()}?`,
      'This process cannot be undone',
    ],
    success: 'Something is success',
    error:
      'We are experiencing an internal server problem. If the problem still occur please try again later.',
    cancel_offer: ['Are you sure?', 'All unsaved changes will be lost'],
    payment: [
      'Please check your email to receive instructions for making payments.',
      'Your job will start once payment is made.',
    ],
    notify_client: [
      'Attention! make sure that you already upload or submitted the',
      'finished job on the Attachment.',
      'You can not change this later',
    ],
    start_job: [
      'Are you sure you want to start the job? Before starting,',
      'make sure the client already complete the payment.',
    ],
    complete_job: 'Are you sure you want to complete the job?',
    accept_job: [
      'By agreeing with this, you will get the opportunity to be matched',
      'with our client.',
    ],
    decline_job: 'Are you sure you want to decline this opportunity?',
  };
  const subtitle = props.subtitle || subtitleOptions[type];
  // convert string parameter to array of strings to acommodate multiple line text
  const subtitles = [].concat(subtitle || []);

  const primaryOptions = {
    add: {
      color: 'lightBlue100',
      text: 'Add',
      onClick: onPrimaryClick,
    },
    edit: {
      color: 'lightBlue100',
      text: 'Save',
      onClick: onPrimaryClick,
    },
    cancel_edit: {
      color: 'red100',
      text: 'No, let me save',
      onClick: onPrimaryClick,
    },
    delete: {
      color: 'red100',
      text: 'Cancel',
      onClick: onPrimaryClick,
    },
    success: {
      color: 'lightBlue',
      text: 'Ok',
      onClick: onPrimaryClick,
    },
    error: {
      color: 'lightBlue',
      text: 'Reload',
      onClick: onPrimaryClick,
    },

    cancel_offer: {
      color: 'red100',
      text: 'No, let me offer',
      onClick: onPrimaryClick,
    },
    payment: {
      color: 'green100',
      text: 'Ok',
      onClick: onPrimaryClick,
    },
    notify_client: {
      color: 'green100',
      text: 'Notify',
      onClick: onPrimaryClick,
    },
    start_job: {
      color: 'green100',
      text: 'Start',
      onClick: onPrimaryClick,
    },
    complete_job: {
      color: 'green100',
      text: 'Complete',
      onClick: onPrimaryClick,
    },
    accept_job: {
      color: 'lightBlue100',
      text: 'Proceed',
      onClick: onPrimaryClick,
    },
    decline_job: {
      color: 'red100',
      text: 'Cancel',
      onClick: onPrimaryClick,
    },
  };
  const primary = props.primary || primaryOptions[type];

  const ghostOptions = {
    add: {
      color: 'lightBlue100',
      text: 'Cancel',
      onClick: onGhostClick,
    },
    edit: {
      color: 'lightBlue100',
      text: 'Cancel',
      onClick: onGhostClick,
    },
    cancel_edit: {
      color: 'red100',
      text: "Yes, I'm sure",
      onClick: onGhostClick,
    },
    delete: {
      color: 'red100',
      text: 'Delete',
      onClick: onGhostClick,
    },
    cancel_offer: {
      color: 'red100',
      text: "Yes, I'm sure",
      onClick: onGhostClick,
    },
    payment: null,
    notify_client: {
      color: 'green100',
      text: 'Cancel',
      onClick: onGhostClick,
    },
    start_job: {
      color: 'green100',
      text: 'Cancel',
      onClick: onGhostClick,
    },
    complete_job: {
      color: 'green100',
      text: 'Cancel',
      onClick: onGhostClick,
    },
    accept_job: {
      color: 'lightBlue100',
      text: 'Cancel',
      onClick: onGhostClick,
    },
    decline_job: {
      color: 'red100',
      text: 'Decline',
      onClick: onGhostClick,
    },
  };
  const ghost = props.ghost || ghostOptions[type];

  return (
    <Modal padding="4px" open={open} width={isMobile ? '100%' : '480px'}>
      <Col alignItems="center">
        <img src={src} alt={alt} style={{ width: '200px', heigth: '100px' }} />
        <Spacer size="24" />
        <Text size="18px" bold color={color}>
          {title}
        </Text>
        <Spacer size="16" />
        {subtitles.map((subtitle, index) => (
          <Text _as="b2" color="black" key={`subtitle-${subtitle}-${index}`} textAlign="center">
            {subtitle}
          </Text>
        ))}
        {infix}
        <Spacer size="24" />
        <Row justifyContent="center" style={{ width: '100%' }}>
          {ghost && (
            <>
              <Button
                onClick={() => ghost.onClick()}
                color={ghost.color}
                variant="outlined"
                id={ghost.id}
              >
                {ghost.text}
              </Button>
              <Spacer size="16" display="block" />
            </>
          )}
          <Button
            onClick={() => primary.onClick()}
            color={primary.color}
            id={primary.id}
            data-cy={primary.text + '-confirmation'}
          >
            {primary.text}
          </Button>
        </Row>
      </Col>
      {props.children}
    </Modal>
  );
}
