import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import {
  getFirestore,
  query,
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  addDoc,
  onSnapshot,
  orderBy,
  QuerySnapshot,
  updateDoc,
  arrayUnion,
  setDoc,
  serverTimestamp,
  writeBatch,
  limit as setLimit,
  runTransaction,
} from 'firebase/firestore';
import { ObjectID } from 'bson';
import { getCookie } from './utils';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const login = async (token: string) => {
  try {
    const res = await signInWithCustomToken(auth, token);
  } catch (err) {
    console.error(err);
  }
};

const logout = () => {
  signOut(auth);
};

const listenToChatMessages = (roomId: string, callback: (snapshot: QuerySnapshot) => void) => {
  const q = query(collection(db, `rooms/${roomId}/messages`), orderBy('createdAt'));
  return onSnapshot(q, callback);
};

const sendMessage = async (
  roomId: string,
  payload: {
    from: string;
    avatar: string;
    email: string;
    firstName: string;
    lastName: string;
    message: string;
    attachments: any[];
  },
) => {
  const roomRef = doc(db, 'rooms', roomId);
  const roomSnapshot = await getDoc(roomRef);

  if (roomSnapshot.exists()) {
    const room = roomSnapshot.data();
    let participants = room.participants;

    if (!participants.includes(payload.from)) {
      participants.push(payload.from);

      await updateDoc(roomRef, { participants: arrayUnion(payload.from) });
    }

    const sendTo = participants.map((participantId: string) => {
      return {
        userId: participantId,
        read: participantId !== payload.from ? false : true,
      };
    });

    const messageId = new ObjectID().toString();

    const messagePayload = {
      _from: payload.from,
      _fromAvatar: payload.avatar,
      _fromEmail: payload.email,
      _fromName: payload.firstName + ' ' + payload.lastName,
      _fromUserName: payload.firstName + ' ' + payload.lastName,
      _fromFirstName: payload.firstName,
      _fromLastName: payload.lastName,
      createdAt: serverTimestamp(),
      _to: sendTo.map((sent: { userId: string; read: boolean }) => ({
        userId: String(sent.userId),
        read: sent.read,
      })),
      _id: messageId,
      message: payload.message,
      attachments: payload.attachments,
    };

    setDoc(doc(db, 'rooms', roomId, 'messages', messageId), messagePayload);
  } else {
    console.log('Room not found!');
  }
};

const setMessagesAsRead = async (roomId: string, userId: string) => {
  const q = query(collection(db, 'rooms', roomId, 'messages'));
  let unreadMessages: any[] = [];

  const messagesSnapshot = await getDocs(q);
  messagesSnapshot.forEach((doc: any) => {
    // doc.data() is never undefined for query doc snapshots
    const msg = {
      ...doc.data(),
      _id: doc.id,
    };

    if (
      msg._to.some(
        (receiver: { userId: string; read: boolean }) =>
          receiver.userId === userId && receiver.read === false,
      )
    ) {
      unreadMessages.push(msg);
    }
  });

  const batch = writeBatch(db);

  for (const unreadMsg of unreadMessages) {
    unreadMsg._to.forEach((receiver: { userId: string; read: boolean }) => {
      if (String(receiver.userId) === userId) {
        const msgRef = doc(db, `rooms/${roomId}/messages`, unreadMsg._id);

        const updatedReceiver = unreadMsg._to.map(
          (receiver: { userId: string; read: boolean }) => ({
            userId: receiver.userId,
            read: receiver.userId === userId ? true : receiver.read,
          }),
        );

        batch.update(msgRef, {
          _to: updatedReceiver,
        });
      }
    });
  }

  await batch.commit();
};

const fetchNotifications = (
  { limit, startAfter }: { limit: any; startAfter: any },
  cb: () => void,
  errCb: (arg: any) => void,
) => {
  const userId = getCookie('ID');

  if (limit) {
    if (startAfter) {
      const q = query(
        collection(db, `notifications`),
        where('toId', '==', userId),
        where('deleted', '!=', true),
        setLimit(limit),
        orderBy('deleted', 'desc'),
        orderBy('createdAt', 'desc'),
        startAfter(startAfter),
      );

      getDocs(q)
        .then(cb)
        .catch((err) => {
          console.error('Error listening to notifications from firestore', err);
          errCb(err);
        });
    } else {
      const q = query(
        collection(db, `notifications`),
        where('toId', '==', userId),
        where('deleted', '!=', true),
        setLimit(limit),
        orderBy('deleted', 'desc'),
        orderBy('createdAt', 'desc'),
      );

      getDocs(q)
        .then(cb)
        .catch((err) => {
          console.error('Error listening to notifications from firestore', err);
          errCb(err);
        });
    }
  } else {
    const q = query(
      collection(db, `notifications`),
      where('toId', '==', userId),
      where('deleted', '!=', true),
    );

    getDocs(q)
      .then(cb)
      .catch((err) => {
        console.error('Error listening to notifications from firestore', err);
        errCb(err);
      });
  }
};

const listenToNotifications = ({ limit }: { limit: any }, cb: (arg: any) => void) => {
  const userId = getCookie('ID');

  const q = query(
    collection(db, `notifications`),
    where('toId', '==', userId),
    where('deleted', '!=', true),
    orderBy('deleted', 'desc'),
    orderBy('createdAt', 'desc'),
    setLimit(limit),
  );

  onSnapshot(q, cb, (err) => console.error('Error listening to notifications from firestore', err));
};

const listenToTotalUnread = (cb: any) => {
  const userId = getCookie('ID');

  if (cb && userId) {
    return onSnapshot(doc(db, 'users', userId), cb, (err: any) =>
      console.error('Error listening to notifications from firestore', err),
    );
  }
};

const readNotification = async (notificationId: string) => {
  const userId = getCookie('ID');
  const userRef = doc(db, 'users', userId);
  const notifRef = doc(db, 'notifications', `${notificationId}-${userId}`);

  try {
    await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userRef);

      if (!userDoc.exists()) {
        throw 'Document does not exist!';
      }

      const newTotalUnread = userDoc.data().totalUnread > 0 ? userDoc.data().totalUnread - 1 : 0;
      transaction.update(userRef, { totalUnread: newTotalUnread });
      transaction.update(notifRef, { isRead: true });
    });
    console.log('Transaction successfully committed!');
  } catch (e) {
    console.log('Transaction failed: ', e);
  }
};

const readAllNotifications = async () => {
  const userId = getCookie('ID');

  const unreadNotifications = await getDocs(
    query(
      collection(db, `notifications`),
      where('toId', '==', userId),
      where('isRead', '==', false),
    ),
  );

  unreadNotifications.forEach(async (notif) => {
    const unreadNotificationRef = doc(db, 'notifications', `${notif.id}-${userId}`);

    await updateDoc(unreadNotificationRef, { isRead: true });
  });

  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, { totalUnread: 0 });
};

const listenLastOnline = (userId: string, cb: () => void) => {
  return onSnapshot(doc(db, 'users', userId), cb, (err) =>
    console.error('Error listening to notifications from firestore', err),
  );
};

export {
  db,
  // auth related
  auth,
  login,
  logout,
  // chat related
  listenToChatMessages,
  sendMessage,
  setMessagesAsRead,
  // notif related
  fetchNotifications,
  listenToNotifications,
  listenToTotalUnread,
  readNotification,
  readAllNotifications,
  listenLastOnline,
};
