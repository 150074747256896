import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Divider, Spacer, StatusBadge, Text, Container } from 'ravenry-ui';
import { NewCard } from 'ui/molecules';
import colors, { ColorOptions } from 'ui/colors';
import { convertStatusNumberToText, getDashboardStatusColor } from 'helper/utils';
import Responsive from 'helper/responsive';
import { Job } from 'store/constants/properties';

const CustomizedNewCard = styled(NewCard)`
  &:hover {
    box-shadow: none;
    background: ${colors['semiWhite' as ColorOptions]};
    > p:nth-of-type(1) {
      color: ${colors['blue90' as ColorOptions]};
    }
    > p:nth-of-type(2) {
      color: ${colors['black100' as ColorOptions]};
    }
  }
  margin-bottom: 0;
  box-shadow: none;
  height: 100%;
`;

interface Props {
  jobs: Job[];
  showLastDivider: boolean;
  userRole?: 'client' | 'freelancer';
}

export default function JobsNeedActionCard({
  jobs = [],
  showLastDivider = false,
  userRole = 'client',
}: Props) {
  const { isMobile } = Responsive();

  const getStatusDescription = (status: number) => {
    if (userRole === 'client') {
      return [
        '',
        'We found the right freelancer for your job. Now you can start chat freelancer and can start chatting with them.',
        'Please allow some buffer time for us to send an invoice to your email.',
        '',
        'Your freelancer has submitted the work. Please review the work soon.',
        '',
      ][status];
    } else if (userRole === 'freelancer') {
      return [
        '',
        '',
        'You have been connected with the client. Start chatting with the client to learn more about the project.',
        '',
        'Congratulations! The client has decided to work with you on this project. Start working on this project now.',
        '',
        '',
      ][status];
    }
  };

  return (
    <>
      {jobs.map((job, index) => {
        const linkTo =
          userRole === 'client'
            ? `/client/${job.status === -1 ? 'dashboard' : 'jobs'}/${job._id}`
            : userRole === 'freelancer'
            ? `/freelancer/jobs/${job._id}`
            : '';
        return (
          <React.Fragment key={`job-${job._id}`}>
            <Container fluid height={isMobile ? 'auto' : '100px'} padding="4px 8px">
              <Link to={linkTo}>
                <CustomizedNewCard padding="8px 16px 8px 16px" column>
                  <StatusBadge color={getDashboardStatusColor(job.status, job.cancelled, userRole)}>
                    {convertStatusNumberToText(
                      job.status,
                      userRole,
                      job.cancelled,
                      job?.availableFor?.recommendedBy?.role,
                    )}
                  </StatusBadge>
                  <Spacer size="4" />
                  <Text _as="b3" bold color="black100">
                    {job.title?.slice(0, 50)}
                    {(job.title || '').length > 50 ? '...' : ''}
                  </Text>
                  <Spacer size="4" />
                  <Text _as="b3" color="black80">
                    {getStatusDescription(job.status)}
                  </Text>
                </CustomizedNewCard>
              </Link>
            </Container>
            {index < jobs.length - 1 && <Divider size="1" margin="0" offset="-8" />}
            {index === jobs.length - 1 && showLastDivider && (
              <Divider size="1" margin="0" offset="-8" />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
