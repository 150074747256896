import React from 'react';
import styled from 'styled-components';

import ChatList from './ChatList';
import ChatParticipants from './ChatParticipants';
import ChatHeader from './ChatHeader';
import ChatInput from './ChatInput';

const Root = styled.div`
  display: flex;
  /*height: calc(100vh - 150px);*/
  margin-right: -24px;
  margin-left: -24px;
  background: white;
`;

function Chat(props) {
  const { children } = props;

  return <Root>{children}</Root>;
}

Chat.List = ChatList;
Chat.Participants = ChatParticipants;
Chat.Header = ChatHeader;
Chat.Input = ChatInput;

export default Chat;
