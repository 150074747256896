import React from 'react';
import styled, { css } from 'styled-components';

import { Row, Spacer } from 'ravenry-ui';
import colors from 'ui/colors';

import { Navigation } from 'ui/molecules';

interface NavigationWithNumberProps {
  active?: boolean;
  onClick?: () => void;
}
const NavigationWithNumber = styled(Navigation)<NavigationWithNumberProps>`
  box-shadow: none;
  color: ${colors.black60};
  font-size: 14px;
  line-height: 16px;

  ${({ active = false }) =>
    active
      ? css`
          background: ${colors.blue};
          color: ${colors.white};
        `
      : css`
          background: transparent;
        `}

  &:hover {
    color: ${({ active }) => (active ? colors.white : colors.blue)};
    background: ${({ active }) => (active ? colors.blue : colors.black5)};
  }
`;

const Root = styled(Row)``;

interface Props {
  onNext: (newPage: number) => void;
  onPrev: (newPage: number) => void;
  onPageClick: (newPage: number) => void;
  activePage: number;
  totalPages: number;
  maxShownPages?: number;
}

export default function Pagination(props: Props) {
  const { maxShownPages = 10, totalPages, activePage, onNext, onPrev, onPageClick } = props;

  const difference = (maxShownPages + 1) / 2 - 2;
  const isMaxShownPagesEven = maxShownPages % 2 === 0;

  const isTooFew = totalPages <= maxShownPages;
  const isTooLeft = isMaxShownPagesEven
    ? activePage - Math.ceil(difference) <= 1
    : activePage - Math.floor(difference) <= 2;
  const isTooRight = activePage + Math.ceil(difference) + 1 >= totalPages;

  let firstMiddlePage = 1;

  if (isTooFew) {
    firstMiddlePage = 2;
  } else if (isTooLeft) {
    firstMiddlePage = 2;
  } else if (isTooRight) {
    firstMiddlePage = isMaxShownPagesEven
      ? totalPages - 2 * Math.ceil(difference)
      : totalPages - (2 * Math.ceil(difference) + 1);
  } else {
    firstMiddlePage = isMaxShownPagesEven
      ? activePage - Math.ceil(difference) + 1
      : activePage - Math.ceil(difference);
  }

  const middlePageTotal = isTooFew ? totalPages - 2 : maxShownPages - 2;

  const middlePages = Array.from({ length: middlePageTotal }, (_, i) => i + firstMiddlePage);
  const pages = [
    1,
    ...(isTooLeft || isTooFew ? [] : ['...']),
    ...middlePages,
    ...(isTooRight || isTooFew ? [] : ['...']),
    totalPages,
  ];

  return (
    <Root alignItems="center" justifyContent="space-between">
      <Navigation.Prev
        onClick={() => {
          if (activePage > 1) {
            onPrev(activePage - 1);
          }
        }}
      />
      <Row>
        {pages.map((p, i, { length }) => (
          <>
            <NavigationWithNumber
              active={p === activePage}
              onClick={() => {
                if (typeof p === 'number') {
                  onPageClick(p);
                }
              }}
              data-cy={`page-${p}`}
            >
              {p}
            </NavigationWithNumber>

            {i < length - 1 ? <Spacer size="4" horizontal /> : null}
          </>
        ))}
      </Row>

      <Navigation.Next
        onClick={() => {
          if (activePage < totalPages) {
            onNext(activePage + 1);
          }
        }}
      />
    </Root>
  );
}
