import React from 'react';
import styled, { css } from 'styled-components';
import { useField, useFormikContext } from 'formik';

import { Label, Text, Input, Row, Spacer } from 'ravenry-ui';

const Container = styled.div`
  /* width: ${(props) => props.width && props.width}; */
  width: 100%;
  padding: 0;
  position: relative;
  ${(props) =>
    props.inline
      ? css`
          display: flex;
          align-items: center;
        `
      : css``};
`;

/**
 * Select molecule
 *
 * @param {Object} props
 */
const Select = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue, submitForm } = useFormikContext();
  const def = props.options.filter((op) => op.value === field.value);

  return (
    <Container inline={props.inline} style={props.style} id={props.id}>
      {label && (
        <>
          <Row>
            <Label
              inline={props.inline}
              color={props.labelColor}
              _as={props._labelAs}
              bold
              required={props.required}
            >
              {label}
            </Label>
          </Row>
          <Spacer size="8" display="block" />
        </>
      )}
      <div
        data-cy={props.dataCy}
        style={{ width: props.width ? props.width : 200, position: 'relative' }}
      >
        <Input.Select
          disabled={props.disabled}
          isFilter={props.isFilter}
          options={props.options}
          value={(def && def[0]) || ''}
          error={props.error || (meta.touched && meta.error)}
          onChange={(e) => {
            if (e) {
              setFieldValue(props.name, e.value);

              if (typeof props.onChange === 'function') {
                props.onChange(e);
              }
            }

            if (typeof props.onChange === 'function') {
              props.onChange(e);
            }

            if (props.submitOnChange) {
              submitForm();
            }
          }}
          onClear={(e) => {
            if (e) {
              setFieldValue(props.name, '');
            }

            if (props.submitOnChange) {
              submitForm();
            }
          }}
          onFocus={props.onFocus}
          placeholder={props.placeholder}
          controlShouldRenderValue={props.controlShouldRenderValue}
        />
        {props.error || (meta.touched && meta.error) ? (
          <>
            <Spacer size="4" display="block" />
            <Text _as="b3" color="red">
              {props.error || meta.error}
            </Text>
          </>
        ) : null}
      </div>
    </Container>
  );
};

export default Select;
