import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Col,
  Icon,
  Input,
  Text,
  Rating,
  Row,
  Spacer,
  Tag,
  StatusBadge,
  Segment,
  Button,
  Span,
} from 'ravenry-ui';
import { NewCard } from 'ui/molecules';
import { SaveFreelancer } from 'ui/organisms';
import { constructFirstNameLastInitial, displayNumberWithSeparator } from 'helper/utils';
import colors, { ColorOptions } from 'ui/colors';
import { jobStatusClient } from 'store/constants/Constants';
import { amplitudeEvent } from 'helper/amplitude';

const RecommendedTag = styled.div`
  background: ${colors.purple};
  border-radius: 10px 0;
  padding: 4px 12px;
  position: absolute;
  top: 0;
  left: 0;
`;

const getTextAndColor = (
  freelancerStatus: number,
  jobStatus: number,
  isAssignedFreelancer: boolean,
  recommendedBy: 'admin' | 'client',
) => {
  if (freelancerStatus === 0) {
    return ['Invited', 'statsBlue'];
  }

  if (freelancerStatus === 1) {
    return ['Not interested', 'statsYellow'];
  }

  if (freelancerStatus === 2 && recommendedBy === 'admin') {
    return ['Interested', 'statsGreen'];
  }

  if (freelancerStatus === 3 && recommendedBy === 'client') {
    return ['Published', 'statsBlue'];
  }

  if (freelancerStatus === 4 && isAssignedFreelancer) {
    if (jobStatus === jobStatusClient.MAKE_PAYMENT) {
      return ['Booked', 'statsBlue'];
    }

    if (
      [
        jobStatusClient.WORK_IN_PROGRESS,
        jobStatusClient.REVIEW_WORK,
        jobStatusClient.COMPLETED,
      ].includes(jobStatus)
    ) {
      return ['Hired', 'statsGreen'];
    }
  }

  return ['', ''];
};

export default function FreelancerCard(props: {
  pathname?: string;
  freelancer: {
    _id: string;
    _freelancerId: string;
    avatarLink?: string;
    firstName?: string;
    lastName?: string;
    title?: string;
    aboutMe?: string;
    location?: string;
    rate?: number;
    rating?: number;
    reviews?: number;
    unit?: string;
    expertise?: string[];
    review?: {
      review?: string;
    };
    // status of freelancer for particular job
    status: number;
    recommendedBy?: {
      role: 'admin' | 'client';
    };
    chatRoom?: string;
  };
  checked?: boolean;
  // callback to handle when checkbox is selected
  onSelect?: (freelancerId: string) => void;
  // callback to remove freelancer from their My Freelancers
  onRemove: (freelancerId: string) => void;
  // callback to save freelancer to their My Freelancers
  onSave: (freelancerId: string) => void;
  // callback to view offer
  onViewOffer?: (offer: any, freelancerId: any) => void;
  // callback to start chat
  onStartChat?: (freelancerId: string, jobId: string, chatRoom?: string) => void;
  // callback to chat
  onChat?: (freelancerId: string, roomId: string) => void;
  // callback to view contract
  onViewContract?: (offer: any) => void;
  // callback to review freelancer
  onReviewFreelancer?: (freelancer: any, jobId: string) => void;
  saved: boolean;
  // recommended by Ravenry or not
  recommended?: boolean;
  // render about me or not, default to true
  offer?: {
    deadline: string;
    scope: string;
    amount: number;
  };
  job: {
    _id: string;
    // status of the job
    status: number;
    // assigned freelancer id (will only be available from job status Make payment)
    _assignTo?: string | null;
    cancelled?: boolean;
  };
  chat?: {
    roomId: string;
  };
  review?: {
    star?: number;
    review?: string;
  };
  withCheckbox?: boolean;
}) {
  const {
    pathname,
    freelancer,
    checked,
    onSelect,
    onRemove,
    onSave,
    onStartChat,
    onViewOffer,
    onChat,
    onViewContract,
    onReviewFreelancer,
    saved,
    recommended = false,
    offer = { deadline: undefined, scope: undefined, amount: undefined },
    job,
    chat,
    review,
    withCheckbox = true,
  } = props;

  const isStatusBadgeShown =
    job.status >= jobStatusClient.CHOOSE_YOUR_FREELANCER && job.status <= jobStatusClient.COMPLETED;

  return (
    <NewCard
      noMargin
      onClick={() => {
        if (typeof onSelect === 'function') {
          onSelect(freelancer._freelancerId);
        }
      }}
      style={{
        cursor: 'default',
        position: 'relative',
        paddingTop: recommended ? '30px' : undefined,
      }}
    >
      {recommended && (
        <RecommendedTag>
          <Text _as="s6" bold color="white">
            Recommended by Ravenry
          </Text>
        </RecommendedTag>
      )}

      {job.status <= jobStatusClient.LOOK_FOR_FREELANCERS && withCheckbox ? (
        <>
          <Col>
            <Spacer size="14" />
            <Input.Check checked={checked} />
          </Col>
          <Spacer size="12" horizontal />
        </>
      ) : null}

      <Col>
        <Row>
          {(isStatusBadgeShown || job.status === jobStatusClient.COMPLETED) && (
            <Spacer size="28" horizontal />
          )}
          <Avatar
            url={freelancer.avatarLink || null}
            name={`${freelancer.firstName} ${freelancer.lastName}`}
            size="medium"
          />
        </Row>

        <Spacer size="8" />

        {isStatusBadgeShown ? (
          <StatusBadge
            color={
              (getTextAndColor(
                freelancer.status,
                job.status,
                job._assignTo === freelancer._freelancerId,
                freelancer?.recommendedBy?.role || 'admin',
              )[1] || 'white') as ColorOptions
            }
            width="104px"
          >
            {getTextAndColor(
              freelancer.status,
              job.status,
              job._assignTo === freelancer._freelancerId,
              freelancer?.recommendedBy?.role || 'admin',
            )[0] || ''}
          </StatusBadge>
        ) : null}
      </Col>

      <Spacer size="16" horizontal />

      <Col grow style={{ width: 'min-content' }}>
        <Row>
          <Col>
            <Spacer size="8" />
            <Link
              to={
                pathname
                  ? pathname + `/${freelancer._freelancerId}`
                  : `/client/freelancers/${freelancer._freelancerId}`
              }
            >
              <Text size="14px" lineHeight="18px" bold underLineOnHover>
                {constructFirstNameLastInitial(`${freelancer.firstName} ${freelancer.lastName}`) ||
                  '-'}
              </Text>
            </Link>
            <Spacer size="2" />
            <Link
              to={
                pathname
                  ? pathname + `/${freelancer._freelancerId}`
                  : `/client/freelancers/${freelancer._freelancerId}`
              }
            >
              <Text size="12px" lineHeight="16px" color="blue" underLineOnHover>
                {freelancer.title}
              </Text>
            </Link>

            {job.status >= jobStatusClient.CHOOSE_YOUR_FREELANCER &&
            job.status <= jobStatusClient.REVIEW_WORK ? (
              <Spacer size="16" />
            ) : (
              <Spacer size="4" />
            )}

            <Row>
              <Text _as="b3" color="black60">
                {freelancer.location}
              </Text>
              <Spacer size="40" horizontal />

              <Row>
                <Text _as="b3" color="black60">
                  {`${freelancer.rating?.toFixed(1) || 0}`}
                </Text>
                <Spacer size="4" />
                <Rating value={1} total={1} size="12px" style={{ marginTop: '-1px' }} />
                <Spacer size="4" />
                <Text _as="b3" color="black60">
                  ({freelancer?.reviews} reviews)
                </Text>
              </Row>

              <Spacer size="40" horizontal />
              <Row>
                <Icon name="pay" fill="green" />
                <Text _as="b3" color="black60">
                  USD {freelancer.rate?.toFixed(1)}/{freelancer.unit || 'word'}
                </Text>
              </Row>
            </Row>
          </Col>

          <Spacer filler />

          <Col alignItems="flex-end">
            <SaveFreelancer
              _id={freelancer._freelancerId}
              saved={saved}
              onRemove={(freelancerId: string) => {
                amplitudeEvent('remove the freelancer from my freelancer');
                onRemove(freelancerId);
              }}
              onSave={(freelancerId: string) => {
                amplitudeEvent('added the freelancer to my freelancer');
                onSave(freelancerId);
              }}
            />
          </Col>
        </Row>

        {job.status <= jobStatusClient.LOOK_FOR_FREELANCERS && (
          <>
            <Spacer size="10" />
            <Row>
              <div
                style={{
                  height: '48px',
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: 'grey',
                  overflowY: 'hidden',
                  display: '-webkit-box',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }}
              >
                {freelancer.aboutMe?.split('\n').map((line) => (
                  <>
                    {line}
                    <br />
                  </>
                ))}
              </div>
            </Row>
          </>
        )}

        {!(
          job.status === jobStatusClient.MAKE_PAYMENT || job.status === jobStatusClient.COMPLETED
        ) && (
          <>
            <Spacer size="16" />

            <Row style={{ marginBottom: '-8px' }}>
              {freelancer?.expertise?.slice(0, 3).map((exp) => (
                <Tag key={`exp-${exp}`} style={{ marginRight: '8px', marginBottom: '8px' }}>
                  {exp}
                </Tag>
              ))}

              {(freelancer?.expertise?.length || 0) > 3 ? (
                <>
                  <Text>...</Text>
                  <Spacer size="8" horizontal />
                  <Tag color="black80" style={{ marginBottom: '8px' }}>
                    +{(freelancer?.expertise?.length || 0) - 3}
                  </Tag>
                </>
              ) : null}
            </Row>
          </>
        )}

        {job.status === jobStatusClient.CHOOSE_YOUR_FREELANCER && (
          <>
            <Spacer size="12" display="block" />
            <Segment basic color="backgroundLight" padding="8px 16px 8px 20px">
              <Row justifyContent="space-between">
                <Col>
                  <Text _as="b3" color="black80">
                    OFFER
                  </Text>
                  {offer?.amount ? (
                    <>
                      <Spacer size="0" />

                      <Text _as="s1" bold>
                        {job?.budgetCurrency?.toUpperCase()}{' '}
                        {displayNumberWithSeparator(offer.amount)}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Spacer size="6" />
                      <Text _as="b3" color="black60">
                        This candidate has not submitted an offer. Start{' '}
                        <Span
                          onClick={(e) => {
                            e.stopPropagation();
                            if (onStartChat) {
                              onStartChat(freelancer._freelancerId, job._id);
                            }
                          }}
                          color="blue"
                          underLineOnHover
                        >
                          chat
                        </Span>{' '}
                        now.
                      </Text>
                      <Spacer size="10" />
                    </>
                  )}
                </Col>

                <Col>
                  <Spacer size="8" />

                  <Row>
                    <Button
                      disabled={job.cancelled}
                      variant="outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onStartChat) {
                          amplitudeEvent('started chat');
                          onStartChat(freelancer._freelancerId, job._id, freelancer.chatRoom);
                        }
                      }}
                    >
                      Start chat
                    </Button>

                    {offer?.amount && (
                      <>
                        <Spacer size="16" />
                        <Button
                          disabled={job.cancelled}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (onViewOffer) {
                              onViewOffer(freelancer._freelancerId, offer);
                            }
                          }}
                        >
                          View offer
                        </Button>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </Segment>
          </>
        )}

        {job.status >= jobStatusClient.MAKE_PAYMENT &&
          job.status <= jobStatusClient.REVIEW_WORK &&
          freelancer._freelancerId === job._assignTo && (
            <>
              <Spacer size="12" display="block" />
              <Segment basic color="backgroundLight" padding="8px 16px 8px 20px">
                <Row justifyContent="space-between">
                  <Col>
                    <Text _as="b3" color="black80">
                      JOB FEES
                    </Text>

                    <Spacer size="0" />

                    <Text _as="s1" bold>
                      {job?.budgetCurrency?.toUpperCase()}&nbsp;
                      {displayNumberWithSeparator(offer.amount)}
                    </Text>
                  </Col>

                  <Col>
                    <Spacer size="8" />

                    <Row>
                      <Button
                        disabled={job.cancelled}
                        variant="outlined"
                        onClick={() => {
                          if (onViewContract) {
                            onViewContract(offer);
                          }
                        }}
                      >
                        View contract
                      </Button>

                      {job.status >= jobStatusClient.WORK_IN_PROGRESS &&
                        job.status <= jobStatusClient.REVIEW_WORK && (
                          <>
                            <Spacer size="16" />
                            <Button
                              disabled={job.cancelled}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (onChat) {
                                  onChat(freelancer._freelancerId, chat?.roomId || '');
                                }
                              }}
                            >
                              Chat
                            </Button>
                          </>
                        )}
                    </Row>
                  </Col>
                </Row>
              </Segment>
            </>
          )}

        {job.status === jobStatusClient.COMPLETED && freelancer._freelancerId === job._assignTo && (
          <>
            <Spacer size="12" display="block" />
            <Segment basic color="backgroundLight" padding="8px 16px 8px 20px">
              <Row justifyContent="space-between">
                <Col width={review?.star ? '100%' : undefined}>
                  <Text _as="b3" color="black80">
                    Review from you:
                  </Text>
                  <Spacer size="4" display="block" />
                  <Rating
                    size="12px"
                    total={5}
                    value={review?.star || 0}
                    style={{ marginBottom: '-5px', marginTop: '-4px' }}
                  />

                  <Spacer size="8" display="block" />

                  {review?.review ? (
                    <Input.Textarea
                      value={review?.review || ''}
                      noBorder
                      disabled
                      fluid
                      style={{ marginTop: 0, background: 'none', overflowY: 'hidden' }}
                      padding="0"
                    />
                  ) : (
                    <Text _as="b3" color="black60">
                      You haven&apos;t given review yet
                    </Text>
                  )}
                </Col>

                {!review?.star && (
                  <Col>
                    <Spacer size="8" />

                    <Button
                      disabled={job.cancelled}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onReviewFreelancer) {
                          onReviewFreelancer(freelancer, job._id);
                        }
                      }}
                    >
                      Review freelancer
                    </Button>
                  </Col>
                )}
              </Row>
            </Segment>
          </>
        )}
      </Col>
    </NewCard>
  );
}
