import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';

import { getCookie } from 'helper/utils';
import { hideChat, showChat } from 'helper/bento';

import { updateUserData } from 'store/reducer/global';
import { useUser } from 'hooks/useUser';
import { usePrevState } from 'hooks/usePrevState';
import { initAmplitude, amplitudeEvent } from 'helper/amplitude';

const PrivateRoute = ({ component: Component, path, domain, name, ...rest }) => {
  const dispatch = useDispatch();
  const global = useSelector((state) => state.global);
  const [loggedIn] = useState(global.isLoggedIn);
  const history = useHistory();
  const location = useLocation();
  const { fetchSingleUser, singleUser, updateLastOnline } = useUser();

  const [profileLoading, setProfileLoading] = useState(false);

  const prevProfileLoading = usePrevState(profileLoading);

  useEffect(() => {
    if (global.user.domain === 'admin') {
      hideChat();
    } else {
      showChat();
    }
  }, []);

  useEffect(() => {
    const profileLoadingDone = prevProfileLoading && !profileLoading;

    if (
      loggedIn &&
      profileLoadingDone &&
      global.user.domain === 'freelancer' &&
      singleUser !== null &&
      global.user.isProfileComplete === false &&
      location.pathname !== '/freelancer/profile-wizard'
    ) {
      history.push('/freelancer/profile-wizard');
    }

    if (
      loggedIn &&
      profileLoadingDone &&
      singleUser !== null &&
      global.user.domain === 'client' &&
      global.user.isProfileComplete === false &&
      location.pathname !== '/client/profile-wizard'
    ) {
      history.push('/client/profile-wizard');
    }
  }, [profileLoading, prevProfileLoading]);

  useEffect(() => {
    if (global.user?.userId) {
      setProfileLoading(true);

      fetchSingleUser(global.user.userId).finally(() => setProfileLoading(false));
    }
  }, [global.user?.userId]);

  useEffect(() => {
    if (loggedIn) {
      const userId = getCookie('ID');
      if (userId) {
        return history.listen(() => updateLastOnline(userId));
      }
    }
  }, []);

  useEffect(() => {
    if (loggedIn && singleUser) {
      if (process.env.REACT_APP_ENV === 'production') {
        // initiate amplitude
        initAmplitude(singleUser);
        amplitudeEvent(`visit ${name}`);
      }

      if (singleUser.domain === 'freelancer') {
        const hasName = (singleUser.firstName && singleUser.lastName) || singleUser.fullName;

        const isProfileComplete = !!(
          hasName &&
          singleUser.title &&
          singleUser.address?.country &&
          singleUser.address?.region &&
          singleUser.rate &&
          singleUser.unit
        );

        const isExpertiseFilled = !!singleUser.skills?.length;
        const doesHaveSampleWorks = !!singleUser.portfolios.length;
        const doesHaveServiceOffered = !!singleUser.services?.length;
        const isRequiredFieldsOfSampleWorksFilled = doesHaveSampleWorks
          ? singleUser.portfolios
              .map((p) => p.works.map((w) => ({ ...w, type: p.type })))
              .reduce((prev, curr) => [...prev, ...curr], [])
              .every(
                (p) =>
                  p.title &&
                  p.type &&
                  p.tags?.length &&
                  p.description &&
                  (p.url || p.attachmentUrl),
              )
          : false;

        if (
          isProfileComplete &&
          isExpertiseFilled &&
          doesHaveSampleWorks &&
          doesHaveServiceOffered &&
          isRequiredFieldsOfSampleWorksFilled
        ) {
          dispatch(updateUserData({ ...global.user, isProfileComplete: true }));
        }
      }

      if (singleUser.domain === 'client') {
        const hasName = (singleUser.firstName && singleUser.lastName) || singleUser.fullName;

        const isProfileComplete = !!(
          hasName &&
          singleUser.title &&
          singleUser.address?.country &&
          singleUser.address?.region
        );

        if (isProfileComplete) {
          dispatch(updateUserData({ ...global.user, isProfileComplete: true }));
        }
      }
    }
  }, [singleUser]);

  return (
    <Route
      {...rest}
      render={(props) =>
        !domain || domain === global.user.domain ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
