/**
 * @deprecated delete this later
 */
import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Modal, Row, Button, Spacer, Rating, Label, Text } from 'ravenry-ui';
import { Fields } from 'ui/molecules';
import { amplitudeEvent } from 'helper/amplitude';

export default function ReviewFreelancerModal(props) {
  const { open, onClose, onSubmit, mode, review } = props;

  const title =
    mode === 'give' ? 'Review the freelancer' : mode === 'view' ? 'View the freelancer rating' : '';

  const subtitle =
    mode === 'give'
      ? 'Tell others about your experience with this freelancer.'
      : mode === 'view'
      ? 'This is the review that you gave to the freelancer.'
      : '';

  return (
    <Modal open={open}>
      <Text _as="s1" bold>
        {title}
      </Text>
      <Spacer size="4" display="block" />
      <Text _as="b3" color="black60">
        {subtitle}
      </Text>
      <Spacer size="24" display="block" />

      <Formik
        initialValues={{
          rating: mode === 'give' ? 0 : mode === 'view' ? review.rating : 0,
          review: mode === 'give' ? '' : mode === 'view' ? review.review : '',
        }}
        validationSchema={Yup.object({})}
        enableReinitialize
        onSubmit={(values) => {
          amplitudeEvent('reviewed the freelancer');
          onSubmit(values);
        }}
        validateOnBlur={false}
      >
        {(FormikProps) => (
          <Form>
            <Text _as="s6" color="black60">
              FREELANCER&apos;S RATING
            </Text>
            <Spacer size="8" display="block" />

            <Rating
              value={FormikProps.values.rating}
              size="20px"
              spacing="16px"
              margin="8px 0"
              onClick={(rating) => {
                if (mode === 'give') {
                  FormikProps.setValues({
                    ...FormikProps.values,
                    rating,
                  });
                }
              }}
            />

            <Spacer size="8" display="block" />

            {mode === 'give' ? (
              <>
                <Text _as="s6" color="black60">
                  YOUR COMMENT
                </Text>

                <Fields.FieldArea
                  name="review"
                  placeholder="Type your comment here"
                  minRows="5"
                  maxRows="5"
                  fluid
                  maxLength={500}
                />
              </>
            ) : (
              <>
                <Label size="12px" lineHeight="16px">
                  YOUR COMMENT
                </Label>
                <Spacer size="8" display="block" />
                <div style={{ minHeight: '72px' }}>
                  {(review.review || '').split('\n').map((line, i, { length }) => (
                    <>
                      <Text _as="b2">{line}</Text>
                      {i < length ? <br /> : null}
                    </>
                  ))}
                </div>
              </>
            )}

            <Spacer size="8" display="block" />

            <Row justifyContent="flex-end">
              {mode === 'give' ? (
                <>
                  <Button
                    color="blue"
                    variant="outlined"
                    width="150px"
                    type="button"
                    onClick={onClose}
                  >
                    Do it later
                  </Button>
                  <Button
                    color="blue"
                    width="150px"
                    type="submit"
                    style={{ marginLeft: '16px' }}
                    disabled={FormikProps.values.rating === 0}
                  >
                    Submit
                  </Button>
                </>
              ) : mode === 'view' ? (
                <Button color="blue" type="button" variant="outlined" onClick={onClose}>
                  Close
                </Button>
              ) : null}
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
