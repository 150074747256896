import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import { Avatar, Col, Divider, Text, Row, Spacer, Span } from 'ravenry-ui';
import { BubbleChat } from 'ui/molecules';

import Responsive from 'helper/responsive';
import {
  convertLogObjectToText,
  getSubjectFromLog,
  convertLogObjectToChatVariant,
} from 'helper/utils';

const Log = styled(Row)`
  justify-content: center;
`;

const Message = styled(Row)`
  align-items: flex-start;
  ${({ isOutgoing }) => isOutgoing && 'flex-direction: row-reverse;'}
`;

/**
 * ChatList
 *
 * @param {Object} props
 * @param {Array} props.chats
 * @param {String} props.me - UUID of logged in user
 */
export default function ChatList(props) {
  const { chats, me, onViewOfferAndContract, jobStatus, isCancelled, job } = props;
  const { isMobile } = Responsive();

  function isDifferentDay(currentChatElement, prevChatElement) {
    return !moment(currentChatElement.createdAt).isSame(prevChatElement?.createdAt, 'day');
  }

  const firstUnreadChatIndex = chats
    .map((chat) => !!(chat._to?.find((to) => to?.userId === me) || {})?.read)
    .indexOf(false);

  const renderLog = (log) => {
    const [subject, toBe] = getSubjectFromLog(log, me);
    return (
      <Text _as="b3">
        <Span color="black100" bold>
          {subject}
        </Span>{' '}
        {log.type === 'jobCompleted' ? (
          <Span color="black60">{convertLogObjectToText(log, me)}</Span>
        ) : (
          // Need to be edited
          <Span color="black60">{`${toBe} ${convertLogObjectToText(log, me)}`}</Span>
        )}
      </Text>
    );
  };

  return chats.map((chat, index) => (
    <React.Fragment key={`chat-${index}`}>
      {(index === 0 || isDifferentDay(chat, chats[index - 1])) && (
        <>
          <Divider size="36">
            <Text _as="b2" color="black40">
              {moment(chat.createdAt).format('dddd, D MMM YYYY')}
            </Text>
          </Divider>
          <Spacer size="24" display="block" />
        </>
      )}
      {chat._from !== me && index === firstUnreadChatIndex ? (
        <>
          <Divider size="36" color="red">
            <Text _as="b2" color="red">
              New messages
            </Text>
          </Divider>
          <Spacer size="24" display="block" />
        </>
      ) : null}
      {(!_.isEmpty(chat.log) && convertLogObjectToChatVariant(chat.log) === undefined) ||
      convertLogObjectToChatVariant(chat.log) === 'submitWork' ? (
        <>
          <Log>{renderLog(chat.log)}</Log>
          <Spacer size="16" display="block" />
        </>
      ) : (
        <Message isMobile={isMobile} spacing="24px" isOutgoing={chat._from === me}>
          <Col
            style={(() => {
              const margin = chat._from === me ? 'marginLeft' : 'marginRight';

              return { [margin]: '16px', marginBottom: '20px' };
            })()}
          >
            <Avatar
              url={chat._fromAvatar}
              name={chat._from === me ? chat._fromName : chat._fromUserName}
            />
          </Col>
          <Col style={chat._from === me ? { alignItems: 'flex-end' } : undefined}>
            {convertLogObjectToChatVariant(chat.log) ? (
              <BubbleChat
                from={chat._from}
                me={me}
                job={job}
                isMobile={isMobile}
                fromName={chat._fromName}
                createdAt={chat.createdAt}
                attachments={chat.log.attachment ? [chat.log.attachment] : null}
                message={chat.message}
                variant={convertLogObjectToChatVariant(chat.log)}
                review={chat.log.review ? chat.log.review : null}
                offer={chat.log.offerJob ? { ...chat.log.offerJob, freelanceId: chat._from } : null}
                onViewOfferAndContract={onViewOfferAndContract}
                jobStatus={jobStatus}
                isCancelled={isCancelled}
              />
            ) : (
              <BubbleChat
                from={chat._from}
                me={me}
                job={job}
                isMobile={isMobile}
                fromName={chat._fromName}
                createdAt={chat.createdAt}
                attachments={chat.attachments}
                message={chat.message}
                isCancelled={isCancelled}
                // variant={convertLogObjectToChatVariant(chat.log)?convertLogObjectToChatVariant(chat.log):'attach'}
                // review={chat.review!==undefined?chat.review:null}
              />
            )}
            <Spacer size={24} />
          </Col>
        </Message>
      )}
    </React.Fragment>
  ));
}
