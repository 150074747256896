import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import ReactEasyCropper from 'react-easy-crop';
import Slider from 'react-slider';

import { Spacer, Row, Button, Icon } from 'ravenry-ui';
import { Modal } from 'ui/molecules';
import colors from 'ui/colors';

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}

const StyledSlider = styled(Slider)`
  width: calc(100% - 56px);
  height: 8px;
`;

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  top: -8px;
`;

const Thumb = (props) => <StyledThumb {...props} />;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) => (props.index === 1 ? 'grey' : `${colors.blue}`)};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

export default function Cropper(props) {
  const { open, img, onClose, onUpload } = props;

  const [coordinate, setCoordinate] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);

  const handleUpload = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(img, croppedAreaPixels);

      const blob = await fetch(croppedImage).then((r) => r.blob());

      onUpload(blob);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <Modal open={open} width="400px">
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '300px',
        }}
      >
        <ReactEasyCropper
          image={img}
          crop={coordinate}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          showGrid={false}
          onCropChange={(coordinate) => setCoordinate(coordinate)}
          onCropComplete={(_croppedArea, croppedAreaPixels) =>
            setCroppedAreaPixels(croppedAreaPixels)
          }
        />
      </div>

      <Spacer size="16" />

      <Row justifyContent="space-between" alignItems="center">
        <Icon name="zoomout" />
        <StyledSlider
          min={1}
          max={3}
          step={0.1}
          renderTrack={Track}
          renderThumb={Thumb}
          onChange={(value) => setZoom(value)}
        />
        <Icon name="zoomin" />
      </Row>

      <Spacer size="16" />

      <Row justifyContent="center">
        <Button onClick={onClose} color="blue" variant="outlined">
          Cancel
        </Button>
        <Spacer size="16" />
        <Button onClick={handleUpload} color="blue">
          Save
        </Button>
      </Row>
    </Modal>
  );
}
