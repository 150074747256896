import React, { useEffect, useState } from 'react';

import { Button, Row, Spacer, Text } from 'ravenry-ui';
import { Modal, Fields } from 'ui/molecules';

interface Props {
  open?: boolean;
  onCancel?: () => any;
  onChangeName?: (newName: string) => any;
  oldName?: string;
}

export default function RenameFolderModal({
  open = false,
  onCancel = () => null,
  onChangeName = () => null,
  oldName = '',
}: Props) {
  const [newName, setNewName] = useState(oldName);
  const [error, setError] = useState('');

  useEffect(() => {
    setNewName(oldName);
  }, [oldName]);

  return (
    <Modal open={open} padding="0" outerPadding="24px" width="522px">
      <Text _as="s3" bold>
        Rename Folder
      </Text>
      <Spacer size="8" display="block" />
      <Text _as="b3" color="black80">
        Change your folder name
      </Text>
      <Spacer size="16" display="block" />

      <Fields.Text
        label="FOLDER NAME"
        placeholder="Type in your folder name"
        _labelAs="s5"
        value={newName}
        error={error}
        onChange={(e) => setNewName(e.target.value)}
        icon="search"
        fluid
      />

      <Spacer size="16" display="block" />

      <Row justifyContent="flex-end">
        <Button color="blue" variant="outlined" onClick={onCancel} width="110px">
          Cancel
        </Button>
        <Spacer size="16" horizontal />
        <Button
          color="blue"
          onClick={() => {
            if (newName.trim().length === 0) {
              setError('Please create a name for this folder');
            } else {
              setError('');
              onChangeName(newName);
            }
          }}
          width="150px"
        >
          Save
        </Button>
      </Row>
    </Modal>
  );
}
