import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { Icon, Text, Dropdown, Spacer } from 'ravenry-ui';
import { useOutsideClick } from 'hooks/useOutsideClick';
import colors from '../../colors';
import { useAutoAnimate } from '@formkit/auto-animate/react';

interface ContainerProps {}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface SelectedLabelProps {
  width?: string;
  padding?: string;
  active: boolean;
}

const SelectedLabel = styled.div<SelectedLabelProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => padding};
  cursor: pointer;
  position: relative;
  min-width: ${({ width }) => width};
  height: 32px;
  font-weight: bold;
  color: ${({ active }) => (active ? colors.black : colors.black50)};

  /* Background / White */
  background: ${colors.white};

  /* Text / Black 10 */
  border: 1px solid ${({ active }) => (active ? colors.blue90 : colors.black10)};
  box-sizing: border-box;
  /* Container Elevation */

  border-radius: 5px;

  &:hover {
    color: ${colors.black};
    border: 1px solid ${colors.blue90};
  }
`;

interface FilterProps {
  fieldName: string;
  options?: {
    icon?: React.ReactNode;
    label: React.ReactNode;
    value: string;
  }[];
  initialLabel?: React.ReactNode;
  selectedLabelPrefix?: string;
  width?: string;
  padding?: string;
  dropdownWidth?: string;
  value?: string;
  dropdownOptions?: { top: string };
  onSelect?: (value: { _order?: string; _sort?: string; _show?: string }) => void;
  dataCy?: string;
}

export default function Filter(props: FilterProps) {
  const {
    fieldName,
    options,
    initialLabel,
    selectedLabelPrefix,
    onSelect,
    width = '150px',
    padding = '10px 16px',
    dropdownWidth,
    value,
    dropdownOptions = { top: undefined },
    dataCy,
  } = props;
  const [dropdown, setDropdown] = useState({ open: false });
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    setDropdown({ ...dropdown, open: false });
  });

  const [parent, enableAnimations] = useAutoAnimate(/* optional config */);

  return (
    <Container ref={parent}>
      {selectedLabelPrefix && (
        <>
          <Text _as="s5" color="black60" width="max-content">
            {selectedLabelPrefix}
          </Text>
          <Spacer size="8" />
        </>
      )}
      <SelectedLabel
        active={dropdown.open}
        onClick={() => setDropdown({ ...dropdown, open: !dropdown.open })}
        width={width}
        padding={padding}
        ref={ref}
        data-cy={dataCy}
      >
        {initialLabel ||
          options?.find((option: { value: string }) => option.value === value)?.icon ||
          options?.find((option: { value: string }) => option.value === value)?.label}
        <Icon currentColor name={dropdown.open ? 'upward chevron' : 'downward chevron'} />
      </SelectedLabel>
      {dropdown.open && (
        <Dropdown
          style={{
            right: 0,
            width: dropdownWidth || width,
            top: dropdownOptions.top || '24px',
            zIndex: 2,
          }}
        >
          {options?.map((option) => (
            <Dropdown.Item
              key={`option-${option.value}`}
              onClick={() => {
                // setLabel(option.label);
                if (onSelect) {
                  onSelect({ [fieldName]: option.value });
                }
              }}
              data-cy={`item-${option.value}`}
            >
              {option.icon && (
                <>
                  {option.icon}
                  <Spacer size="8" />
                </>
              )}
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown>
      )}
    </Container>
  );
}
