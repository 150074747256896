import React from 'react';
import styled from 'styled-components';
import { snakeCaseToSentenceCase, convertStatusNumberToText } from 'helper/utils';
import moment from 'moment';
import { UnreadDot, ColoredTag } from 'ravenry-ui';
import { CardTable, CardTableMobile } from 'ui/molecules';
import Responsive from 'helper/responsive';

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default function JobCardTable({ jobs, userRole, minHeight }) {
  const { isMobile } = Responsive();
  const widths =
    userRole === 'client'
      ? [
          '32px',
          'calc(488 / 1180 * 100%)',
          'calc(166 / 1180 * 100%)',
          'calc(166 / 1180 * 100%)',
          'calc(166 / 1180 * 100%)',
          'calc(166 / 1180 * 100%)',
        ]
      : userRole === 'freelancer'
      ? ['32px', 'calc(297 / 564 * 100%)', 'calc(85 / 564 * 100%)', 'calc(106 / 564 * 100%)']
      : [];
  const header =
    userRole === 'client'
      ? ['', 'Job title', 'Job type', 'Job deadline', 'Job fees', 'Status']
      : userRole === 'freelancer'
      ? ['', 'Job title', 'Job deadline', 'Status']
      : [];
  const items = jobs.map((job, key) => ({
    to: `/${userRole}/jobs/${job._id}`,
    bold: job.unread === true,
    data:
      userRole === 'client'
        ? [
            job.unread ? (
              <DotContainer>
                <UnreadDot />
              </DotContainer>
            ) : (
              ''
            ),
            job.title,
            snakeCaseToSentenceCase(job.type),
            job.deadline ? moment(job.deadline).format('D MMM Y') : 'Not set',
            job.budget ? `USD ${job.budget?.toLocaleString()}` : 'Not set',
            <ColoredTag
              key={'colored-tag-' + key}
              color={['lightBlue', 'green', 'green', 'lightBlue', 'green', 'lightBlue'][job.status]}
            >
              {convertStatusNumberToText(
                job.status,
                userRole,
                job.cancelled,
                job?.availableFor?.recommendedBy?.role,
              )}
            </ColoredTag>,
          ]
        : userRole === 'freelancer'
        ? [
            job.unread ? (
              <DotContainer>
                <UnreadDot />
              </DotContainer>
            ) : (
              ''
            ),
            job.title,
            job.deadline ? moment(job.deadline).format('D MMM Y') : 'Not set',
            <ColoredTag
              key={'colored-tag-' + key}
              color={['lightBlue', 'green', 'green', 'lightBlue', 'green', 'lightBlue'][job.status]}
            >
              {convertStatusNumberToText(
                job.status,
                userRole,
                job.cancelled,
                job?.availableFor?.recommendedBy?.role,
              )}
            </ColoredTag>,
          ]
        : [],
  }));

  return isMobile ? (
    <CardTableMobile
      header={header}
      widths={widths}
      items={items}
      minHeight={items.length === 0 && minHeight}
      emptyText="You do not have any active jobs right now."
    />
  ) : (
    <CardTable
      header={header}
      widths={widths}
      items={items}
      minHeight={items.length === 0 && minHeight}
      emptyText="You do not have any active jobs right now."
    />
  );
}
