import React from 'react';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

import popup_cancel from 'ui/assets/popup_cancel.svg';

interface CancelJobModalProps {
  open: boolean;
  onCancelJob: () => void;
  onCancel: () => void;
}

export default function CancelJobModal(props: CancelJobModalProps) {
  const { open, onCancelJob, onCancel } = props;

  return (
    <ConfirmationModal
      open={open}
      title="Cancel job"
      titleOptions={{ color: 'red' }}
      subtitle={[
        'Are you sure?',
        'Once you cancel,',
        'you will not be able to continue with the job.',
      ]}
      subtitleOptions={{ color: 'black' }}
      img={{ src: popup_cancel, alt: 'cancel job' }}
      primary={{
        text: 'No, let me stay',
        color: 'red',
        onClick: onCancel,
      }}
      ghost={{
        text: "Yes, I'm sure",
        color: 'red',
        onClick: onCancelJob,
      }}
    ></ConfirmationModal>
  );
}
