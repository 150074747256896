const permissions = {
  all: [
    'READ_JOBS',
    'ADD_JOBS',
    'DELETE_JOBS',
    'UPDATE_JOBS',

    'READ_DELETED_JOBS',
    'RESTORE_DELETED_JOBS',
    'READ_COMPLETED_JOBS',

    'READ_JOB',
    'ADD_JOB',
    'DELETE_JOB',
    'UPDATE_JOB',
    'PUBLISH_JOB',
    'ASSIGN_JOB',
    'FINISH_JOB',
    'ACCEPT_JOB',
    'REJECTS_JOB',

    'READ_EMPLOYEE',
    'ADD_EMPLOYEE',
    'DELETE_EMPLOYEE',
    'UPDATE_EMPLOYEE',
    'DEMOTE_EMPLOYEE',

    'READ_INACTIVE_EMPLOYEE',
    'DEACTIVATE_EMPLOYEE',
    'REACTIVATE_EMPLOYEE',

    'READ_PEOPLE',
    'ADD_PEOPLE',
    'DELETE_PEOPLE',
    'UPDATE_PEOPLE',
    'DEMOTE_PEOPLE',

    'READ_PAYMENT',
    'ADD_NEW_PAYMENT',
    'CREATE_INVOICE',
    'EXPORT_INVOICE',
  ],
  admin: [
    'READ_JOBS',
    'ADD_JOBS',
    'DELETE_JOBS',
    'UPDATE_JOBS',

    'READ_JOB',
    'ADD_JOB',
    'DELETE_JOB',
    'UPDATE_JOB',
    'PUBLISH_JOB',
    'ASSIGN_JOB',
    'FINISH_JOB',
    'ACCEPT_JOB',
    'REJECTS_JOB',

    'READ_EMPLOYEE',

    'READ_PEOPLE',
    'ADD_PEOPLE',
    'DELETE_PEOPLE',
    'UPDATE_PEOPLE',
    'DEMOTE_PEOPLE',

    'READ_PAYMENT',
  ],
  finance: [
    'READ_JOBS',

    'READ_JOB',

    'READ_EMPLOYEE',

    'READ_PEOPLE',

    'READ_PAYMENT',
    'ADD_NEW_PAYMENT',
    'CREATE_INVOICE',
    'EXPORT_INVOICE',
  ],
  readOnly: ['READ_JOBS', 'READ_JOB', 'READ_EMPLOYEE', 'READ_PAYMENT', 'READ_PEOPLE'],
};

export default permissions;
