import React from 'react';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

import image from 'ui/assets/popup_notify.svg';

interface ResumeJobModalProps {
  open: boolean;
  onResume: () => void;
  onCancel: () => void;
}

export default function ResumeJobModal(props: ResumeJobModalProps) {
  const { open, onResume, onCancel } = props;

  return (
    <ConfirmationModal
      open={open}
      title="Resume job"
      titleOptions={{ color: 'blue' }}
      subtitle="Are you sure you want to resume this job?"
      subtitleOptions={{ color: 'black' }}
      img={{ src: image, alt: 'resume job' }}
      primary={{ text: 'Resume', color: 'blue', onClick: onResume }}
      ghost={{ text: 'Cancel', color: 'blue', onClick: onCancel }}
    ></ConfirmationModal>
  );
}
