import React from 'react';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

import image from 'ui/assets/popup_notify.svg';

interface RestoreFolderModalProps {
  open: boolean;
  onRestore: () => void;
  onCancel: () => void;
}

export default function RestoreFolderModal(props: RestoreFolderModalProps) {
  const { open, onRestore, onCancel } = props;

  return (
    <ConfirmationModal
      open={open}
      title="Restore folder"
      titleOptions={{ color: 'blue' }}
      subtitle="Are you sure you want to restore this folder?"
      subtitleOptions={{ color: 'black' }}
      img={{ src: image, alt: 'restore folder' }}
      primary={{ text: 'Restore', color: 'blue', onClick: onRestore }}
      ghost={{ text: 'Cancel', color: 'blue', onClick: onCancel }}
    ></ConfirmationModal>
  );
}
