import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { FaSortAlphaUp, FaSortAlphaDown } from 'react-icons/fa';

import moment from 'moment';
import { SortBy, IconWithTooltip, Search } from 'ui/molecules';
import Responsive from 'helper/responsive';
import { Toolbar, Input, Spacer, Row, ToggleButton } from 'ravenry-ui';

import colors from '../../colors';

const Ascending = styled(FaSortAlphaDown)``;

const Descending = styled(FaSortAlphaUp)``;

const ViewOptionsContainer = styled.div`
  height: 32px;
  display: flex;
  cursor: pointer;

  background: ${colors.white};
  box-sizing: border-box;

  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 5px 5px;
`;

interface Parameter {
  q?: string;
  _sort?: string;
  _order?: string;
  _show?: string;
  _from?: string;
  _to?: string;
}

interface Props {
  searchPlaceholder?: string;
  searchKey?: string;
  searchValue?: string;
  searchWidth?: string;
  height?: string | number;
  from?: string;
  to?: string;
  filter?: {
    shown: boolean;
    toggled: boolean;
    onClick?: (e: React.MouseEvent) => void;
  };
  /** @deprecated since UI that needs this props no longer exists */
  viewOptions?:
    | [
        {
          icon: string;
          onClick: MouseEventHandler<HTMLDivElement>;
          tooltipText: string;
          active: boolean;
          id?: string;
        },
      ]
    | [];
  orderBy: string;
  orderByWidth?: string;
  orderByOptions: {
    label: React.ReactNode;
    value: string;
  }[];
  sortBy: 'asc' | 'desc';
  onChange: ({}: Parameter) => void;
  action?: React.FC;
  showBy?: string;
  showOptions?: { label: React.ReactNode; value: string }[] | [];
  orderByDataCy?: string;
  sortByDataCy?: string;
}

export default function QueryToolbar({
  onChange,
  height,
  searchPlaceholder = 'Search',
  searchKey = 'q',
  searchValue = '',
  searchWidth,
  from,
  to,
  filter = {
    shown: false,
    toggled: false,
    onClick: undefined,
  },
  viewOptions = [],
  showBy,
  showOptions = [],
  orderByOptions,
  orderBy,
  orderByWidth,
  sortBy,
  action,
  orderByDataCy,
  sortByDataCy,
}: Props) {
  const { isMobile } = Responsive();
  return (
    <Toolbar height={isMobile ? undefined : height} column={isMobile}>
      <Toolbar.Left wrap={isMobile}>
        {from && (
          <>
            <div style={{ marginRight: '8px' }}>From</div>
            <Input.DatePicker
              width="200px"
              onChange={(e: any) =>
                onChange({
                  _from: moment(e.target.value).format('dddd, D MMM YYYY'),
                })
              }
              value={from}
            />
          </>
        )}
        {to && (
          <>
            <div style={{ margin: '0px 8px' }}>to</div>
            <Input.DatePicker
              width="200px"
              onChange={(e: any) =>
                onChange({
                  _to: moment(e.target.value).format('dddd, D MMM YYYY'),
                })
              }
              value={to}
            />
            <Toolbar.Spacer />
          </>
        )}

        {viewOptions.length ? (
          <>
            <ViewOptionsContainer>
              {viewOptions.map((opt, i, { length }) => (
                <Row
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    width: '36px',
                    border: opt.active ? `1px solid ${colors.blue}` : `1px solid ${colors.black5}`,
                    borderRadius: i === 0 ? '5px 0 0 5px' : i === length - 1 ? '0 5px 5px 0' : '0',
                    background: opt.active ? `${colors.blue20}` : undefined,
                  }}
                  key={`option-${opt.icon}-${opt.tooltipText}`}
                  onClick={opt.onClick}
                  id={opt.id}
                >
                  <IconWithTooltip
                    name={opt.icon}
                    onClick={opt.onClick}
                    content={opt.tooltipText}
                    fill={opt.active ? 'blue90' : 'black40'}
                    hoverFill={opt.active ? 'blue90' : undefined}
                    arrowPosition="bottom center"
                    position={{ top: '-32px', left: '-29px' }}
                  />
                </Row>
              ))}
            </ViewOptionsContainer>
            <Spacer size="16" display="block" horizontal />
          </>
        ) : null}

        <Search
          onSearch={(value: string) => onChange({ [searchKey]: value })}
          datacy="cta-search-query"
          value={searchValue}
          placeholder={searchPlaceholder}
          width={
            isMobile ? '100%' : searchWidth ? searchWidth : showOptions.length && showBy && '382px'
          }
          withoutMarginRight
        />
        <Toolbar.Spacer />

        {filter.shown && (
          <>
            {/* this */}
            <ToggleButton
              icon="filter"
              data-cy="cta-show-filter"
              toggled={filter.toggled}
              onClick={filter.onClick}
            >
              Filter
            </ToggleButton>
            <Toolbar.Spacer />
          </>
        )}

        <SortBy
          options={orderByOptions}
          selectedLabelPrefix={isMobile ? '' : 'Sort by'}
          value={orderBy}
          initialLabel={orderByOptions?.find((option) => option.value === orderBy)?.label}
          fieldName="_order"
          onSelect={(value: { _order?: string; _sort?: string; _show?: string }) => onChange(value)}
          width={orderByWidth || '150px'}
          dataCy={orderByDataCy}
        />
        <Toolbar.Spacer />

        <SortBy
          options={[
            {
              value: 'asc',
              icon: <Ascending />,
              label: 'A to Z',
            },
            {
              value: 'desc',
              icon: <Descending />,
              label: 'Z to A',
            },
          ]}
          value={sortBy}
          fieldName="_sort"
          onSelect={(value: { _order?: string; _sort?: string; _show?: string }) => onChange(value)}
          width="64px"
          padding="6px 8px"
          dropdownWidth="max-content"
          dataCy={sortByDataCy}
        />

        {showOptions.length && showBy ? (
          <>
            <Toolbar.Spacer />

            <SortBy
              options={showOptions}
              selectedLabelPrefix={isMobile ? '' : 'Show'}
              value={showBy}
              fieldName="_show"
              onSelect={(value: { _order?: string; _sort?: string; _show?: string }) =>
                onChange(value)
              }
              width="130px"
            />
          </>
        ) : null}
      </Toolbar.Left>
      <Toolbar.Right>{action}</Toolbar.Right>
    </Toolbar>
  );
}
