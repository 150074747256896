import React from 'react';
import styled from 'styled-components';
import colors from 'ui/colors';
import getElevation from 'ui/elevations';

import { Button, Divider, Icon, Text, Row, Spacer, Toggle, Input, Span } from 'ravenry-ui';
import { Fields, IconButton, IconWithTooltip } from '../../molecules';

const Root = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '1000px'};
  margin: auto;
  padding: 16px 24px 24px;
  border-radius: 10px;
  background-color: ${colors.backgroundWhite};

  ${getElevation(2)}
`;

interface QuestionCardProps {
  main?: boolean;
  question: any;
  first?: boolean;
  last?: boolean;
  width?: string;
  /**
   * Set the card to have No. [number]
   */
  number?: number;
  /**
   * Render only QUESTION and DESCRIPTION fields
   */
  simplified?: boolean;
  excludedQuestionTypes?: string[];
  error?: {
    label?: string;
    type?: string;
  };

  onUpdateOptions?: (updatedOptions: any) => void;
  onChangePosition?: (direction: -1 | 1) => void;
  onChangeQuestionType?: (
    { label, value }: { label: string; value: string },
    needOptions: boolean,
  ) => void;
  onChangeField?: (field: string, value: string) => void;
  onToggleRequired?: () => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
}

export default function QuestionCard(props: QuestionCardProps) {
  const {
    main,
    question,
    first,
    last,
    width,
    number,
    simplified,
    excludedQuestionTypes = [],
    error,

    onUpdateOptions,
    onChangePosition,
    onChangeQuestionType,
    onChangeField,
    onToggleRequired,
    onDuplicate,
    onDelete,
  } = props;

  const labelKeyOptions = {
    text: 'Short answer',
    number: 'Budget',
    richtext: 'Paragraph',
    location: 'Location',
    tags: 'Tag',
    email: 'Email',
    file: 'Attachment',
    choice: 'Multiple choice',
    multiple: 'Checkbox',
    date: 'Date',
    dropdown: 'Dropdown',
    deadline: 'Deadline',
    options: 'Currency',
    percentage: 'Percentage',
    subtask: 'Subtask',
  };

  const answerOptions = question.options || [];

  return (
    <Root width={width}>
      <Row justifyContent="space-between">
        <Row alignItems="center">
          {typeof number === 'number' && number !== 0 && <Text _as="s5">No. {number}</Text>}
        </Row>
        <Row justifyContent="flex-end" alignItems="center">
          <Text _as="s5" color="black60">
            Question type
          </Text>
          <Spacer size="8" />
          <Input.Select
            style={{ width: '180px' }}
            placeholder="Select question type"
            value={
              question.type
                ? {
                    value: question.type,
                    label: <strong>{labelKeyOptions[question.type]}</strong>,
                  }
                : null
            }
            options={[
              'text',
              'number',
              'richtext',
              'location',
              'tags',
              'email',
              'file',
              'choice',
              'multiple',
              'deadline',
              'percentage',
              'subtask',
            ]
              .filter((opt) => !excludedQuestionTypes.includes(opt))
              .map((opt) => ({ value: opt, label: labelKeyOptions[opt] }))}
            onChange={(e: { label: string; value: string }) => {
              const needOptions = ['choice', 'multiple'].includes(e.value);

              if (typeof onChangeQuestionType === 'function') {
                onChangeQuestionType(e, needOptions);
              }
            }}
            disabled={main}
            error={error?.type || ''}
          />
        </Row>
      </Row>
      <Spacer size="8" display="block" />
      <Divider offset="24" color="black10" />
      <Spacer size="16" display="block" />

      {simplified ? (
        <>
          <Fields.Text
            label="QUESTION"
            _labelAs="s5"
            space="16"
            disabled={main}
            fluid
            placeholder="Type in the question"
            required
            error={error?.label || ''}
            value={question.label}
            onChange={(e) => {
              if (typeof onChangeField === 'function') {
                onChangeField('label', e.target.value);
              }
            }}
          />
          <Spacer size="24" display="block" />
          <Fields.Text
            label="DESCRIPTION"
            _labelAs="s5"
            space="16"
            fluid
            disabled={main}
            placeholder="Add further description or instructions to answer the question"
            value={question.subtitle}
            onChange={(e) => {
              if (typeof onChangeField === 'function') {
                onChangeField('subtitle', e.target.value);
              }
            }}
          />
        </>
      ) : (
        <>
          <Fields.Text
            label="LABEL IN QB"
            _labelAs="s5"
            space="16"
            disabled={main}
            fluid
            value={question.label}
            onChange={(e) => {
              if (typeof onChangeField === 'function') {
                onChangeField('label', e.target.value);
              }
            }}
          />
          <Spacer size="24" display="block" />
          <Fields.Text
            label="LABEL INSTRUCTION"
            _labelAs="s5"
            space="16"
            fluid
            disabled={main}
            value={question.subtitle}
            onChange={(e) => {
              if (typeof onChangeField === 'function') {
                onChangeField('subtitle', e.target.value);
              }
            }}
          />

          <Spacer size="24" display="block" />
          <Fields.Text
            label="PLACEHOLDER ANSWER"
            _labelAs="s5"
            space="16"
            disabled={main}
            fluid
            value={
              answerOptions.length && question.type !== 'dropdown' ? 'Auto' : question.placeholder
            }
            onChange={(e) => {
              if (typeof onChangeField === 'function') {
                onChangeField('placeholder', e.target.value);
              }
            }}
          />
          <Spacer size="24" display="block" />

          <Fields.Text
            label="LABEL IN BRIEF"
            _labelAs="s5"
            space="16"
            disabled={main}
            fluid
            value={question.labelInBrief}
            onChange={(e) => {
              if (typeof onChangeField === 'function') {
                onChangeField('labelInBrief', e.target.value);
              }
            }}
          />

          <Spacer size="24" display="block" />

          <Fields.Text
            label="ERROR MESSAGE"
            _labelAs="s5"
            space="16"
            disabled={main}
            fluid
            value={question.errorMessage}
            onChange={(e) => {
              if (typeof onChangeField === 'function') {
                onChangeField('errorMessage', e.target.value);
              }
            }}
          />
        </>
      )}

      <Spacer size="24" display="block" />

      {answerOptions.length && question.type !== 'dropdown' ? (
        <>
          {answerOptions.map((opt, i) => (
            <React.Fragment key={`answer-options-${i}-${opt.value}`}>
              <Row noWrap alignItems="center">
                {question.type === 'multiple' ? (
                  <>
                    <Input.Check />
                    <Spacer size="16" display="block" horizontal />
                  </>
                ) : null}
                {question.type === 'choice' ? (
                  <>
                    <Input.Radio />
                    <Spacer size="16" display="block" horizontal />
                  </>
                ) : null}
                {opt.value === 'other' ? (
                  <>
                    <Text size="14px" lineHeight="16px" color="black60">
                      Other...
                    </Text>
                    <Spacer filler />
                  </>
                ) : (
                  <Fields.Text
                    width="628px"
                    name={`option-${opt.value}`}
                    defaultValue={opt.label}
                    placeholder={`Option ${i + 1}`}
                    onBlur={(e) => {
                      const updatedOptions = [...answerOptions];

                      updatedOptions[i] = {
                        label: e.target.value,
                        value: e.target.value.toLowerCase().split(' ').join('_'),
                      };

                      if (typeof onUpdateOptions === 'function') {
                        onUpdateOptions(updatedOptions);
                      }
                    }}
                  />
                )}
                {answerOptions.length > 1 ? (
                  <>
                    <Spacer size="16" display="block" horizontal />

                    <IconButton
                      name="delete"
                      onClick={() => {
                        if (!main) {
                          const firstPart = answerOptions.slice(0, i);
                          const secondPart = answerOptions.slice(i + 1);

                          if (typeof onUpdateOptions === 'function') {
                            onUpdateOptions([...firstPart, ...secondPart]);
                          }
                        }
                      }}
                      tooltipOptions={{
                        position: {
                          top: '-48px',
                          right: '-40px',
                        },
                        content: 'Remove option',
                        arrowPosition: 'bottom-center',
                      }}
                    />
                  </>
                ) : null}
              </Row>
              <Spacer size="16" display="block" />
            </React.Fragment>
          ))}

          {['choice', 'multiple'].includes(question.type) ? (
            <>
              <Row noWrap alignItems="center">
                {question.type === 'multiple' ? (
                  <>
                    <Input.Check />
                    <Spacer size="16" display="block" horizontal />
                  </>
                ) : null}
                {question.type === 'choice' ? (
                  <>
                    <Input.Radio />
                    <Spacer size="16" display="block" horizontal />
                  </>
                ) : null}

                <Row>
                  <Text
                    size="14px"
                    lineHeight="16px"
                    bold
                    color="black60"
                    clickable
                    onClick={() => {
                      const existingOptions = [...answerOptions];
                      const otherOptionIndex = existingOptions.findIndex(
                        (option) => option.value === 'other',
                      );
                      let otherOption = [];
                      if (otherOptionIndex > 0) {
                        otherOption = existingOptions.splice(otherOptionIndex, 1);
                      }
                      const updatedOptions = [
                        ...existingOptions,
                        { label: '', value: '' },
                        ...otherOption,
                      ];

                      if (typeof onUpdateOptions === 'function') {
                        onUpdateOptions(updatedOptions);
                      }
                    }}
                  >
                    Add option
                  </Text>
                  {!answerOptions.map((opt) => opt.value).includes('other') ? (
                    <Text
                      size="14px"
                      lineHeight="16px"
                      bold
                      color="black60"
                      style={{ marginTop: 0 }}
                    >
                      &nbsp;or{' '}
                      <Span
                        color="blue"
                        bold
                        clickable
                        onClick={() => {
                          const updatedOptions = [
                            ...answerOptions,
                            { label: 'Other', value: 'other' },
                          ];

                          if (typeof onUpdateOptions === 'function') {
                            onUpdateOptions(updatedOptions);
                          }
                        }}
                      >
                        add &quot;Other&quot;
                      </Span>
                    </Text>
                  ) : null}
                </Row>
              </Row>
              <Spacer size="16" display="block" />
            </>
          ) : (
            ''
          )}
        </>
      ) : null}

      <Divider offset="24" />

      <Spacer size="16" display="block" />
      <Row justifyContent="space-between" style={{ width: '100%' }}>
        <Row>
          <IconButton
            name="chevron up"
            onClick={() => {
              if (!(main || first)) {
                if (typeof onChangePosition === 'function') {
                  onChangePosition(1);
                }
              }
            }}
            tooltipOptions={{
              content: 'Move up',
              position: { top: '-48px', right: '-25px' },
              arrowPosition: 'bottom-center',
            }}
            disabled={main || first}
          ></IconButton>
          <Spacer size="16" horizontal />
          <IconButton
            name="chevron down"
            onClick={() => {
              if (!(main || last)) {
                if (typeof onChangePosition === 'function') {
                  onChangePosition(-1);
                }
              }
            }}
            tooltipOptions={{
              content: 'Move down',
              position: { top: '-48px', right: '-32px' },
              arrowPosition: 'bottom-center',
            }}
            disabled={main || last}
          ></IconButton>
        </Row>
        <Row alignItems="center">
          <Text _as="b2" color="black40">
            Required
          </Text>
          <Spacer size="16" horizontal />
          <Toggle disabled={main} checked={question.required} onChange={onToggleRequired} />
          <Spacer size="16" horizontal />
          <Button color="blue" variant="outlined" width="150px" onClick={onDuplicate}>
            Duplicate
          </Button>
          <Spacer size="16" horizontal />
          <Button color="red" width="150px" disabled={main} onClick={onDelete}>
            Delete question
          </Button>
        </Row>
      </Row>
    </Root>
  );
}
