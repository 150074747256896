import { useEffect } from 'react';

/**
 * useOutsideClick detects click outside specified element(s)
 *
 * @param {Object | Array} ref HTML ref
 * @param {Function} callback
 */
export const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && ref.current.contains && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
