import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';

import Responsive from 'helper/responsive';
import PrivateRoute from './PrivateRoute';
import { getCookie } from '../helper/utils';

// public
const AuthLandingPage = loadable(() => import('../modules/Auth/AuthLandingPage'));
const Activation = loadable(() => import('../modules/Auth/Activation'));
const Login = loadable(() => import('../modules/Auth/Login'));

const Signup = loadable(() => import('../modules/Auth/Signup/Signup'));

const PasswordUpdated = loadable(() => import('../modules/Settings/PasswordUpdated'));
const ResetPassword = loadable(() => import('../modules/Auth/ResetPassword/ResetPassword'));
const ForgotPassword = loadable(() => import('../modules/Auth/ForgotPassword/ForgotPassword'));

const PublicAdminJob = loadable(() => import('modules/Admin/Job/Detail/Detail.tsx'));

// freelancer
const Profile = loadable(() => import('../modules/Profile'));
const FreelancerJobBoard = loadable(() => import('modules/Freelancer/JobBoard'));
const FreelancerEarnings = loadable(() => import('modules/Freelancer/Earnings/Earnings'));
const FreelancerProfileWizard = loadable(() => import('modules/Profile/ProfileWizard'));
const Settings = loadable(() => import('modules/Settings'));
const SettingsChangePassword = loadable(() => import('modules/Settings/ResetPassword'));

// admin
const AdminJobDetail = loadable(() => import('modules/Admin/Job/Detail/Detail.tsx'));
const AdminFreelancerList = loadable(() => import('modules/Admin/Freelancer/List'));
const AdminProjectList = loadable(() => import('modules/Admin/Project'));
const AdminProjectDetail = loadable(() => import('modules/Admin/Project/Detail'));
const AdminClientList = loadable(() => import('modules/Admin/Client/List'));
const AdminPaymentHistory = loadable(() => import('modules/Admin/Payment/PaymentHistory'));
const JobEditPage = loadable(() => import('modules/Admin/Job/Edit'));
const AdminSettingsPage = loadable(() => import('modules/Admin/Settings/Settings'));
const AdminReportsPage = loadable(() => import('modules/Admin/Report'));
const AdminSettingsJobTypeListPage = loadable(() => import('modules/Admin/Settings/JobTypeList'));
const AdminSettingsJobTypeDetailMenu = loadable(() =>
  import('modules/Admin/Settings/JobTypeDetail/Menu'),
);
const AdminSettingsQBEditor = loadable(() => import('modules/Admin/Settings/QBEditor'));
const AdminSettingsServiceFee = loadable(() =>
  import('modules/Admin/Settings/JobTypeDetail/ServiceFee'),
);
const AdminSettingsAnalyticsReport = loadable(() =>
  import('modules/Admin/Settings/AnalyticsReport'),
);

// const Dynaform = loadable(() => import("modules/Dynaform"));
const AdminJobCreate = loadable(() => import('modules/Admin/Job/Create'));
const ChooseClient = loadable(() => import('modules/Job/ChooseClient'));

// client
const JobList = loadable(() => import('modules/Job/List'));
const Dashboard = loadable(() => import('modules/Dashboard'));
const JobDetails = loadable(() => import('modules/Job/Detail'));
const JobPayment = loadable(() => import('../modules/Client/Job/Detail/Payment'));
const ClientPaymentHistory = loadable(() => import('modules/Client/Payment/PaymentHistory'));
const ClientDetailPayment = loadable(() => import('modules/Client/Payment/PaymentDetail'));

const ClientCreateJob = loadable(() => import('modules/Job/Create'));
const FindFreelancers = loadable(() => import('modules/Job/FindFreelancers'));
const ClientMyFreelancers = loadable(() => import('modules/Client/Freelancer/MyFreelancers'));
const ClientProfileWizard = loadable(() => import('modules/Client/ProfileWizard'));

const Playground = loadable(() => import('ui/Playground'));

const MobileProfile = loadable(() => import('mobile/shared/Profile/Profile'));
const MobileNotifications = loadable(() => import('mobile/shared/Notification/Notification'));

const PublicProfile = loadable(() => import('../modules/Profile'));

const routeList = [
  // auth
  {
    path: '/auth',
    header: null,
    component: AuthLandingPage,
    Route,
    name: 'auth',
  },
  {
    path: '/auth/activate/:token',
    header: null,
    component: Activation,
    Route,
    name: 'activate password',
  },
  {
    path: '/profile/:userId',
    header: 'freelancer profile',
    component: Profile,
    Route: PrivateRoute,
    name: 'freelancer profile',
  },
  {
    path: '/freelancers/:userId',
    header: 'freelancer profile',
    component: PublicProfile,
    Route,
    name: 'freelancer profile',
  },

  // freelancer
  {
    path: '/freelancer/signup',
    component: Signup,
    Route,
    name: 'freelancer signup',
  },
  {
    path: '/freelancer/login',
    component: Login,
    Route,
    name: 'freelancer login',
  },
  {
    path: '/freelancer/forgot-password',
    component: ForgotPassword,
    Route,
    name: 'freelancer forgot password',
  },
  {
    path: '/freelancer/reset-password/:token',
    component: ResetPassword,
    Route,
    name: 'freelancer reset password',
  },
  {
    path: '/freelancer/password-updated',
    component: PasswordUpdated,
    Route,
    name: 'freelancer password update',
  },
  {
    path: '/freelancer/dashboard',
    header: 'My Dashboard',
    component: Dashboard,
    Route: PrivateRoute,
    domain: 'freelancer',
    name: 'freelancer dashboard',
  },
  {
    path: '/freelancer/jobs',
    header: 'My Jobs',
    component: JobList,
    Route: PrivateRoute,
    domain: 'freelancer',
    name: 'freelancer jobs',
  },
  {
    path: '/freelancer/job-board',
    header: 'Job Board',
    component: FreelancerJobBoard,
    Route: PrivateRoute,
    domain: 'freelancer',
  },
  {
    path: '/freelancer/job-board/:jobId',
    header: 'freelancer job board details',
    component: JobDetails,
    Route: PrivateRoute,
    domain: 'freelancer',
    name: 'freelancer job board details',
  },
  {
    path: '/freelancer/profile',
    header: 'freelancer profile',
    component: MobileProfile,
    Route: PrivateRoute,
    domain: 'freelancer',
    device: 'mobile',
    name: 'freelancer profile',
  },
  {
    path: '/freelancer/profile/:userId',
    header: 'freelancer profile',
    component: Profile,
    Route: PrivateRoute,
    domain: 'freelancer',
    name: 'freelancer profile',
  },
  {
    path: '/freelancer/jobs/:jobId',
    header: 'freelancer job details',
    component: JobDetails,
    Route: PrivateRoute,
    domain: 'freelancer',
    name: 'freelancer job details',
  },
  {
    path: '/freelancer/jobs/:jobId/:userId',
    header: null,
    component: Profile,
    Route: PrivateRoute,
    domain: 'freelancer',
    name: 'freelancer job client profile',
  },
  {
    path: '/freelancer/earnings',
    header: 'freelancer earnings',
    component: FreelancerEarnings,
    Route: PrivateRoute,
    domain: 'freelancer',
    name: 'freelancer job earnings',
  },

  {
    path: '/freelancer/profile-wizard',
    component: FreelancerProfileWizard,
    Route: PrivateRoute,
    domain: 'freelancer',
    name: 'freelancer profile wizard',
  },
  {
    path: '/freelancer/settings',
    component: Settings,
    Route: PrivateRoute,
    name: 'freelancer profile settings',
  },
  {
    path: '/freelancer/settings/change-password',
    component: SettingsChangePassword,
    Route: PrivateRoute,
    domain: 'freelancer',
    name: 'freelancer settings change password',
  },

  // client
  {
    path: '/client/signup',
    component: Signup,
    Route,
    name: 'client signup',
  },
  {
    path: '/client/login',
    component: Login,
    Route,
    name: 'client login',
  },
  {
    path: '/client/forgot-password',
    component: ForgotPassword,
    Route,
    name: 'client forgot password',
  },
  {
    path: '/client/reset-password/:token',
    component: ResetPassword,
    Route,
    name: 'client reset password',
  },
  {
    path: '/client/password-updated',
    component: PasswordUpdated,
    Route,
    domain: 'client',
    name: 'client password updated',
  },
  {
    path: '/client/dashboard',
    header: 'My Dashboard',
    component: Dashboard,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client dashboard',
  },
  {
    path: '/client/dashboard/new',
    header: 'New job',
    component: ClientCreateJob,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client create job',
  },
  {
    path: '/client/dashboard/:jobId',
    header: 'Edit Draft job',
    component: ClientCreateJob,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client edit draft job',
  },
  {
    path: '/client/dashboard/:jobId/find-freelancers',
    header: 'Find Freelancers',
    component: FindFreelancers,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client job find freelancers',
  },
  {
    path: '/client/dashboard/:jobId/find-freelancers/:userId',
    header: null,
    component: Profile,
    Route: PrivateRoute,
    domain: 'client',
    name: "client job details' freelancer profile",
  },
  {
    path: '/client/jobs',
    header: 'My Jobs',
    component: JobList,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client jobs',
  },
  {
    path: '/client/jobs/:jobId',
    header: 'Job Details',
    component: JobDetails,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client job details',
  },
  {
    path: '/client/jobs/:jobId/:userId',
    header: null,
    component: Profile,
    Route: PrivateRoute,
    domain: 'client',
    name: "client job details' freelancer profile",
  },
  {
    path: '/client/jobs/:jobId/offer/:freelancerId/payment',
    header: null,
    component: JobPayment,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client job payment',
  },
  {
    path: '/client/payments',
    header: null,
    component: ClientPaymentHistory,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client payment history',
  },
  {
    path: '/client/payments/:paymentid',
    header: null,
    component: ClientDetailPayment,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client payment preview',
  },
  {
    path: '/client/freelancers',
    header: null,
    component: ClientMyFreelancers,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client my freelancers',
  },
  {
    path: '/client/freelancers/:userId',
    header: null,
    component: Profile,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client freelancer profile',
  },
  {
    path: '/client/notifications',
    header: null,
    component: MobileNotifications,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client notifications',
  },
  {
    path: '/client/profile',
    header: null,
    component: MobileProfile,
    Route: PrivateRoute,
    domain: 'client',
    device: 'mobile',
    name: 'client profile',
  },
  {
    path: '/client/profile/:userId',
    header: null,
    component: Profile,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client profile',
  },
  {
    path: '/client/profile-wizard',
    component: ClientProfileWizard,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client profile wizard',
  },
  {
    path: '/client/settings',
    component: Settings,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client settings',
  },
  {
    path: '/client/settings/change-password',
    component: SettingsChangePassword,
    Route: PrivateRoute,
    domain: 'client',
    name: 'client settings change password',
  },

  // god
  {
    path: '/god/freelancers',
    header: 'Freelancers',
    component: AdminFreelancerList,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin freelancers list',
  },
  {
    path: '/god/freelancers/:userId',
    header: 'Freelancer Profile',
    component: Profile,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin freelancer profile',
  },
  {
    path: '/god/clients',
    header: 'Clients',
    component: AdminClientList,
    Route: PrivateRoute,
    domain: 'admin',
    name: "admin's clients",
  },
  {
    path: '/god/clients/:userId',
    header: 'Client Profile',
    component: Profile,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin client profile',
  },
  {
    path: '/god/payments',
    header: 'Payments',
    component: AdminPaymentHistory,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin payments',
  },
  {
    path: '/god/jobs/:jobId/freelancers/:userId',
    header: 'Freelancer Profile',
    component: Profile,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin job freelancer profile',
    exact: true,
  },
  {
    path: '/god/settings',
    header: null,
    component: AdminSettingsPage,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin settings',
  },
  {
    path: '/god/settings/job_types',
    header: null,
    component: AdminSettingsJobTypeListPage,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin settings job types',
  },
  {
    path: '/god/settings/job_types/:jobTypeId',
    header: null,
    component: AdminSettingsJobTypeDetailMenu,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin job settings job type detail',
  },
  {
    path: '/god/settings/job_types/:jobTypeId/editor',
    header: null,
    component: AdminSettingsQBEditor,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin job settings job type edit',
  },
  {
    path: '/god/settings/job_types/:jobTypeId/service_fee',
    header: null,
    component: AdminSettingsServiceFee,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin job settings job type service fee',
  },
  {
    path: '/god/settings/analytics_report',
    header: null,
    component: AdminSettingsAnalyticsReport,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin settings analytics report',
  },
  {
    path: '/god/new',
    header: null,
    component: AdminJobCreate,
    Route: PrivateRoute,
    exact: true,
    name: 'admin create job',
  },
  {
    path: '/god/jobs/choose-client',
    header: null,
    component: ChooseClient,
    Route: PrivateRoute,
    domain: 'admin',
    exact: true,
    name: 'admin choose client',
  },
  {
    path: '/god/jobs/new',
    header: null,
    component: ClientCreateJob,
    Route: PrivateRoute,
    domain: 'admin',
    exact: true,
    name: 'admin create job',
  },
  {
    path: '/god/jobs',
    header: 'Jobs',
    component: JobList,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin jobs',
    exact: true,
  },
  {
    path: '/god/jobs/:jobId',
    header: 'Job Details',
    component: AdminJobDetail,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin job detail',
    exact: true,
  },
  {
    path: '/god/projects/:jobId',
    header: 'Projects Details',
    component: AdminProjectDetail,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin project detail',
    exact: true,
  },
  {
    path: '/god/jobs/:jobId/edit',
    header: 'Job Details',
    component: JobEditPage,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin job edit',
    exact: true,
  },
  {
    path: '/god/projects',
    header: 'Jobs',
    component: AdminProjectList,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin jobs',
    exact: true,
  },
  {
    path: '/god/reports',
    header: 'Jobs',
    component: AdminReportsPage,
    Route: PrivateRoute,
    domain: 'admin',
    name: 'admin jobs',
    exact: true,
  },
  {
    path: '/god/setting',
    header: null,
    component: MobileProfile,
    Route: PrivateRoute,
    exact: true,
    name: 'admin settings',
  },
  {
    path: '/dev/pg',
    header: null,
    component: process.env.NODE_ENV === 'production' ? null : Playground,
    Route,
    exact: true,
    name: 'playground',
  },

  {
    path: '/public/jobs/:jobId',
    header: null,
    component: PublicAdminJob,
    Route,
    exact: true,
    name: 'public admin job',
  },
];

export default function Routes() {
  const { isMobile } = Responsive();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const redirectRoot = () => {
      const domain = getCookie('DOMAIN');
      //console.log('Current domain:', domain);
      let path = '/auth';

      switch (domain) {
        case 'admin':
          path = '/god/jobs';
          break;
        case 'freelancer':
          path = '/freelancer/dashboard';
          break;
        case 'client':
          path = '/client/dashboard';
          break;
        default:
          //console.log('No valid domain found, staying on /auth');
          return; // Don't redirect if no valid domain
      }

      //console.log('Redirecting to:', path);
      history.push(path);
    };

    if (location.pathname === '/' || location.pathname === '/auth') {
      redirectRoot();
    }
  }, [history, location]);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/auth" />
      </Route>
      {routeList.map((data) => {
        if (data.device === 'mobile' && !isMobile) {
          return null;
        }
        return (
          <data.Route
            path={data.path}
            component={data.component}
            exact
            header={data.header}
            domain={data.domain}
            key={`route-${data.path}-${data.header}`}
            name={data.name}
          />
        );
      })}
      <Redirect path="*" to="/" />
    </Switch>
  );
}
