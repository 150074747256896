import axios from 'axios';
import * as Sentry from '@sentry/react';
import { deleteAllCookies, logoutUser, setCookie, getCookie } from './utils';

function NetworkError(message) {
  this.name = 'NetworkError';
  this.message = message;
  this.stack = new Error().stack;
}
NetworkError.prototype = Object.create(Error.prototype);
NetworkError.prototype.constructor = NetworkError;

const _shouldLogOut = ({ status, config, data: { message } }) => {
  if (
    (status === 401 && ['refresh'].some((path) => config.url.includes(path))) ||
    (status === 400 && message === 'Validation Error' && config.url.includes('refresh'))
  ) {
    return true;
  }

  return false;
};

export default class IamApi {
  constructor() {
    this.token = null;
    this.client = null;
    this.api_url = process.env.REACT_APP_API_URL;
  }

  init = () => {
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + getCookie('ACCESS_TOKEN'),
    };

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers,
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        if (_shouldLogOut(response)) {
          logoutUser();
          deleteAllCookies();
          setTimeout(() => (window.location = 'auth/login'), 1000);
        }
        Sentry.setTag('code', error?.response?.status);
        Sentry.setTag('endpoint', error?.response?.config?.url);
        Sentry.setExtras({
          extra: { config: error?.response?.config, error: error?.response?.data },
        });
        Sentry.captureException(new NetworkError(error?.message));
        return Promise.reject(error);
      },
    );

    return this.client;
  };

  auth = () =>
    this.init()
      .post('/auth/refresh', { refreshToken: getCookie('REFRESH_TOKEN'), platform: 'ravenry' })
      .then((resp) => {
        setCookie('ACCESS_TOKEN', resp.data.accessToken);
      });

  // this should be removed, in favor of createUser
  register = (data) => this.init().post('/users', data);

  createUser = (data) => this.init().post('/users', data);

  registerReal(payload) {
    return this.init().post('/auth/register', payload);
  }

  login(payload) {
    return this.init().post('/auth/login', payload);
  }

  logout(payload) {
    return this.init().post('/auth/logout', payload);
  }

  linkedIn(payload) {
    return this.init().post('/auth/linkedin', payload);
  }

  createPassword(payload, token) {
    return this.init().post(`/auth/set-password/${token}`, payload);
  }

  requestResetPassword(payload) {
    return this.init().put('/auth/reset-password', payload);
  }

  activateAccount(token) {
    return this.init().post(`/auth/activate/${token}`);
  }

  checkTokenValidity(token) {
    return this.init().get(`/auth/validity/${token}`);
  }

  changePassword(payload) {
    return this.init().post('/auth/change-password', payload);
  }

  resendActivationEmail = (userId) => this.init().post('/auth/resend-activation-email', { userId });

  getFirebaseToken = (params) => this.init().get('/auth/firebase-auth', { params });

  verifyCaptcha = (data) => this.init().post('/captcha', data);
}
