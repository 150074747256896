import React from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'rsuite';

import { Card, CardLabel, CardValue, CardItem } from 'ui/molecules';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Loading = styled.div`
  position: fixed;
  bottom: 0%;
  /* background-color: white; */
  padding: 16px 0px;
`;

/**
 * CardPile
 *
 * @param {Object} props -
 * @param {Boolean} props.hasMore - flag to fetch more data if true
 * @param {Number} props.length - data length
 * @param {Function} [props.onNext] - callback to load more
 * @param {React.ReactNode} props.children
 * @param {{totalDocs: number,}} props.data
 * @param {string} [props.scrollableTarget]
 * @param {string | number} [props.height]
 */
export default function CardPile(props) {
  const { data = {}, onNext, length, hasMore, height, scrollableTarget } = props;

  return (
    <>
      {data && data.totalDocs ? (
        <InfiniteScroll
          style={{ overflow: 'visible' }}
          height={height}
          dataLength={length}
          next={onNext}
          hasMore={hasMore}
          scrollableTarget={scrollableTarget}
          loader={
            <Loading>
              <Loader />
              Loading...
            </Loading>
          }
          // endMessage={
          //   <p style={{ textAlign: "center" }}>
          //     <b>You've loaded all data</b>
          //   </p>
          // }
        >
          {props.children}
        </InfiniteScroll>
      ) : (
        <Root {...props} />
      )}
    </>
  );
}

CardPile.Card = Card;
CardPile.Label = CardLabel;
CardPile.Value = CardValue;
CardPile.Item = CardItem;
