exports.trackLogin = (firstName, lastName, email) => {
  if (typeof bento$ !== 'undefined') {
    window.bento$(() => {
      window.bento.track('$login', {});
      window.bento.identify(email);

      const name = window.bento.getName();
      if (!name && name !== `${firstName} ${lastName}`) {
        // you can add custom fields to your visitors which you can leverage for personalization.
        window.bento.updateFields({
          first_name: firstName,
          last_name: lastName,
        });
      }

      window.bento.view();
    });
  }
};
exports.trackJobRequested = (budget, currency, jobId) => {
  if (typeof bento$ !== 'undefined') {
    window.bento$(() => {
      // tag when a visitor requested a job.
      window.bento.track('$request', {
        value: {
          amount: budget * 100,
          currency,
        },
        unique: jobId,
      });
    });
  }
};

exports.hideChat = () => {
  if (typeof bento$ !== 'undefined') {
    window.bento$(() => {
      // window.bento.hideChat();
    });
  }
};

exports.showChat = () => {
  if (typeof bento$ !== 'undefined') {
    window.bento$(() => {
      window.bento.showChat();
    });
  }
};
