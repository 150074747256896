import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Normalize } from 'styled-normalize';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Inspect from 'inspx';
import { URLPattern } from 'helper/urlpattern-polyfill/url-pattern';

import FirebaseWrapper from 'FirebaseWrapper';
import GlobalStyles from 'ui/GlobalStyles';
import { setLogin } from 'store/reducer/global';
import Layout from 'ui/templates/Layout/Layout';
import { coachmarkUtil } from 'helper/utils';
import store from './store/Store';
import { getCookie, deleteAllCookies } from './helper/utils';
import Routes from './routes/Routes';
import './App.css';

if (!globalThis.URLPattern) {
  globalThis.URLPattern = URLPattern;
}

const domain = getCookie('DOMAIN');
const hasAccessToken = getCookie('ACCESS_TOKEN');
const hasRefreshToken = getCookie('REFRESH_TOKEN');
const hasUser = getCookie('USER');

if (hasAccessToken || hasRefreshToken || hasUser) {
  const userId = getCookie('ID');
  const domain = getCookie('DOMAIN');

  let userData = {};

  try {
    userData = JSON.parse(hasUser);
  } catch (err) {
    deleteAllCookies();
  }

  const hasName = (userData.firstName && userData.lastName) || userData.fullName;

  let isProfileComplete = false;
  if (userData.role === 'freelancer') {
    isProfileComplete = !!(
      hasName &&
      userData.title &&
      userData.address?.country &&
      userData.address?.region &&
      userData.rate &&
      userData.unit
    );
  }

  if (userData.role === 'client') {
    isProfileComplete = !!(
      hasName &&
      userData.title &&
      userData.address?.country &&
      userData.address?.region
    );
  }

  store.dispatch(
    setLogin({
      _id: userId,
      fullName: `${userData.firstName} ${userData.lastName}`,
      userName: `${userData.firstName} ${userData.lastName}`,
      domain,
      pseudonym: userData.pseudonym,
      email: userData.email,
      avatar: userData.avatar,
      status: userData.status,
      firstName: userData.firstName,
      lastName: userData.lastName,
      isProfileComplete,
    }),
  );
} else {
  if (
    !window.location.pathname.includes('auth') &&
    !window.location.pathname.includes('login') &&
    !window.location.pathname.includes('signup') &&
    !window.location.pathname.includes('reset-password') &&
    !window.location.pathname.includes('forgot-password') &&
    !window.location.pathname.includes('password-updated') &&
    // public profile
    !window.location.pathname.startsWith('/freelancers')
  ) {
    deleteAllCookies();
    setTimeout(() => (window.location = 'auth'), 1000);
  }
}

try {
  const coachmarkSettings = coachmarkUtil.getCoachmarkSettings();

  if (coachmarkSettings.lastClosedTime) {
    const currentTime = new Date().getTime();

    const leftDuration = currentTime - coachmarkSettings.lastClosedTime;

    if (leftDuration > 10_000) {
      coachmarkUtil.removeCoachmarkSettings();
    }

    if (coachmarkSettings.lastStep && typeof coachmarkSettings.lastStep !== 'number') {
      coachmarkUtil.removeCoachmarkSettings();
    }
  }
} catch (err) {
  coachmarkUtil.removeCoachmarkSettings();
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  useEffect(() => {
    // prev url
    window.localStorage.setItem('prevUrl', window.location.pathname);

    function handler() {
      coachmarkUtil.updateCoachmarkSettings({
        lastClosedTime: new Date().getTime(),
      });
    }

    window.addEventListener('beforeunload', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, []);

  return (
    <Provider store={store}>
      <FirebaseWrapper>
        <Elements stripe={stripePromise}>
          <Normalize />
          <GlobalStyles />
          <Router>
            <Inspect disabled={process.env.NODE_ENV === 'production'}>
              <Layout domain={domain}>
                <Routes />
              </Layout>
            </Inspect>
          </Router>
        </Elements>
      </FirebaseWrapper>
    </Provider>
  );
}

export default App;
