import React from 'react';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

import image from 'ui/assets/popup_notify.svg';

interface RestoreJobModalProps {
  open: boolean;
  onRestore: () => void;
  onCancel: () => void;
  insideFolder?: boolean;
}

export default function RestoreJobModal(props: RestoreJobModalProps) {
  const { open, onRestore, onCancel, insideFolder = false } = props;

  return (
    <ConfirmationModal
      open={open}
      title="Restore job"
      titleOptions={{ color: 'blue' }}
      subtitle={
        insideFolder
          ? [
              'Are you sure you want to restore this job?',
              'The restored job will be removed from folder.',
            ]
          : 'Are you sure you want to restore this job?'
      }
      subtitleOptions={{ color: 'black' }}
      img={{ src: image, alt: 'restore job' }}
      primary={{ text: 'Restore', color: 'blue', onClick: onRestore }}
      ghost={{ text: 'Cancel', color: 'blue', onClick: onCancel }}
    ></ConfirmationModal>
  );
}
