import React from 'react';

import popup_cancel from 'ui/assets/popup_cancel.svg';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

interface ForfeitJobModalProps {
  open: boolean;
  onCancel: () => void;
  onForfeit: () => void;
}

export default function ForfeitJobModal(props: ForfeitJobModalProps) {
  const { open, onCancel, onForfeit } = props;

  return (
    <ConfirmationModal
      open={open}
      img={{ src: popup_cancel, alt: 'forfeit job' }}
      title="Forfeit"
      titleOptions={{ color: 'red' }}
      subtitle={[
        'Are you sure?',
        'Once you forfeited,',
        'you will not be able to continue with the job.',
      ]}
      subtitleOptions={{ color: 'black' }}
      primary={{
        text: 'No, let me stay',
        color: 'red',
        onClick: onCancel,
      }}
      ghost={{
        text: "Yes, I'm sure",
        color: 'red',
        onClick: onForfeit,
      }}
    />
  );
}
