import React from 'react';

import { Button, Text, Row, Spacer } from 'ravenry-ui';

/**
 * Actions
 *
 * @param {Object} props
 * @param {Boolean} [props.withStepNumber] - display the step if true, default to false
 * @param {Number} [props.currentStep] -
 * @param {Number} [props.totalStep] -
 * @param {Boolean} [props.withBack] - display back button, default to false
 * @param {Function} [props.onBack] - callback when back button clicked
 * @param {Function} props.onNext - callback when next button clicked
 * @param {String} props.nextText - custom text of next button
 */
export default function Actions(props) {
  const {
    withStepNumber,
    currentStep,
    totalStep,
    withBack,
    onBack,
    onNext,
    nextText = 'Okay',
    backText = 'Back',
  } = props;

  return (
    <>
      <Spacer size="16" display="block" />
      <Row justifyContent="space-between" alignItems="center">
        <Button
          color="black"
          variant="text"
          onClick={onBack}
          width="110px"
          style={!withBack ? { visibility: 'hidden' } : undefined}
        >
          {backText}
        </Button>
        <Text
          _as="s6"
          color="black60"
          style={!withStepNumber ? { visibility: 'hidden' } : undefined}
        >
          {currentStep} of
          {totalStep}
        </Text>
        <Button color="blue" onClick={onNext}>
          {nextText}
        </Button>
      </Row>
    </>
  );
}
