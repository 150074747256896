import React from 'react';

import popup_success from 'ui/assets/popup_success.svg';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

interface InterestedInJobModalProps {
  open: boolean;
  onCancel: () => void;
  onInterest: () => void;
}

export default function InterestedInJobModal(props: InterestedInJobModalProps) {
  const { open, onCancel, onInterest } = props;

  return (
    <ConfirmationModal
      open={open}
      img={{ src: popup_success, alt: 'interested in job' }}
      title="Interested in the job"
      titleOptions={{ color: 'blue' }}
      subtitle={[
        'By agreeing with this, you will get the',
        'opportunity to be matched with our client',
      ]}
      subtitleOptions={{ color: 'black' }}
      primary={{
        text: 'I am interested',
        color: 'blue',
        onClick: onInterest,
      }}
      ghost={{
        text: 'Cancel',
        color: 'blue',
        onClick: onCancel,
      }}
    />
  );
}
