import { createSlice } from '@reduxjs/toolkit';
import { permissions } from '../constants/Constants';
import { deleteAllCookies, logoutUser } from '../../helper/utils';

// import { post } from "helper/apiHandler";
// import Api from "helper/api";

// const api = new Api();

// // First, create the thunk
// export const fetchNotification = createAsyncThunk("notifications", async () => {
//   const response = await api.getNotification();
//   return response.data;
// });

// export const sidebarCount = createAsyncThunk("sidebarCount", async () => {
//   const response = await api.sidebarCount();
//   return response.data;
// });

const globalState = createSlice({
  name: 'globalState',
  initialState: {
    chatLoggedIn: false,
    isLoggedIn: false,
    user: {
      userId: '',
      userName: '',
      fullName: '',
      firstName: '',
      lastName: '',
      pseudonym: '',
      email: '',
      domain: '',
      avatar: '',
      isProfileComplete: false,
    },
    loading: false,
    dim: false,
    error: false,
    error_msg: '',
    alert: {
      open: false,
      message: '',
      type: '',
    },
    alertMessage: '',
    header: null,
    brief: [],
    permission_list: permissions,
    notif: [],
    totalUnreadNotifications: 0,
    sticky: null,
    globalNotif: {},
  },
  reducers: {
    setLoading: (state, action) => ({ ...state, loading: action.payload }),
    setSticky: (state, action) => ({ ...state, sticky: action.payload }),
    setNotif: (state, action) => ({ ...state, notif: action.payload }),
    setTotalUnreadNotifications: (state, action) => ({
      ...state,
      totalUnreadNotifications: action.payload,
    }),
    setGlobalNotif: (state, action) => ({ ...state, globalNotif: action.payload }),

    setGlobalError: (state, action) => ({ ...state, error: action.payload }),

    setBrief: (state, action) => ({ ...state, brief: action.payload }),
    setNotLoading: (state) => ({ ...state, loading: false }),

    setQuery: (state, action) => ({ ...state, query: action.payload }),

    setLogin: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        user: {
          userId: action.payload._id,
          userName: action.payload.userName,
          fullName: action.payload.fullName,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          domain: action.payload.domain,
          pseudonym: action.payload.pseudonym,
          email: action.payload.email,
          avatar: action.payload.avatar ? action.payload.avatar : null,
          status: action.payload.status,
          isProfileComplete: action.payload.isProfileComplete,
        },
      };
    },

    updateUserData: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        userName: action.payload.userName,
        fullName: action.payload.fullName,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        avatar: action.payload.avatar ? action.payload.avatar : null,
        isProfileComplete: action.payload.isProfileComplete,
      },
    }),

    setChatLoggedIn: (state) => ({ ...state, chatLoggedIn: true }),

    setChatLoggedOut: (state) => ({ ...state, chatLoggedIn: false }),

    setHeader: (state, action) => ({ ...state, header: action.payload }),

    setLogout: (state) => {
      logoutUser();
      deleteAllCookies();
      localStorage.clear();

      return {
        ...state,
        chatLoggedIn: false,
        isLoggedIn: false,
        user: {
          userId: '',
          userName: '',
          domain: '',
        },
        loading: false,
        notif: [],
        globalNotif: {},
      };
    },

    handleApiError: (state, action) => {
      if (action && action.payload && action.payload.status === 401) {
        deleteAllCookies();
        setTimeout(() => (window.location = 'auth/login'), 1000);
      } else {
        return {
          ...state,
          error: true,
          error_msg:
            action.payload.data && action.payload.data.message
              ? action.payload.data.message
              : 'Connection problems',
          color_msg: 'red',
        };
      }
    },

    clearMessage: (state) => ({
      ...state,
      error: false,
      error_msg: '',
      color_msg: '',
    }),

    // setMessage: (state, action) => {},

    setAlert: (state, action) => ({
      ...state,
      alert: {
        open: action.payload.open,
        message: action.payload.message,
        type: action.payload.type,
      },
    }),

    clearAlert: (state) => ({
      ...state,
      alert: {
        open: false,
        message: '',
        type: '',
      },
    }),
  },
});

export const {
  setLoading,
  setGlobalError,
  setNotLoading,
  setLogin,
  setQuery,
  setHeader,
  setLogout,
  setBrief,
  handleApiError,
  clearMessage,
  setAlert,
  clearAlert,
  setChatLoggedIn,
  setChatLoggedOut,
  updateUserData,
  setNotif,
  setTotalUnreadNotifications,
  setSticky,
  setGlobalNotif,
  setOnlineUsers,
} = globalState.actions;
export default globalState.reducer;
