import moment from 'moment';
import amplitude from 'amplitude-js';
import { getUserRole } from './utils';

export const initAmplitude = (user) => {
  const amplitudeKey = process.env.REACT_APP_AMPLITUDE_KEY;
  amplitude.getInstance().init(amplitudeKey);
  amplitude.getInstance().setUserId(user.email);
  amplitude.getInstance().setGroup('role', user.domain);
  const userProperties = {
    'ongoing job': user.ongoingJob,
    'completed job': user.completedJob,
    reviews: user.reviews,
    rating: user.rating,
    created: `quarter ${moment(user.createdAt).quarter()} - ${moment().year()}`,
  };
  amplitude.getInstance().setUserProperties(userProperties);
};

export const amplitudeEvent = (event, eventProperties) => {
  const newEventProperties = {
    ...eventProperties,
    role: getUserRole(),
  };
  amplitude.getInstance().logEvent(event, newEventProperties);
};

export const removeAmplitude = () => {
  amplitude.getInstance().clearUserProperties();
  amplitude.getInstance().setUserId(null);
  amplitude.getInstance().regenerateDeviceId();
};
