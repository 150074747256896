import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Button, Container, Icon, Segment, Spacer, Tag, Text } from 'ravenry-ui';
import { CheckboxList } from '..';
import getElevation from 'ui/elevations';
import { Item } from '../CheckboxList/CheckboxList';
import colors from 'ui/colors';
import loading_gif from 'ui/assets/loading.gif';
import Responsive from 'helper/responsive';
import { MAIN_JOB_TYPE_ID } from 'store/constants/Constants';

const Panel = styled(Segment)`
  ${getElevation(2)}
`;

const CustomizedTag = styled(Tag)`
  &:hover {
    border: 1px solid ${colors.blue90};
  }

  &:hover > div > p {
    color: ${colors.black100};
  }

  &:hover > div > svg > path {
    fill: ${colors.blue90};
  }
`;

interface JobFilterPanelProps {
  draft?: boolean;
  open?: boolean;
  onClose?: () => void;
  loading?: boolean;
  onUpdateQuery: (updatedQuery: any) => void;
  initialQuery: {
    'type[in]'?: string[];
    'status[in]'?: number[];
  };
  jobTypes: any[];
  userRole?: 'client' | 'freelancer' | 'admin';
}

export default function JobFilterPanel(props: JobFilterPanelProps) {
  const {
    draft = false,
    open = false,
    onClose,
    loading = false,
    initialQuery,
    jobTypes: retrievedJobTypes,
    onUpdateQuery,
    userRole = 'client',
  } = props;

  const { isMobile } = Responsive();

  let rawJobStatuses: string[] = [];

  if (userRole === 'client') {
    rawJobStatuses = [
      'Look for freelancers',
      'Choose your freelancer',
      'Make payment',
      'Work in progress',
      'Review work',
      'Completed',
    ];
  }

  if (userRole === 'freelancer') {
    rawJobStatuses = [
      'Invitation',
      'Pending match',
      'Matched',
      'Offer submitted',
      'Work in progress',
      'Work submitted',
      'Job completed',
    ];
  }

  if (userRole === 'admin') {
    rawJobStatuses = [
      'Freelancer needed',
      'Awaiting freelancers',
      'Ready to recommend',
      'Recommendation sent',
      'Awaiting payment',
      'Work in progress',
      'Job submitted',
      'Job completed',
    ];
  }

  const [jobStatuses, setJobStatuses] = useState<Item[]>([
    {
      text: 'Any job status',
      checked:
        (initialQuery['status[in]']?.length || 0) === 0 ||
        rawJobStatuses.every((s, i) => initialQuery['status[in]']?.includes(i)),
      type: 'any',
    },
    ...rawJobStatuses.map((stat: string, index: number) => ({
      text: stat,
      checked: (initialQuery['status[in]'] || []).includes(index) || false,
    })),
  ]);

  const [jobTypes, setJobTypes] = useState<Item[]>([
    {
      text: 'Any job type',
      checked: (initialQuery['type[in]'] || []).length === 0,
      type: 'any',
    },
    ...retrievedJobTypes
      .filter(
        (jobType) =>
          jobType._id !== MAIN_JOB_TYPE_ID && !['client', 'freelancer'].includes(jobType.key),
      )
      .map((jobType: any) => ({
        text: jobType.label,
        checked: initialQuery['type[in]']?.includes(jobType.key),
      })),
  ]);

  useEffect(() => {
    setJobTypes([
      {
        text: 'Any job type',
        checked: (initialQuery['type[in]'] || []).length === 0,
        type: 'any',
      },
      ...retrievedJobTypes
        .filter(
          (jobType) =>
            jobType._id !== MAIN_JOB_TYPE_ID && !['client', 'freelancer'].includes(jobType.key),
        )
        .map((jobType: any) => ({
          text: jobType.label,
          checked: initialQuery['type[in]']?.includes(jobType.key),
        })),
    ]);
  }, [retrievedJobTypes]);

  useEffect(() => {
    const updatedTypes = {
      'type[in]': jobTypes
        .filter((jobType) => jobType.checked && jobType.type !== 'any')
        .map((jobType) => retrievedJobTypes.find((rjt) => rjt.label === jobType.text).key),
    };

    const updatedStatuses = !draft
      ? {
          'status[in]': jobStatuses
            .filter((stat) => stat.checked && stat.type !== 'any')
            .map((stat) => rawJobStatuses.findIndex((rjs) => rjs === stat.text)),
        }
      : {};

    onUpdateQuery({ ...updatedTypes, ...updatedStatuses });
  }, [...jobTypes, ...jobStatuses]);

  const tags: any[] = [
    ...jobTypes
      .filter((jobType) => jobType.checked && jobType.type !== 'any')
      .map((type) => ({
        value: type.text,
        displayedText: type.text,
        category: 'type[in]',
      })),
    ...jobStatuses
      .filter((status) => status.checked && status.type !== 'any')
      .map((status) => ({
        value: status.text,
        displayedText: `Status: ${status.text}`,
        category: 'status[in]',
      })),
  ];

  const jobStatusColumn = userRole === 'freelancer' ? 3 : 2;

  return (
    <div style={{ width: isMobile ? '100%' : '972px' }}>
      {tags.length > 0 && (
        <>
          <Spacer size="16" display="block" />
          <Container flex row wrap gap="16px">
            {tags.map((tag) => (
              <>
                <CustomizedTag
                  backgroundColor="backgroundWhite"
                  clickable
                  padding="6px 16px 6px 24px"
                >
                  <Container flex row>
                    <Text _as="btn1" color="black80" bold>
                      {tag.displayedText}
                    </Text>
                    <Spacer size="4" horizontal />
                    <Icon
                      name="x"
                      fill="black50"
                      onClick={() => {
                        if (tag.category === 'status[in]') {
                          const isOnlyOneChecked =
                            tags.filter((tag) => tag.category === 'status[in]').length === 1;

                          setJobStatuses((latestJobStatuses) =>
                            latestJobStatuses.map((ljs) => {
                              let checked = ljs.checked;

                              if (isOnlyOneChecked && ljs.type === 'any') {
                                checked = true;
                              }

                              if (ljs.text === tag.value) {
                                checked = false;
                              }

                              return {
                                ...ljs,
                                checked,
                              };
                            }),
                          );
                        }

                        if (tag.category === 'type[in]') {
                          const isOnlyOneChecked =
                            tags.filter((tag) => tag.category === 'type[in]').length === 1;

                          setJobTypes((latestJobTypes) =>
                            latestJobTypes.map((ljt) => {
                              let checked = ljt.checked;

                              if (isOnlyOneChecked && ljt.type === 'any') {
                                checked = true;
                              }

                              if (ljt.text === tag.value) {
                                checked = false;
                              }

                              return {
                                ...ljt,
                                checked,
                              };
                            }),
                          );
                        }
                      }}
                    />
                  </Container>
                </CustomizedTag>
              </>
            ))}
            <Button
              variant="text"
              color="blue"
              onClick={() => {
                setJobTypes((latestJobTypes) =>
                  latestJobTypes.map((ljt) => ({ ...ljt, checked: ljt.type === 'any' })),
                );
                setJobStatuses((latestJobStatuses) =>
                  latestJobStatuses.map((ljs) => ({ ...ljs, checked: ljs.type === 'any' })),
                );
              }}
            >
              Clear all filter
            </Button>
          </Container>
        </>
      )}

      {open && (
        <>
          <Spacer size="16" display="block" />
          <Panel padding="24px" width={isMobile ? '100%' : '972px'}>
            <Container flex row={!isMobile} column={isMobile} fluid>
              <CheckboxList
                title="JOB TYPE"
                items={jobTypes}
                onChange={({ updatedItems }) => setJobTypes(updatedItems || [])}
                itemsStyle={{
                  col: 3,
                  width: isMobile ? '100%' : '280px',
                  justifyContent: 'flex-start',
                }}
                width="100%"
              />
            </Container>

            {!draft && (
              <>
                <Spacer size="40" display="block" />
                <Container flex row={!isMobile} column={isMobile} fluid>
                  <CheckboxList
                    title="JOB STATUS"
                    items={jobStatuses}
                    onChange={({ updatedItems }) => setJobStatuses(updatedItems || [])}
                    itemsStyle={{
                      col: jobStatusColumn,
                      width: isMobile ? '100%' : '280px',
                      justifyContent: 'flex-start',
                    }}
                    width="100%"
                  />
                </Container>
              </>
            )}

            <Spacer size="40" display="block" />

            <Container flex row alignItems="center">
              <Button
                variant="outlined"
                color="blue"
                width="150px"
                onClick={onClose}
                data-cy="cta-close-filter"
              >
                Close filter
              </Button>

              <Spacer size="32" />

              {loading && (
                <>
                  <img style={{ width: '32px', height: '32px' }} src={loading_gif} alt="" />
                  <Text _as="s5" color="black60">
                    Applying filter
                  </Text>
                </>
              )}

              {tags.length > 0 && !loading && (
                <Text _as="s5" color="black60">
                  Filter applied
                </Text>
              )}
            </Container>
          </Panel>
        </>
      )}
    </div>
  );
}
