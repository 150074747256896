import React from 'react';

import popup_cancel from 'ui/assets/popup_cancel.svg';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

interface RemoveFromFolderModalProps {
  open: boolean;
  onCancel: () => void;
  onRemove: () => void;
}

export default function RemoveFromFolderModal(props: RemoveFromFolderModalProps) {
  const { open, onCancel, onRemove } = props;

  return (
    <ConfirmationModal
      open={open}
      img={{ src: popup_cancel, alt: 'remove job from folder' }}
      title="Remove from folder"
      titleOptions={{ color: 'red' }}
      subtitle={[
        'This action will move the job outside the folder.',
        'If the folder is empty, the folder will disappear.',
      ]}
      subtitleOptions={{ color: 'black' }}
      primary={{
        text: 'Cancel',
        color: 'red',
        onClick: onCancel,
      }}
      ghost={{
        text: 'Yes, remove',
        color: 'red',
        onClick: onRemove,
      }}
    />
  );
}
