import React, { createRef, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import CKEditor from 'ckeditor4-react';

import { Col, Icon, Text, Row, Spacer } from 'ravenry-ui';
import Responsive from 'helper/responsive';
import colors from 'ui/colors';
import { formatFileSize, getIconByFileType } from 'helper/utils';
import { usePrevState } from 'hooks/usePrevState';
import { amplitudeEvent } from 'helper/amplitude';

/**
 * ChatInput
 *
 * @param {Object} props
 * @param {Boolean} [props.disabled]
 * @param {Function} props.onSend
 * @param {Function} props.onUpload
 * @param {Function} props.onDeleteFile
 * @param {{size: Number, name: String, type: String, uploading: Boolean}[]} props.files - default to empty array
 */

const InputWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  background: #ffffff;
  border: 1px solid #d6d6d7;
  box-sizing: border-box;
  border-radius: 5px;
  width: ${({ width }) => width || '668px'};
  position: absolute;
  bottom: ${({ bottom }) => bottom || '0'};
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ isInputActive }) =>
    isInputActive
      ? `
       border: 1px solid ${colors.black60};`
      : ''}

  &:hover {
    border: 1px solid ${colors.black60};
  }
`;

const AttachmentPreview = styled(Row)`
  padding: 8px;
  border-radius: 5px;
  width: 200px;
  margin-bottom: 8px;
  background-color: ${colors.backgroundLight};
`;

const useHeightSize = (comRef) => {
  const [height, setHeight] = React.useState(0);

  useEffect(() => {
    const sizeObserver = new ResizeObserver((entries, observer) => {
      entries.forEach(({ target }) => {
        setHeight(target.clientHeight);
      });
    });
    sizeObserver.observe(comRef.current);
    return () => sizeObserver.disconnect();
  }, [comRef]);

  return height;
};

export default function ChatInput(props) {
  const {
    disabled,
    onSend,
    onUpload,
    onDeleteFile,
    files = [],
    draftMessage,
    chatRoomId,
    onSaveDraft,
    onInputHeightChange,
    width,
    bottom,
  } = props;

  const hiddenFileInput = useRef(null);
  const messageRef = useRef(null);
  const chatRoomRef = useRef(null);
  const inputContainerRef = createRef(null);
  const prevChatRoom = usePrevState(chatRoomId);
  const { isMobile } = Responsive();

  const [isInputActive, setIsInputActive] = useState(false);
  const [richText, setRichText] = useState(draftMessage || '');
  const [deleteIcon, setDeleteIcon] = useState({ index: null, shown: false });

  useEffect(() => {
    setRichText(draftMessage || '');
  }, [draftMessage]);

  useEffect(() => {
    messageRef.current = richText;
  }, [richText]);

  useEffect(() => {
    chatRoomRef.current = chatRoomId;
  }, [chatRoomId]);

  // save message as draft when changing chat room
  useEffect(() => {
    onSaveDraft(messageRef.current, prevChatRoom);
  }, [chatRoomId]);

  // save message as draft when going to another page
  useEffect(
    () => () => {
      onSaveDraft(messageRef.current, chatRoomRef.current);
    },
    [],
  );

  // save message as draft when reloaded
  useEffect(() => {
    async function handleBeforeUnload(e) {
      onSaveDraft(messageRef.current, chatRoomRef.current);

      e.preventDefault();
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const divRef = createRef();
  const height = useHeightSize(divRef);

  useEffect(() => {
    onInputHeightChange(height);
  }, [height]);

  return (
    <InputWrapper isInputActive={isInputActive} ref={divRef} width={width} bottom={bottom}>
      <div>
        {files.length ? (
          <Row style={{ marginLeft: '16px', marginTop: '8px' }}>
            {files.map((file, i) => (
              <React.Fragment key={`preview-${i}`}>
                <AttachmentPreview
                  alignItems="center"
                  noWrap
                  onMouseOver={() => setDeleteIcon({ index: i, shown: true })}
                  onMouseLeave={() => setDeleteIcon({ index: null, shown: false })}
                >
                  <img src={getIconByFileType(file.type)} alt={file.name} />
                  <Spacer size="8" horizontal display="block" />
                  <Col
                    style={{
                      minWidth: 0,
                      flex: '1 1 100%',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Text _as="b2" color="black60" ellipsis fluid>
                      {file.name}
                    </Text>
                    <Text size="10px" lineHeight="16px" color="black60" style={{ marginTop: 0 }}>
                      {file.uploading ? 'Uploading' : formatFileSize(file.size)}
                    </Text>
                  </Col>
                  <Spacer size="8" horizontal display="block" />
                  <Icon
                    name="delete"
                    clickable={deleteIcon.index === i && deleteIcon.shown && !file.uploading}
                    onClick={() => onDeleteFile(file)}
                    fill={
                      deleteIcon.index === i && deleteIcon.shown && !file.uploading
                        ? 'black60'
                        : 'none'
                    }
                  />
                </AttachmentPreview>
                <Spacer size="8" horizontal />
              </React.Fragment>
            ))}
          </Row>
        ) : null}

        <CKEditor
          data={richText}
          config={{
            toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'NumberedList', 'BulletedList']],
            removePlugins: 'elementspath',
            toolbarLocation: 'bottom',
            width: width ? width - 72 : '596px',
            height: 40,
            resize_enabled: false,
            shiftEnterMode: 1,
            extraPlugins: 'autogrow',
            autoGrow_onStartup: true,
            autoGrow_minHeight: 40,
            autoGrow_maxHeight: files.length ? 150 : 80,
            placeholder: 'Type your message...',
          }}
          onChange={(e) => {
            setRichText(e.editor.getData());
          }}
          onKey={(e) => {
            if (e.data.keyCode === 13) {
              e.cancel();
              if ((richText.length || files.length) && files.every((f) => !f.uploading)) {
                amplitudeEvent('sent a message to the client');
                onSend(richText, files);
                setRichText('');
              }
            }
          }}
          onFocus={() => setIsInputActive(true)}
          onBlur={() => setIsInputActive(false)}
          onBeforeLoad={(CKEDITOR) => {
            if (CKEDITOR) {
              CKEDITOR.addCss(
                '.cke_editable{cursor:text; font-size: 1rem; line-height: 1.25rem; letter-spacing: 0.5px; font-family: Lato, sans-serif; margin: 8px 16px;}.cke_editable::-webkit-scrollbar {width: 0;background: transparent;}',
              );
            }
          }}
        />
      </div>

      <Row
        justifyContent="flex-end"
        style={{ right: '16px', bottom: '12px', marginRight: '8px', position: 'absolute' }}
      >
        <>
          <Icon
            name="paperclip"
            clickable={!disabled}
            style={{ marginLeft: '16px' }}
            onClick={() => {
              hiddenFileInput.current.click();
            }}
            hover
            hoverFill="black"
          />
          <input
            type="file"
            multiple
            ref={hiddenFileInput}
            onChange={(e) => onUpload([...e.target.files])}
            style={{ display: 'none' }}
          />
        </>
        <Icon
          name="send"
          clickable={!disabled}
          style={{ marginLeft: '16px' }}
          onClick={() => {
            if ((richText.length || files.length) && files.every((f) => !f.uploading)) {
              amplitudeEvent('sent a message to the client');
              onSend(richText, files);
              setRichText('');
            }
          }}
          fill={
            richText || (files.length && files.every((f) => !f.uploading)) ? 'black' : undefined
          }
        />
      </Row>
    </InputWrapper>
  );
}
