import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, withRouter } from 'react-router-dom';

import { Container, MobileHeading, FullscreenLoading, Text } from 'ravenry-ui';

import { Navbar, Alert } from 'ui/molecules';
import ConfirmationModal from 'ui/molecules/Confirmation/Confirmation';
import colors from 'ui/colors';
import { clearAlert, setGlobalError } from 'store/reducer/global';
import { constructNotifURL } from 'helper/utils';
import { useNotif } from 'hooks/useNotif';
import responsive from 'helper/responsive';

const Root = styled.div`
  margin: 0;
  padding: 64px 0 0 0;
`;

const RootMobile = styled.div`
  margin: 0;
  padding: 0;
  padding-top: 56px;
  position: relative;
  /* height: 100vh; */
  overflow: auto;
`;

const Page = styled(Container)`
  /* height: calc(100vh - 80px); */
  padding: 0;
  width: 100%;
  background-color: ${colors.backgroundLight};
  overflow-y: auto;
`;

function Layout(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.global.alert);
  const error = useSelector((state) => state.global.error);
  const loading = useSelector((state) => state.global.loading);
  const global = useSelector((state) => state.global);
  const header = useSelector((state) => state.global.header);
  const totalUnreadNotifications = useSelector((state) => state.global.totalUnreadNotifications);
  const globalNotif = useSelector((state) => state.global.globalNotif);
  const { isMobile } = responsive();

  const [isLoggedIn, setIsLoggedIn] = useState(global.isLoggedIn);
  const [domain, setDomain] = useState(global.user.domain);
  const [params, setParams] = useState({
    _limit: '10',
    _page: 1,
  });

  const {
    notifications,
    fetchNotifications,
    listenToTotalUnread,
    resetNotif,
    readNotifications,
    readAllNotifications,
    fetchGlobalNotifications,
  } = useNotif();

  useEffect(() => {
    setIsLoggedIn(global.isLoggedIn);
    setDomain(global.user.domain || 'public');
  }, [global.isLoggedIn, global.user.domain]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchGlobalNotifications();
    } else {
      resetNotif();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchNotifications(params);
      listenToTotalUnread();
    }
  }, [isLoggedIn, params]);

  const jobNotifs =
    (globalNotif.jobs?.active?.length || 0) +
    (globalNotif.jobs?.completed?.length || 0) +
    (globalNotif.jobs?.archived?.length || 0);

  const menus = {
    freelancer: [
      {
        path: 'freelancer/dashboard',
        display: 'Dashboard',
        notif: (globalNotif.jobs?.active?.length || 0) + (globalNotif.jobs?.available?.length || 0),
        icon: 'home',
      },
      {
        path: 'freelancer/jobs',
        display: 'My Jobs',
        notif: jobNotifs,
        icon: 'home',
      },
      {
        path: 'freelancer/job-board',
        display: 'Job Board',
        notif: globalNotif.jobs?.available?.length || 0,
        icon: 'home',
      },
      {
        path: 'freelancer/earnings',
        display: 'My Earnings',
        icon: 'wallet',
      },
      isMobile && {
        path: 'freelancer/profile',
        display: 'My Profile',
        icon: 'profile',
      },
    ],
    client: [
      {
        path: 'client/dashboard',
        display: 'Dashboard',
        notif: jobNotifs,
        icon: 'home',
      },
      {
        path: 'client/jobs',
        display: isMobile ? 'My Jobs' : 'My Jobs',
        notif: jobNotifs,
        icon: 'home',
      },
      {
        path: 'client/freelancers',
        display: isMobile ? 'My Freelancers' : 'My Freelancers',
        icon: 'freelancers',
      },

      {
        path: 'client/payments',
        display: isMobile ? 'Payment ' : 'Payment History',
        icon: 'wallet',
      },
      isMobile && {
        path: 'client/profile',
        display: 'My Profile',
        icon: 'profile',
      },
    ],
    admin: [
      {
        path: 'god/jobs',
        display: 'Jobs',
        notif: jobNotifs,
        icon: 'home',
      },
      {
        path: 'god/projects',
        display: 'Projects',
        notif: jobNotifs,
        icon: 'home',
      },
      { path: 'god/clients', display: 'Clients', icon: 'user' },
      { path: 'god/freelancers', display: 'Freelancers', icon: 'freelancers' },
      { path: 'god/payments', display: 'Payments', icon: 'wallet' },
      isMobile && {
        path: 'god/settings',
        display: 'Setting',
        icon: 'cog',
      },
    ],
    public: [],
  };

  const pagesWithLogoOnlyNavbar = ['/freelancer/profile-wizard'];

  return location.pathname === '/auth' ? (
    <div>
      <FullscreenLoading open={loading} />
      <ConfirmationModal
        open={!!(error && error?.response?.status !== 401)}
        type="error"
        onPrimaryClick={() => {
          window.location.reload();
          setGlobalError(false);
        }}
      />
      {props.children}
    </div>
  ) : location.pathname === '/client/profile-wizard' ||
    location.pathname === '/client/signup' ||
    location.pathname === '/client/login' ||
    location.pathname === '/client/forgot-password' ||
    location.pathname.startsWith('/client/reset-password/') ||
    location.pathname === '/client/password-updated' ||
    location.pathname === '/freelancer/signup' ||
    location.pathname === '/freelancer/login' ||
    location.pathname === '/freelancer/forgot-password' ||
    location.pathname.startsWith('/freelancer/reset-password/') ||
    location.pathname.startsWith('/freelancers/') ||
    location.pathname === '/freelancer/password-updated' ? (
    <div>
      <FullscreenLoading open={loading} />
      <Alert
        open={alert.open}
        onFinish={() => dispatch(clearAlert())}
        success={alert.type === 'success'}
        danger={alert.type === 'danger'}
        icon={
          alert.type === 'success' ? 'complete check' : alert.type === 'danger' ? 'warning' : 'info'
        }
      >
        <Text _as="b2">{alert.message}</Text>
      </Alert>
      {props.children}
    </div>
  ) : (
    <>
      <FullscreenLoading open={loading} />
      <ConfirmationModal
        open={!!(error && error?.response?.status !== 401)}
        type="error"
        subtitle={error?.response?.data?.message}
        onPrimaryClick={() => {
          window.location.reload();
          setGlobalError(false);
        }}
      />
      {!isMobile ? (
        <>
          {location.pathname != '/freelancers/:userId' && (
            <Navbar
              domain={domain}
              logoOnly={pagesWithLogoOnlyNavbar.includes(location.pathname)}
              menus={menus[domain] || []}
              isLoggedIn={isLoggedIn}
              notification={{
                notifications: notifications?.docs,
                onReadAll: () => readAllNotifications(),
                onReadSelected: (notif) => {
                  const { url, hash, state, search } = constructNotifURL(notif, domain);
                  readNotifications([notif._id.split('-')[0]]);
                  history.push({ pathname: url, hash, state, search });
                },
                unreadNotifs: totalUnreadNotifications,
                onNext: () => setParams({ ...params, _page: params._page + 1 }),
                hasMoreNotif: true, // notifications?.hasNextPage,
              }}
            />
          )}
          <Root>
            <Page fluid>{props.children}</Page>
          </Root>

          <Alert
            open={alert.open}
            onFinish={() => dispatch(clearAlert())}
            success={alert.type === 'success'}
            danger={alert.type === 'danger'}
            icon={
              alert.type === 'success'
                ? 'complete check'
                : alert.type === 'danger'
                ? 'warning'
                : 'info'
            }
          >
            <Text _as="b2">{alert.message}</Text>
          </Alert>
        </>
      ) : (
        <>
          {location.pathname != '/freelancers/:userId' && (
            <Navbar
              menus={menus[domain] || []}
              isLoggedIn={isLoggedIn}
              domain={domain}
              notification={{
                notifications,
                onReadAll: () => readAllNotifications(),
                onReadSelected: (notif) => {
                  const { url, hash, state, search } = constructNotifURL(notif, domain);
                  readNotifications([notif._id.split('-')[0]]);
                  history.push({ pathname: url, hash, search, state });
                },
              }}
            />
          )}

          <RootMobile>
            <Container padding="0" color="backgroundLight" fluid>
              <MobileHeading
                domain={domain}
                menus={menus[domain] || []}
                isLoggedIn={isLoggedIn}
                notification={{
                  notifications: notifications?.docs,
                  onReadAll: () => readAllNotifications(),
                  onReadSelected: (notif) => {
                    const { url, hash, state, search } = constructNotifURL(notif, domain);
                    history.push({ pathname: url, hash, state, search });
                  },
                  unreadNotifs: totalUnreadNotifications,
                  onNext: () => setParams({ ...params, _page: params._page + 1 }),
                  hasMoreNotif: notifications?.hasNextPage,
                }}
              >
                {header}
              </MobileHeading>
              {props.children}
            </Container>
          </RootMobile>

          <Alert
            open={alert.open}
            onFinish={() => dispatch(clearAlert())}
            success={alert.type === 'success'}
            danger={alert.type === 'danger'}
            icon={
              alert.type === 'success'
                ? 'complete check'
                : alert.type === 'danger'
                ? 'warning'
                : 'info'
            }
          >
            <Text _as="b2">{alert.message}</Text>
          </Alert>
        </>
      )}
    </>
  );
}

export default withRouter(Layout);
