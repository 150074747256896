import axios from 'axios';
import * as Sentry from '@sentry/react';
import { refreshToken, getCookie } from './utils';
import { MAIN_JOB_TYPE_ID } from 'store/constants/Constants';

function NetworkError(message) {
  this.name = 'NetworkError';
  this.message = message;
  this.stack = new Error().stack;
}
NetworkError.prototype = Object.create(Error.prototype);
NetworkError.prototype.constructor = NetworkError;

export default class Api {
  constructor() {
    this.token = null;
    this.client = null;
    this.api_url = process.env.REACT_APP_API_URL;
  }

  init = () => {
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + getCookie('ACCESS_TOKEN'),
    };

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers,
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        const isTokenExpired =
          error?.response?.status === 401 &&
          (error?.response?.data?.name === 'TokenExpiredError' ||
            error?.response?.data?.name === 'TokenNotSuppliedError' ||
            error?.response?.data?.name === 'JsonWebTokenError');

        if (isTokenExpired) {
          return refreshToken().then(() => {
            return this.client.request({
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: 'Bearer ' + getCookie('ACCESS_TOKEN'),
              },
            });
          });
        }

        Sentry.setTag('code', error?.response?.status);
        Sentry.setTag('endpoint', error?.response?.config?.url);
        Sentry.setExtras({
          extra: { config: error?.response?.config, error: error?.response?.data },
        });
        Sentry.captureException(new NetworkError(error?.message));
        return Promise.reject(error);
      },
    );

    return this.client;
  };

  getProfile(id) {
    return this.init().get(`/users/${id}`);
  }

  updateProfile(payload, id) {
    return this.init().put(`/users/${id}`, payload);
  }

  updateAvatar = (id, data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    return this.init().put(`/users/${id}/avatar`, formData);
  };

  createPortfolio(userId, payload) {
    return this.init().post(`/users/${userId}/portfolios`, payload);
  }

  updatePortfolio(userId, payload, portfolioId) {
    return this.init().put(`/users/${userId}/portfolios/${portfolioId}`, payload);
  }

  deletePortfolio(userId, portfolioId) {
    return this.init().delete(`/users/${userId}/portfolios/${portfolioId}`);
  }

  updatePaymentDetail(userId, payload) {
    return this.init().put(`/users/${userId}/payment`, payload);
  }

  // job
  getAllJobs = (params) => this.init().get('/jobs', { params });

  getJobById = (id) => this.init().get(`/jobs/${id}`);

  addJobAttachment = (jobId, attachments) => {
    const formData = new FormData();
    attachments.forEach((asd) => {
      formData.append('attachments', asd);
    });

    return this.init().put(`/jobs/${jobId}/addAttachments`, formData);
  };

  publishJob = (id, data) => this.init().put(`/jobs/${id}/publish`, data);

  recommendJob = (id, data) => this.init().put(`/jobs/${id}/recommend`, data);

  interestJob = (id, data) => this.init().put(`/jobs/${id}/interest`, data);

  freelancerList = (id, params) =>
    this.init().get(`/jobs/${id}/freelancers`, {
      params,
    });

  recommendationList = (id, params) =>
    this.init().get(`/jobs/${id}/recommendation`, {
      params,
    });

  getJobOffer = (id) => this.init().get(`/jobs/${id}/offers`);

  getJobOfferByFreelancer = (jobId, freelancerId) =>
    this.init().get(`/jobs/${jobId}/offers/${freelancerId}`);

  sendOffer = (jobId, data) => this.init().put(`/jobs/${jobId}/send-offer`, data);

  updateOffer(jobId, payload) {
    return this.init().put(`/jobs/${jobId}/offers`, payload);
  }

  forfeitJob = (jobId) => this.init().put(`/jobs/${jobId}/forfeit`);

  acceptOffer = (jobId, data) => this.init().put(`/jobs/${jobId}/accept-offer`, data);

  getDocumentsByJobId(jobId, params) {
    return this.init().get(`/jobs/${jobId}/documents`, { params });
  }

  updateDocumentSeenLabel(jobId, documentId) {
    return this.init().put(`jobs/${jobId}/documents/${documentId}/seen-by`);
  }

  uploadJobDocument = (jobId, data, onUploadProgress, params) =>
    this.init().post(`/jobs/${jobId}/documents`, data, {
      onUploadProgress,
      timeout: 0,
      params,
    });

  updateJobDocument(jobId, documentId, payload) {
    return this.init().put(`/jobs/${jobId}/documents/${documentId}`, payload);
  }

  deleteJobDocument = (jobId, documentId) =>
    this.init().delete(`/jobs/${jobId}/documents/${documentId}`);

  startJob = (jobId) => this.init().put(`/jobs/${jobId}/start`);

  deleteJob = (jobId) => this.init().delete(`/jobs/${jobId}`);

  cancelJob = (jobId) => this.init().put(`/jobs/${jobId}/cancel`);

  submitJob = (jobId) => this.init().put(`/jobs/${jobId}/submit`);

  completeJob = (jobId) => this.init().put(`/jobs/${jobId}/complete`);

  reworkJob = (jobId, data) => this.init().put(`/jobs/${jobId}/rework`, data);

  resumeJob = (jobId) => this.init().put(`/jobs/${jobId}/resume`);

  archiveJob = (jobId, data) => this.init().put(`/jobs/${jobId}/archive`, data);

  postponeJob = (jobId) => this.init().put(`/jobs/${jobId}/postpone`);

  updateJobPublicStatus = (jobId, payload) => this.init().put(`/jobs/${jobId}/public`, payload);

  getPublishList = (id, params) => this.init().get(`/jobs/${id}/publish`, { params });

  moveJobToFolder = (jobId, payload) => this.init().put(`/jobs/${jobId}/folders`, payload);

  updateJobLastSeen = (jobId) => this.init().put(`/jobs/${jobId}/last-seens`);

  updateJobLastActive = (jobId) => this.init().put(`/jobs/${jobId}/last-active`);

  duplicateJob = (jobId) => this.init().post(`/jobs/${jobId}/duplicate`);

  // folder
  createFolder = (payload) => this.init().post('/folders', payload);

  updateFolder = (folderId, payload) => this.init().put(`/folders/${folderId}`, payload);

  getFolders = (params) => this.init().get('/folders', { params });

  deleteFolder = (folderId) => this.init().delete(`/folders/${folderId}`);

  archiveFolder = (folderId, payload) => this.init().put(`/folders/${folderId}/archive`, payload);

  // payments
  getAllPayment = (params) => this.init().get('/payments', { params });

  getAllInvoice = (params) => this.init().get('/invoices', { params });

  getInvoiceByInvoiceId = (invoiceId) => this.init().get(`/invoices/${invoiceId}`);

  createPaymentIntent(payload) {
    return this.init().post('/payments/intent', payload);
  }

  confirmPayment(payload) {
    return this.init().post('/payments/confirm', payload);
  }

  confirmPaymentInvoice(invoiceId) {
    return this.init().post(`/invoices/${invoiceId}/confirm-payment`);
  }

  downloadInvoice(invoiceId) {
    return this.init().get(`/invoices/${invoiceId}/download`, {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    });
  }

  // chats
  createChatRoom = (data) => this.init().post('/chat/room', data);

  getRoomsByJobId(jobId, params) {
    return this.init().get(`/chat/jobs/${jobId}`, { params });
  }

  // users
  getAllUser = (params) => this.init().get('/users', { params });

  getSingleUser = (id) => this.init().get(`/users/${id}`);

  getReviewByUser = (id, params) => this.init().get(`/users/${id}/reviews`, { params });

  getPublicFreelancer = (id) => this.init().get(`/freelancers/${id}`);

  updateLastOnline = (userId) => this.init().post(`/users/${userId}/online`);

  validateEmail = (data) => this.init().post('/users/validate-email', data);

  verifyUser = (id) => this.init().put(`/users/${id}/verify`);

  getQuestionMain = (params) => this.init().get('/questions/' + MAIN_JOB_TYPE_ID, { params });

  getQuestionSub = (params) => this.init().get(`/questions/${params}`);

  createJob = (data) => this.init().post('/jobs', data);

  updateNewJob = (id, data) => this.init().put(`/jobs/${id}`, data);

  assignJobToClient = (id, payload) => this.init().put(`/jobs/${id}/assign-client`, payload);

  requestRecommendation = (jobId) => this.init().put(`/jobs/${jobId}/request-recommendation`);

  getFreelancersByClient(clientId, params, paramsSerializer) {
    const config = {
      params,
      paramsSerializer: typeof paramsSerializer === 'function' ? paramsSerializer : undefined,
    };

    return this.init().get(`/clients/${clientId}/freelancers`, config);
  }

  getFreelancersByJob(jobId, clientId, params) {
    return this.init().get(`/clients/${clientId}/jobs/${jobId}/freelancers`, { params });
  }

  deleteFreelancerByClient(clientId, freelancerId) {
    return this.init().delete(`/clients/${clientId}/freelancers/${freelancerId}`);
  }

  // notif
  getNotifications = (params) => this.init().get('/notifications', { params, timeout: 0 });

  readNotifications = (notifications) => this.init().put('/notifications', { notifications });

  readAllNotifications() {
    return this.init().put('/notifications/all');
  }

  getGlobalNotifications() {
    return this.init().get('/notifications/dashboard');
  }

  getNotifJobById(jobId) {
    return this.init().get(`/notifications/jobs/${jobId}`);
  }

  // review
  reviewFreelancer = (payload) => this.init().post('/reviews', payload);

  // this is actually the same as reviewFreelancer function
  // the duplicate is to prevent confusion
  reviewClient = (payload) => this.init().post('/reviews', payload);

  // client
  addFreelancerToClient(clientId, freelancerId) {
    return this.init().put(`/clients/${clientId}/freelancers/${freelancerId}`);
  }

  addPersonalNote(clientId, freelancerId, payload) {
    return this.init().put(
      `/clients/${clientId}/freelancers/${freelancerId}/personal-note`,
      payload,
    );
  }

  // analytics
  getFreelancerAnalytics() {
    return this.init().get('/analytics/freelancers', { responseType: 'blob' });
  }

  getClientAnalytics() {
    return this.init().get('/analytics/clients', { responseType: 'blob' });
  }

  getJobAnalytics() {
    return this.init().get('/analytics/jobs', { responseType: 'blob' });
  }

  // job types
  getJobTypes(params) {
    return this.init().get('/job-types', { params });
  }

  getJobType(jobTypeId) {
    return this.init().get(`/job-types/${jobTypeId}`);
  }

  createJobType(payload) {
    return this.init().post('/job-types', payload);
  }

  updateJobType(id, payload) {
    return this.init().put(`/job-types/${id}`, payload);
  }

  deleteJobType(id) {
    return this.init().delete(`job-types/${id}`);
  }

  createJobTypePortfolio(jobTypeId, payload) {
    return this.init().post(`/job-types/${jobTypeId}/portfolios`, payload);
  }

  updateJobTypePortfolio(jobTypeId, portfolioId, payload) {
    return this.init().put(`/job-types/${jobTypeId}/portfolios/${portfolioId}`, payload);
  }

  deleteJobTypePortfolio(jobTypeId, portfolioId) {
    return this.init().delete(`job-types/${jobTypeId}/portfolios/${portfolioId}`);
  }

  // promotions
  verifyReferrals(code) {
    return this.init().get(`promotions/referrals/${code}/verify`);
  }

  getDiscount(code) {
    return this.init().get(`discounts/${encodeURIComponent(code)}/apply`);
  }
  // url previews
  getPreview = (url) => this.init().get('/previews', { params: { url }, timeout: 0 });

  verifyCaptcha = (data) => this.init().post('/captcha', data);

  createTestimonial(payload) {
    return this.init().post(`/testimonials`, payload);
  }

  updateTestimonial(testimonialId, payload) {
    return this.init().put(`/testimonials/${testimonialId}`, payload);
  }

  deleteTestimonial(testimonialId) {
    return this.init().delete(`/testimonials/${testimonialId}`);
  }

  uploadClientele = (userId, data, onUploadProgress) =>
    this.init().post(`/users/${userId}/storefront/clienteles`, data, {
      onUploadProgress,
      timeout: 0,
    });

  deleteClientele = (userId, clienteleId) =>
    this.init().delete(`/users/${userId}/storefront/clienteles/${clienteleId}`);

  // storefront
  getStorefront = (userId) => this.init().get(`/users/${userId}/storefront`);

  createStorefront = (payload, userId) => this.init().post(`/users/${userId}/storefront`, payload);

  updateStorefront = (payload, userId) => this.init().put(`/users/${userId}/storefront`, payload);

  trackQuotes = (payload) => this.init().post(`/jobs/track`, payload);

  updateAvatarStorefront = (userId, data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    return this.init().put(`/users/${userId}/storefront/avatar`, formData);
  };

  getRoomChat = (roomId, params) => this.init().get(`/rooms/${roomId}`, { params });
}
