import React from 'react';
import styled from 'styled-components';

import { Fields, Modal } from 'ui/molecules';
import { Button, Divider, Icon, Row, Spacer, Text } from 'ravenry-ui';

interface Props {
  open: boolean;
  onClose: () => void;
  link?: string;
}

const ShareButton = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: ${({ color }) => color || 'white'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function ShareStorefrontModal(props: Props) {
  const { open, onClose, link = '' } = props;

  const text = `Get a quote for my services now!%0A%0A`;

  return (
    <Modal open={open} padding="24px" outerPadding="0" width="744px">
      <Text _as="s3" bold>
        Share Storefront
      </Text>
      <Spacer size="24" display="block" />
      <Text _as="s5" bold>
        SHARE ON
      </Text>
      <Spacer size="8" display="block" />
      <Row>
        <ShareButton color="#25d366" href={`https://wa.me/?text=${text}${link}`} target="_blank">
          <Icon name="whatsapp" fill="white" />
        </ShareButton>
        <Spacer size="16" display="block" />
        <ShareButton
          color="#1977f3"
          href={`https://www.facebook.com/sharer/sharer.php?u=${link}&sdkpreparse&quote=${text}${link}`}
          target="_blank"
        >
          <Icon name="facebook round" fill="white" />
        </ShareButton>
        <Spacer size="16" display="block" />
        <ShareButton
          color="#2867b2"
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${link}&text=${text}`}
          target="_blank"
        >
          <Icon name="linkedin big" fill="white" />
        </ShareButton>
        <Spacer size="16" display="block" />
        <ShareButton
          color="#1da1f2"
          href={`https://twitter.com/share?ref_src=twsrc%5Etfw&text=${text}&url=${link}`}
          target="_blank"
        >
          <Icon name="twitter big" fill="white" />
        </ShareButton>
      </Row>
      <Spacer size="16" display="block" />
      <Divider
        color="black10"
        textOptions={{ fontSize: '12px', lineHeight: '14px', color: 'black60' }}
      >
        or
      </Divider>
      <Spacer size="16" display="block" />
      <Row>
        <Fields.Text
          label="SHARE YOUR STOREFRONT LINK TO A FRIEND"
          _labelAs="s5"
          value={link}
          width="530px"
        />
        <Spacer size="16" />
        <Button width="150px" onClick={() => navigator.clipboard.writeText(link)}>
          Copy link
        </Button>
      </Row>
      <Spacer size="24" display="block" />
      <Row justifyContent="flex-end">
        <Button variant="outlined" width="110px" onClick={onClose}>
          Back
        </Button>
      </Row>
    </Modal>
  );
}
