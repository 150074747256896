import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Text, Container, Dropdown, Segment } from 'ravenry-ui';
import { Accordion, IconButton } from 'ui/molecules';
import { JobCard } from 'ui/organisms';
import useOutsideClickMultiple from '../../../hooks/useOutsideClickMultiple';
import { Job } from 'store/constants/properties';
import getElevation from 'ui/elevations';
import colors from 'ui/colors';

const Root = styled.div``;

type EligibleRole = 'client' | 'freelancer';

interface Folder {
  name: string;
  _id: string;
  jobs: Job[];
}

interface Props {
  folders: Folder[];
  tab?: string;
  userRole?: EligibleRole;
  onClickRenameFolder?: (folderId: string, folderName: string) => void;
  onClickRemoveFolder?: (folderId: string) => void;
  onRemoveFromFolder?: (jobId: string, folderId: string | null) => void;
  onArchiveFolder?: (folderId: string) => void;
  onRestoreFolder?: (folderId: string) => void;
  onMoveToFolder?: (jobId: string, sourceFolderId: string) => void;
  onRestoreJob?: (jobId: string) => void;
  onDeleteJob?: (jobId: string) => void;
  onDuplicateJob?: (jobId: string) => void;
  onArchiveJob?: (jobId: string) => void;
  onCancelJob?: (jobId: string) => void;
}

const FolderSegment = styled(Segment)`
  margin-bottom: 24px;
  ${getElevation(2)}
  &:hover {
    ${getElevation(4)}
  }
`;

const EllipsisContainer = styled.div`
  margin-right: 8px;
`;

export default function FolderCard({
  folders = [],
  tab = 'active',
  userRole = 'client',
  onClickRenameFolder = () => null,
  onClickRemoveFolder = () => null,
  onRemoveFromFolder,
  onArchiveFolder,
  onRestoreFolder,
  onMoveToFolder,
  onDeleteJob,
  onRestoreJob,
  onDuplicateJob,
  onArchiveJob,
  onCancelJob,
}: Props) {
  const [dropdown, setDropdown] = useState<{
    open: boolean;
    id: string | null;
    position: { left: number; top: number };
  }>({
    open: false,
    id: null,
    position: {
      left: 0,
      top: 0,
    },
  });

  const ellipsisOptions = [
    {
      text: 'Rename folder',
      onClick: (folderId: string, folderName: string) => {
        if (typeof onClickRenameFolder === 'function') {
          onClickRenameFolder(folderId, folderName);
        }
      },
    },
    tab === 'active'
      ? {
          text: 'Archive folder',
          onClick: (folderId: string) => {
            if (typeof onArchiveFolder === 'function') {
              onArchiveFolder(folderId);
            }
          },
        }
      : {
          text: 'Restore folder',
          onClick: (folderId: string) => {
            if (typeof onRestoreFolder === 'function') {
              onRestoreFolder(folderId);
            }
          },
        },
    {
      text: 'Remove folder',
      onClick: (folderId: string) => {
        if (typeof onClickRemoveFolder === 'function') {
          onClickRemoveFolder(folderId);
        }
      },
    },
  ];

  const refs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    refs.current = refs.current.slice(0, folders?.length || 0);
  }, [folders]);

  useOutsideClickMultiple(refs, () => {
    setDropdown({
      open: false,
      id: null,
      position: {
        left: 0,
        top: 0,
      },
    });
  });

  const getEllipsisPosition = (index: number) => {
    const element = refs.current[index];
    return {
      left: element.offsetLeft,
      top: element.offsetTop,
    };
  };

  return (
    <Root>
      {folders.map((folder, index) => (
        <FolderSegment key={'folder-segment-' + index} padding="0">
          <Accordion
            title={
              <Container
                width="calc(100% - 36px)"
                flex
                justifyContent="space-between"
                alignItems="center"
              >
                <Text _as="s3" bold>
                  {folder.name}
                </Text>
                <EllipsisContainer ref={(el) => (refs.current[index] = el as HTMLDivElement)}>
                  <IconButton
                    name="ellipsis vertical"
                    tooltipOptions={{
                      content: 'Options',
                      position: { top: '-40px', right: '-22px' },
                      arrowPosition: 'bottom-center',
                    }}
                    onClick={() => {
                      setDropdown({
                        ...dropdown,
                        position: getEllipsisPosition(index),
                        open: !dropdown.open,
                        id: folder._id,
                      });
                    }}
                  />
                </EllipsisContainer>
              </Container>
            }
            content={
              <JobCard
                cardOptions={{
                  noMargin: true,
                  borderRadius: '0 0 10px 10px',
                  borderTop: `1px solid ${colors.black10}`,
                  shadow: 'none',
                  shadowOnHover: 'none',
                }}
                tab={tab}
                userRole={userRole}
                jobs={folder.jobs}
                hasMore={false}
                isDropdownAbsolute
                insideFolder
                onRemoveFromFolder={(jobId) => {
                  if (typeof onRemoveFromFolder === 'function') {
                    const folder = folders.find((f) => f.jobs.map((j) => j._id).includes(jobId));
                    const folderId = folder?.jobs.length === 1 ? folder._id : null;

                    onRemoveFromFolder(jobId, folderId);
                  }
                }}
                onMoveToFolder={(jobId) => {
                  if (typeof onMoveToFolder === 'function') {
                    onMoveToFolder(jobId, folder._id);
                  }
                }}
                onRestoreClick={(jobId) => {
                  if (typeof onRestoreJob === 'function') {
                    onRestoreJob(jobId);
                  }
                }}
                onDeleteArchivedClick={(jobId) => {
                  if (typeof onDeleteJob === 'function') {
                    onDeleteJob(jobId);
                  }
                }}
                onDuplicateClick={(jobId) => {
                  if (typeof onDuplicateJob === 'function') {
                    onDuplicateJob(jobId);
                  }
                }}
                onArchiveJobClick={(jobId) => {
                  if (typeof onArchiveJob === 'function') {
                    onArchiveJob(jobId);
                  }
                }}
                onCancelClick={(jobId) => {
                  if (typeof onCancelJob === 'function') {
                    onCancelJob(jobId);
                  }
                }}
              />
            }
            chevronOnRight={false}
            chevronContainerHover
            headerBackground="white"
            headerHeight="56px"
            headerBorderRadius="10px"
            headerPadding="0 8px"
            contentOverflow="visible"
            contentArrayLength={folder.jobs?.length || 0}
            disableClickTarget={refs.current[index]}
            disableContentChange
            defaultOpen
          />
          {dropdown.open && dropdown.id === folder._id && (
            <Dropdown
              position={{ left: dropdown.position?.left - 118, top: dropdown.position?.top + 40 }}
              style={{ zIndex: 2 }}
            >
              {ellipsisOptions?.length
                ? ellipsisOptions.map((opt: any) => (
                    <Dropdown.Item
                      key={`option-${opt.text}`}
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        opt.onClick(folder._id, folder.name);
                      }}
                    >
                      {opt.text}
                    </Dropdown.Item>
                  ))
                : null}
            </Dropdown>
          )}
        </FolderSegment>
      ))}
    </Root>
  );
}
