import Api from 'helper/api';
import IamApi from 'helper/iamApi';
import { getCookie, setCookie } from 'helper/utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setGlobalError, updateUserData } from 'store/reducer/global';
import { listenLastOnline as listenLastOnlineFirestore } from 'helper/firebase';

/**
 *
 * @param {*} init
 * @returns {{
 *   data: {
 *     hasNextPage: boolean,
 *     totalDocs: number,
 *     docs: object[],
 *     page: number,
 *     limit: number,
 *     totalPages, number,
 *     allIds?: string[],
 *   },
 *   fetchAllUsers: Function,
 *   fetchSingleUser: Function,
 *   fetchPublishList: Function,
 *   fetchFreelancersByClient: Function,
 *   updateUser: Function,
 *   updateAvatar: Function,
 *   addFreelancerToClient: Function,
 *   deleteFreelancerByClient: Function,
 *   hasSavedFreelancers: (clientId: string) => Promise,
 *   fetchFreelancersByJob: Function,
 *   userList: {
 *     _id: string,
 *     _freelancerId: string,
 *     firstName?: string,
 *     lastName?: string,
 *     fullName?: string,
 *     title: string,
 *     avatarLink?: string,
 *     location: string,
 *     address?: {
 *       country?: string,
 *       region?: string,
 *     },
 *     rate?: number,
 *     unit?: string,
 *     rating?: number,
 *     reviews?: number,
 *     services?: { offering: string }[],
 *     skills?: string[]
 *     status: number,
 *     saved?: boolean,
 *     recommendedBy?: {
 *       _id?: string,
 *       role: 'freelancer' | 'admin' | 'client'
 *     }
 *   }[],
 *   singleUser: {
 *     _id: string,
 *     firstName?: string,
 *     lastName?: string,
 *     fullName?: string,
 *     avatarLink?: string,
 *     address?: {
 *       country?: string,
 *       region?: string,
 *     },
 *     rate?: number,
 *     unit?: string,
 *     rating?: number,
 *     services?: { offering: string }[],
 *     status: number,
 *   }
 *   clearUserList: Function
 * }}
 */
export const useUser = () => {
  const api = new Api();
  const iamApi = new IamApi();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.global.user);
  const [data, setData] = useState(null);
  const [userList, setUserList] = useState([]);
  const [singleUser, setSingleUser] = useState(null);
  const [usersLastOnline, setUsersLastOnline] = useState({});
  const [reviews, setReviews] = useState({});

  const fetchAllUsers = (params, options = { infiniteScroll: true }) => {
    const { infiniteScroll } = options;

    api
      .getAllUser(params)
      .then((res) => {
        setData(res.data);

        if (infiniteScroll) {
          setUserList((prev) => prev.concat(res.data.docs));
        } else {
          setUserList(res.data.docs);
        }
      })
      .catch((err) => {
        dispatch(setGlobalError(err));
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const fetchSingleUser = (id, options = { globalLoading: true }) => {
    if (options.globalLoading) {
      dispatch(setLoading(true));
    }

    return api
      .getSingleUser(id)
      .then((res) => {
        setSingleUser(res.data);

        return { payload: res.data, error: null };
      })
      .catch((err) => {
        dispatch(setGlobalError(err));

        return { payload: null, error: err };
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const fetchPublicFreelancer = (id, options = { globalLoading: true }) => {
    if (options.globalLoading) {
      dispatch(setLoading(true));
    }

    return api
      .getPublicFreelancer(id)
      .then((res) => setSingleUser(res.data))
      .catch((err) => {
        dispatch(setGlobalError(err));
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const fetchReviewByUser = (id, params) => {
    dispatch(setLoading(true));
    api
      .getReviewByUser(id, params)
      .then((res) => setReviews(res.data))
      .catch((err) => {
        dispatch(setGlobalError(err));
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const updateUser = async (id, data, options = { globalLoading: true }) => {
    if (options.globalLoading) {
      dispatch(setLoading(true));
    }

    return api
      .updateProfile(data, id)
      .then((res) => {
        const userObj = JSON.parse(getCookie('USER'));
        setCookie(
          'USER',
          JSON.stringify({
            ...userObj,
            fullName: `${res.data.firstName} ${res.data.lastName}`,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
          }),
        );
        dispatch(
          updateUserData({
            fullName: `${res.data.firstName} ${res.data.lastName}`,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            avatar: user.avatar,
          }),
        );

        return true;
      })
      .catch((err) => {
        dispatch(setGlobalError(err));
        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const createPortfolio = async (userId, data, options = { globalLoading: true }) => {
    if (options.globalLoading) {
      dispatch(setLoading(true));
    }

    return api
      .createPortfolio(userId, data)
      .then((res) => res)
      .catch((err) => {
        dispatch(setGlobalError(err));
        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const updateAvatar = async (id, file) => {
    dispatch(setLoading(true));
    return api
      .updateAvatar(id, { fileName: file })
      .then((res) => {
        const userObj = JSON.parse(getCookie('USER'));
        setCookie('USER', JSON.stringify({ ...userObj, avatar: res.data.avatarLink }));
        setTimeout(
          () =>
            dispatch(
              updateUserData({
                ...userObj,
                avatar: res.data.avatarLink,
              }),
            ),
          500,
        );

        return { error: null, payload: res.data };
      })
      .catch((err) => {
        dispatch(setGlobalError(err));
        return { error: err, payload: null };
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const updatePortfolio = async (userId, data, portfolioId, options = { globalLoading: true }) => {
    if (options.globalLoading) {
      dispatch(setLoading(true));
    }

    return api
      .updatePortfolio(userId, data, portfolioId)
      .then(() => true)
      .catch((err) => {
        dispatch(setGlobalError(err));
        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const updatePaymentDetail = async (userId, payload) => {
    dispatch(setLoading(true));

    return api
      .updatePaymentDetail(userId, payload)
      .then(() => true)
      .catch((err) => {
        dispatch(setGlobalError(err));
        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const deletePortfolio = async (userId, portfolioId) => {
    dispatch(setLoading(true));
    return api
      .deletePortfolio(userId, portfolioId)
      .then(() => true)
      .catch((err) => {
        dispatch(setGlobalError(err));
        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const fetchPublishList = (id, params) => {
    // dispatch(setLoading(true));
    api
      .getPublishList(id, params)
      .then((res) => {
        setUserList(res.data);
      })
      .catch((err) => {
        dispatch(setGlobalError(err));
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const fetchFreelancersByClient = (clientId, params, options = { serializer: null }) => {
    const { serializer } = options;

    return api
      .getFreelancersByClient(clientId, params, serializer)
      .then((res) => {
        setData(res.data);
        setUserList((prev) => prev.concat(res.data.docs));
      })
      .catch((err) => {
        console.error(err);
        dispatch(setGlobalError(err));
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const fetchFreelancersByJob = (jobId, clientId, params) =>
    api
      .getFreelancersByJob(jobId, clientId, params)
      .then((res) => {
        setData(res.data);
        setUserList((prev) => prev.concat(res.data.docs));
      })
      .catch((err) => {
        console.error(err);
        dispatch(setGlobalError(err));
      })
      .finally(() => dispatch(setLoading(false)));
  const deleteFreelancerByClient = (clientId, freelancerId) => {
    dispatch(setLoading(true));

    return api
      .deleteFreelancerByClient(clientId, freelancerId)
      .then(() => true)
      .catch((err) => {
        console.error(err);
        dispatch(setGlobalError(err));

        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const addFreelancerToClient = (clientId, freelancerId) => {
    dispatch(setLoading(true));

    return api
      .addFreelancerToClient(clientId, freelancerId)
      .then(() => true)
      .catch((err) => {
        console.error('handleError =>', err);
        dispatch(setGlobalError(err));

        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const addPersonalNote = (clientId, freelancerId, payload) => {
    dispatch(setLoading(true));

    return api
      .addPersonalNote(clientId, freelancerId, payload)
      .then(() => true)
      .catch((err) => {
        console.error('handleError =>', err);
        dispatch(setGlobalError(err));

        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  async function hasSavedFreelancers(clientId) {
    try {
      const res = await api.getFreelancersByClient(clientId);

      if (res.data.docs.length > 0) {
        return true;
      }

      return false;
    } catch (err) {
      console.error(err);
    }
  }

  const verifyUser = (userId) => {
    dispatch(setLoading(true));

    return api
      .verifyUser(userId)
      .then(() => true)
      .catch((err) => {
        console.error('handleError =>', err);
        dispatch(setGlobalError(err));

        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const resendActivationEmail = (userId) => {
    dispatch(setLoading(true));

    return iamApi
      .resendActivationEmail(userId)
      .then(() => true)
      .catch((err) => {
        console.error('handleError =>', err);
        dispatch(setGlobalError(err));

        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const updateLastOnline = (userId) =>
    api.updateLastOnline(userId).catch((err) => console.error('Error update last login', err));

  const listenLastOnline = (userId) =>
    listenLastOnlineFirestore(userId, (doc) => {
      if (doc.exists) {
        setUsersLastOnline({
          ...usersLastOnline,
          [userId]: doc.data().lastOnline?.toDate(),
        });
      }
    });

  const clearUserList = () => setUserList([]);

  const createTestimonial = async (data, options = { globalLoading: true }) => {
    if (options.globalLoading) {
      dispatch(setLoading(true));
    }

    return api
      .createTestimonial(data)
      .then((res) => res)
      .catch((err) => {
        dispatch(setGlobalError(err));
        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const updateTestimonial = async (testimonialId, data, options = { globalLoading: true }) => {
    if (options.globalLoading) {
      dispatch(setLoading(true));
    }

    return api
      .updateTestimonial(testimonialId, data)
      .then(() => true)
      .catch((err) => {
        dispatch(setGlobalError(err));
        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const deleteTestmonial = async (testimonialId) => {
    dispatch(setLoading(true));
    return api
      .deleteTestimonial(testimonialId)
      .then(() => true)
      .catch((err) => {
        dispatch(setGlobalError(err));
        return false;
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return {
    data,
    userList,
    fetchAllUsers,
    fetchSingleUser,
    fetchPublicFreelancer,
    hasSavedFreelancers,
    singleUser,
    fetchReviewByUser,
    reviews,
    updateUser,
    updateAvatar,
    updatePaymentDetail,
    createPortfolio,
    updatePortfolio,
    deletePortfolio,
    fetchPublishList,
    fetchFreelancersByClient,
    fetchFreelancersByJob,
    deleteFreelancerByClient,
    addFreelancerToClient,
    addPersonalNote,
    verifyUser,
    resendActivationEmail,
    updateLastOnline,
    listenLastOnline,
    usersLastOnline,
    clearUserList,
    createTestimonial,
    updateTestimonial,
    deleteTestmonial,
  };
};
