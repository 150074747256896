import React from 'react';
import styled from 'styled-components';

import { Text, Row, RatingBar, Spacer } from 'ravenry-ui';

const Root = styled.div``;

interface RatingBarGroupProps {
  pairs: { star: number; count: number }[];
}

/**
 * RatingBarGroup
 *
 * @param {Object} props -
 * @param {Object[]} props.pairs -
 * @param {Number} props.pairs[].star -
 * @param {Number} props.pairs[].count -
 */
export default function RatingBarGroup(props: RatingBarGroupProps) {
  const { pairs } = props;

  const total = pairs.reduce((prev, curr) => prev + curr.count, 0);

  return (
    <Root>
      {pairs.map((pair, index, { length }) => (
        <React.Fragment key={`rating-bar-${index}`}>
          <Row alignItems="center">
            <Text _as="b3" color="black60" bold>
              {pair.star} star
            </Text>
            <Spacer size="8" horizontal />
            <RatingBar width="346px" value={pair.count} total={total} />
            <Spacer size="8" horizontal />
            <Text _as="b3" color="black60" bold>
              {(+pair.count).toLocaleString()}
            </Text>
          </Row>
          {index < length - 1 ? <Spacer size="8" display="block" /> : null}
        </React.Fragment>
      ))}
    </Root>
  );
}
