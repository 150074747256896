import React from 'react';
import styled from 'styled-components';

import { Text, Segment, Spacer } from 'ravenry-ui';
import { Progress } from 'ui/molecules';
import getElevation from 'ui/elevations';
import InputSelector from 'ui/molecules/QueryBuilder/InputSelector';
import InputBudget from 'ui/molecules/QueryBuilder/InputBudget';

const Root = styled.div`
  margin-bottom: 40px;
`;

const QuestionSegment = styled(Segment)`
  ${getElevation(2)}
`;

interface QBProps {
  main?: any[];
  sub?: any[];
  documents?: any[];
  mobile?: boolean;
}

export default function QueryBuilder({
  main,
  handleChangeMain,
  onFocus,
  onBlur,
  handleChangeBranch,
  documents,
  sub,
  handleChangeSub,
  clientCandidate,
  mobile,
}: QBProps) {
  let progressItems: { text: string; 'data-cy-item'?: string }[] = [
    {
      text: 'Create brief',
    },
    {
      text: 'Find freelancers',
    },
    {
      text: 'Done',
    },
  ];

  let progress = 1;

  if (clientCandidate) {
    progressItems = [
      {
        text: 'Choose client',
      },
      {
        text: 'Create brief',
        'data-cy-item': 'progress-create-brief',
      },
      {
        text: 'Done',
      },
    ];

    progress = 2;
  }

  return (
    <Root id="query-builder">
      {!mobile && (
        <>
          <Text _as="h5" bold>
            Create
          </Text>
          <Spacer size="8" display="block" />
          <Progress items={progressItems} progress={progress} />
          <Spacer size="24" display="block" />
        </>
      )}
      <QuestionSegment id="primary_question" padding="24px" elevation={0}>
        {main &&
          main.map(
            (m) =>
              m.required && (
                <>
                  <InputSelector
                    input={m}
                    onChange={handleChangeMain}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    handleChangeBranch={handleChangeBranch}
                    settings={{
                      multiple: {
                        maxLimit: Infinity,
                      },
                    }}
                    documents={documents}
                    typeQuestion="main-question"
                  />
                </>
              ),
          )}
      </QuestionSegment>

      {!mobile && (
        <>
          <Spacer size="24" display="block" />
          {sub?.length > 0 && (
            <QuestionSegment id="question" padding="24px">
              {sub.map(
                (s) =>
                  (s.key === 'budget' ||
                    s.key === 'service_fee' ||
                    s.key === 'budget_currency') && (
                    <InputSelector
                      input={s}
                      onChange={handleChangeSub}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      settings={{
                        multiple: {
                          maxLimit: Infinity,
                        },
                      }}
                      documents={documents}
                      typeQuestion="sub-question"
                    />
                  ),
              )}
            </QuestionSegment>
          )}
        </>
      )}
      <Spacer size="24" display="block" />
      {sub?.length > 0 && (
        <QuestionSegment id="secondary_question" padding="24px">
          {sub.map(
            (s) =>
              s.key !== 'budget' &&
              s.key !== 'service_fee' &&
              s.key !== 'budget_currency' && (
                <InputSelector
                  input={s}
                  onChange={handleChangeSub}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  settings={{
                    multiple: {
                      maxLimit: Infinity,
                    },
                  }}
                  documents={documents}
                  typeQuestion="sub-question"
                />
              ),
          )}
        </QuestionSegment>
      )}
    </Root>
  );
}
