import React from 'react';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

import image from 'ui/assets/popup_decline.svg';

interface NotInterestedInJobModalProps {
  open: boolean;
  onKeepJob: () => void;
  onRemoveJob: () => void;
}

export default function NotInterestedInJobModal(props: NotInterestedInJobModalProps) {
  const { open, onKeepJob, onRemoveJob } = props;

  return (
    <ConfirmationModal
      open={open}
      title="Not interested in the job"
      titleOptions={{ color: 'red' }}
      subtitle={[
        'Are you sure?',
        "The job will be removed, and you can't see it from your job board",
        'anymore.',
      ]}
      subtitleOptions={{ color: 'black' }}
      img={{ src: image, alt: 'not interested in job' }}
      primary={{ text: 'No, keep it', color: 'red', onClick: onKeepJob }}
      ghost={{ text: 'Remove this job', color: 'red', onClick: onRemoveJob }}
    ></ConfirmationModal>
  );
}
