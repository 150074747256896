export default {
  DRAFT: -1,
  FREELANCER_NEEDED: 0,
  AWAITING_FREELANCERS: 1,
  READY_TO_RECOMMEND: 2,
  RECOMMENDATION_SENT: 3,
  AWAITING_PAYMENT: 4,
  WORK_IN_PROGRESS: 5,
  JOB_SUBMITTED: 6,
  JOB_COMPLETED: 7,
};
