// export { default as phoneNumbers } from "./phoneNumbers";
// export { default as countries } from "./countries";
export { default as banks } from './banks';
// export { default as mimeTypes } from "./mimeTypes";
export { default as languages } from './languages';
// export { default as interests } from "./interests";
// export { default as proficiencies } from "./proficiencies";
// export { default as socialMedias } from "./socialMedias";
// export { default as universities } from "./universities";
export { default as regions } from './regions';
export { default as currencies } from './currencies';
export { default as permissions } from './permissions';
export { default as countryCode } from './countryCode';
export { default as expertises } from './expertises';
export { default as services } from './services';
export { default as jobStatusClient } from './jobStatus/client';
export { default as jobStatusFreelancer } from './jobStatus/freelancer';
export { default as jobStatusAdmin } from './jobStatus/admin';

export const nonCountryRegionIDs = [1, 2, 3, 4, 5, 6, 261, 260];

export const MAIN_JOB_TYPE_ID = '602401635d3257386f775808';
