import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isBrowser, isMobile } from 'react-device-detect';

import { setHeader, setLogout, setSticky } from 'store/reducer/global';
import { useOutsideClick } from 'hooks/useOutsideClick';
import colors from 'ui/colors';
import Feedback from 'ui/molecules/Feedback/Feedback';
import { Avatar, Icon, Text, Row, NavItemMobile, A, Spacer } from 'ravenry-ui';
import { amplitudeEvent } from 'helper/amplitude';

const Container = styled.div`
  display: flex;
  z-index: 0;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  margin: auto;

  cursor: pointer;
  /* padding: 8px; */
  max-width: 300px;
`;

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
  display: flex;
  font-size: 0.875rem;
`;

const Dropdown = styled.div`
  position: absolute;
  top: ${isMobile ? '-150px' : isBrowser ? '32px' : '32px'};
  display: flex;
  flex-direction: column;
  background: white;

  width: 150px;

  /* Hover Elevation */

  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.15));
  border-radius: 5px;
`;

const DropdownP = styled(Text)``;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  box-sizing: border-box;
  &:hover {
    background-color: ${colors.backgroundDark};
  }

  ${DropdownP} {
    color: ${colors.black40};
  }

  &:hover ${DropdownP} {
    color: ${colors.black100};
  }
`;

export default function UserInfo() {
  const global = useSelector((state) => state.global);
  const [isLoggedIn, setIsLoggedIn] = useState(global.isLoggedIn);
  const [userData, setUserData] = useState(global.user);

  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [openDropdown, setOpenDropdown] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  useEffect(() => {
    setIsLoggedIn(global.isLoggedIn);
    setUserData(global.user);
  }, [global]);

  useOutsideClick(ref, () => {
    setOpenDropdown(false);
  });

  let helpCenterUrl =
    global.user?.domain === 'freelancer'
      ? 'https://www.notion.so/ravens/Ravenry-Freelancers-Help-Centre-0dade0d5c8284185b04aab9e9e4797ad'
      : global.user?.domain === 'client'
      ? 'https://www.notion.so/ravens/Ravenry-Help-Center-6e4fba5eb5b94c9facd77a2e06237c3d'
      : '';

  return (
    isLoggedIn && (
      <>
        <Container
          data-cy="navbar-user-info"
          onClick={() => setOpenDropdown(!openDropdown)}
          ref={ref}
        >
          {isBrowser && (
            <>
              <div>
                <Avatar
                  url={userData.avatar}
                  name={
                    userData.firstName && userData.lastName
                      ? `${userData.firstName} ${userData.lastName}`
                      : userData.fullName
                  }
                />
              </div>

              <Name>
                <Row alignItems="center" noWrap>
                  <Row>
                    <Text _as="b2" color="black60" ellipsis>
                      Hello,
                    </Text>
                    <Spacer size="4" />
                    <Text
                      color="black60"
                      _as="b2"
                      bold
                      ellipsis
                      width="50px"
                      display="inline-block"
                    >
                      {userData.firstName ? userData.firstName : userData.fullName?.split(' ')[0]}
                    </Text>
                  </Row>
                  <Spacer size="4" />
                  <Icon name="menu" fill="black40" hoverFill="black100" hover />
                </Row>
              </Name>
            </>
          )}
          {isMobile && (
            <NavItemMobile>
              <Icon name="profile" fill="black40" />
              My Profile
            </NavItemMobile>
          )}

          {openDropdown && (
            <Dropdown>
              {global.user?.domain === 'freelancer' && (
                <>
                  <DropdownItem
                    onClick={() => history.push(`/freelancer/profile/${global.user.userId}`)}
                  >
                    <DropdownP _as="b2">My Profile</DropdownP>
                  </DropdownItem>
                </>
              )}
              {global.user?.domain === 'client' && (
                <>
                  <DropdownItem
                    onClick={() => history.push(`/client/profile/${global.user.userId}`)}
                    data-cy="navbar-user-info-client-profile"
                  >
                    <DropdownP _as="b2">Profile</DropdownP>
                  </DropdownItem>
                </>
              )}

              {global.user?.domain === 'admin' && (
                <>
                  <DropdownItem
                    as={Link}
                    to="/god/settings"
                    data-cy="navbar-user-info-admin-settings"
                  >
                    <DropdownP _as="b2">Settings</DropdownP>
                  </DropdownItem>
                </>
              )}

              {global.user?.domain !== 'admin' && (
                <>
                  <DropdownItem
                    onClick={() => window.open('https://theravenry.com/user-agreement/', '_blank')}
                  >
                    <DropdownP _as="b2" onClick={() => amplitudeEvent('user agreement')}>
                      User Agreement
                    </DropdownP>
                  </DropdownItem>
                  <DropdownItem as={A} href={helpCenterUrl} newTab>
                    <DropdownP _as="b2" onClick={() => amplitudeEvent('help center')}>
                      Help Center
                    </DropdownP>
                  </DropdownItem>
                </>
              )}
              <DropdownItem onClick={() => setFeedbackOpen(true)}>
                <DropdownP _as="b2">Submit Feedback</DropdownP>
              </DropdownItem>

              {global.user?.domain !== 'admin' && (
                <>
                  <DropdownItem as={Link} to={`/${global.user.domain}/settings`}>
                    <DropdownP _as="b2">Settings</DropdownP>
                  </DropdownItem>
                </>
              )}

              <DropdownItem
                onClick={() => {
                  dispatch(setLogout());
                  dispatch(setSticky(null));
                  dispatch(setHeader(null));
                  history.push(`/${global.user.domain}/login`);
                }}
              >
                <DropdownP _as="b2">Logout</DropdownP>
              </DropdownItem>
            </Dropdown>
          )}
        </Container>
        <Feedback
          open={feedbackOpen}
          userName={global.user.userName}
          email={global.user.email}
          domain={global.user.domain}
          onClose={() => setFeedbackOpen(false)}
        />
      </>
    )
  );
}
