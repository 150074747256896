import React from 'react';
import styled from 'styled-components';

import { Avatar, Col, Text, Row } from 'ravenry-ui';
import { constructFirstNameLastInitial } from 'helper/utils';
import colors from 'ui/colors';
import Responsive from 'helper/responsive';
import { jobStatusClient } from 'store/constants/Constants';
import { Accordion } from 'ui/molecules';
import { useSelector } from 'react-redux';

const Root = styled.div`
  position: sticky;
  top: 0;
  box-sizing: border-box;
  width: ${({ isMobile }) => (isMobile ? '100%' : '300px')};
  border-right: ${({ isMobile }) => !isMobile && `1px solid ${colors.black5}`};
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  height: 57px;
  padding: 16px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.black5};
  background: ${colors.white};
  border-radius: 10px 0 0 0;
`;

const List = styled(Row)`
  padding: 8px 24px;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? colors.blue10 : colors.white)};
  &:hover {
    background-color: ${colors.backgroundLight};
  }
`;

const NewTag = styled.div`
  width: 35px;
  height: 18px;
  background: ${colors.red90};
  border-radius: 3px;
  padding: 3px 5px;
  align-items: center;
`;

// const Status = styled.span`
//   height: 5px;
//   width: 5px;
//   vertical-align: middle;
//   margin-left: 12px;
//   border-radius: 50%;
//   background-color: ${({ isOnline }) =>
//     isOnline ? colors.green100 : colors.red100};
// `;

/**
 * ChatParticipants
 *
 * @param {Object} props
 * @param {Array} props.participants
 * @param {String} [props.activeId] - to distinguish which participant it is selected
 * @param {Function} props.onSelect -
 * @param {Array} props.unreadMessages - list of unreadMessages
 * @param {Function} props.onRead -
 * @param {Object} props.job
 */
export default function ChatParticipants(props) {
  const { participants, title, activeId, onSelect, unreadMessages, onRead, job } = props;
  const { isMobile } = Responsive();

  const activeParticipant = participants.find((participant) => participant._id === job._assignTo);

  let listParticipants;
  if (job.status >= jobStatusClient.MAKE_PAYMENT && activeParticipant) {
    listParticipants = [activeParticipant];
  } else {
    listParticipants = participants;
  }

  const archivedParticipants = participants.filter(
    (participant) => participant._id !== job._assignTo,
  );

  const domain = useSelector((state) => state.global.user.domain);

  return (
    <Root isMobile={isMobile}>
      <Header bold>
        <Text _as="b1">{title || 'Chat list'}</Text>
      </Header>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100% - 65px)',
        }}
      >
        <div>
          {listParticipants.map((participant) => (
            <List
              isActive={activeId === participant.chatRoom}
              key={`chat-participant-${participant.chatRoom}`}
              onClick={() => {
                onSelect(participant.chatRoom);
                onRead(participant._id);
              }}
            >
              <Col>
                <Avatar
                  url={participant.avatarLink}
                  size="medium"
                  name={
                    participant?.name
                      ? participant?.name
                      : `${participant?.firstName} ${participant?.lastName}`
                  }
                />
              </Col>
              <Col
                style={{
                  marginLeft: '16px',
                  flex: '1 1 100%',
                  minWidth: 0,
                  flexWrap: 'nowrap',
                }}
              >
                <Row alignItems="center">
                  <Text _as="s3" bold ellipsis fluid>
                    {constructFirstNameLastInitial(
                      participant?.name
                        ? participant?.name
                        : `${participant?.firstName} ${participant?.lastName}`,
                    )}
                  </Text>
                  {/* <Status isOnline={participant.isOnline} /> */}
                </Row>
                <Text _as="b3" color="black40" fluid ellipsis>
                  {participant.title || '-'}
                </Text>
              </Col>
              {unreadMessages.some((message) => message.participants === participant._id) &&
              unreadMessages.length > 0 ? (
                <Col>
                  <NewTag>
                    <Text
                      _as="b1"
                      size="10px"
                      lineHeight="12px"
                      letterSpacing="0.3px"
                      color="backgroundWhite"
                      bold
                    >
                      NEW
                    </Text>
                  </NewTag>
                </Col>
              ) : null}
            </List>
          ))}
        </div>

        {domain === 'client' &&
          archivedParticipants.length > 0 &&
          job.status >= jobStatusClient.MAKE_PAYMENT && (
            <Accordion
              contentHeight={170}
              noHover
              contentOverflow="auto"
              chevronContainerHover
              chevronColor="black60"
              title={
                <Row padding="8px 24px">
                  <Text _as="s5" color="black60">
                    Archived chat
                  </Text>
                </Row>
              }
              content={archivedParticipants.map((participant) => (
                <List
                  isActive={activeId === participant.chatRoom}
                  key={`chat-participant-${participant.chatRoom}`}
                  onClick={() => {
                    onSelect(participant.chatRoom);
                    onRead(participant._id);
                  }}
                >
                  <Col>
                    <Avatar
                      url={participant.avatarLink}
                      size="medium"
                      name={
                        participant?.name
                          ? participant?.name
                          : `${participant?.firstName} ${participant?.lastName}`
                      }
                    />
                  </Col>
                  <Col
                    style={{
                      marginLeft: '16px',
                      flex: '1 1 100%',
                      minWidth: 0,
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Row alignItems="center">
                      <Text _as="s3" bold ellipsis fluid>
                        {constructFirstNameLastInitial(
                          participant?.name
                            ? participant?.name
                            : `${participant?.firstName} ${participant?.lastName}`,
                        )}
                      </Text>
                      {/* <Status isOnline={participant.isOnline} /> */}
                    </Row>
                    <Text _as="b3" color="black40" fluid ellipsis>
                      {participant.title || '-'}
                    </Text>
                  </Col>
                  {unreadMessages.some((message) => message.participants === participant._id) &&
                  unreadMessages.length > 0 ? (
                    <Col>
                      <NewTag>
                        <Text
                          _as="b1"
                          size="10px"
                          lineHeight="12px"
                          letterSpacing="0.3px"
                          color="backgroundWhite"
                          bold
                        >
                          NEW
                        </Text>
                      </NewTag>
                    </Col>
                  ) : null}
                </List>
              ))}
            />
          )}
      </div>
    </Root>
  );
}
