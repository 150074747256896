import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Col, Container, NewLabel, Spacer, StatusBadge, Text, UnreadDot } from 'ravenry-ui';
import { NewCard } from 'ui/molecules';
import Responsive from 'helper/responsive';
import { convertStatusNumberToText, getDashboardStatusColor } from 'helper/utils';
import { jobStatusClient, jobStatusFreelancer } from 'store/constants/Constants';
import { Job } from 'store/constants/properties';
import { ColorOptions } from 'ui/colors';

interface Props {
  jobs: Job[];
  userRole?: 'client' | 'freelancer';
}

export default function DashboardJobCards({ jobs, userRole = 'client' }: Props) {
  const { isMobile } = Responsive();
  return (
    <>
      {jobs.map((job) => {
        const linkTo = { pathname: `/${userRole}/jobs/${job._id}`, search: '' };
        if (userRole === 'client') {
          if (job.status === jobStatusClient.DRAFT) {
            linkTo.pathname = `/${userRole}/dashboard/${job._id}`;
          }

          if (
            job.status >= jobStatusClient.LOOK_FOR_FREELANCERS &&
            job.status < jobStatusClient.WORK_IN_PROGRESS
          ) {
            linkTo.pathname = `/${userRole}/jobs/${job._id}`;
            linkTo.search = '?tab=candidates';
          }

          if (
            job.status >= jobStatusClient.WORK_IN_PROGRESS &&
            job.status < jobStatusClient.COMPLETED
          ) {
            linkTo.pathname = `/${userRole}/jobs/${job._id}`;
            linkTo.search = '?tab=chat';
          }

          if (job.status === jobStatusClient.COMPLETED) {
            linkTo.pathname = `/${userRole}/jobs/${job._id}`;
            linkTo.search = '?tab=brief';
          }
        } else if (userRole === 'freelancer') {
          if (job.status <= jobStatusFreelancer.PENDING_MATCH) {
            linkTo.pathname = `/${userRole}/jobs/${job._id}`;
            linkTo.search = '?tab=brief';
          }
          if (job.status >= jobStatusFreelancer.MATCHED) {
            linkTo.pathname = `/${userRole}/jobs/${job._id}`;
            linkTo.search = '?tab=chat';
          }
        }

        return (
          <React.Fragment key={`job-${job._id}`}>
            <Link to={linkTo}>
              <NewCard noMargin padding="16px 24px 16px 12px" column={isMobile}>
                <Container flex row alignItems="center">
                  <Col width="20px" alignItems="center" justifyContent="center">
                    {job.unread && <UnreadDot />}
                  </Col>

                  <Spacer size="8" />

                  <Col width={isMobile ? '100%' : '380px'}>
                    <StatusBadge
                      color={
                        getDashboardStatusColor(job.status, job.cancelled, userRole) as ColorOptions
                      }
                    >
                      {job.status === -1
                        ? 'Draft'
                        : convertStatusNumberToText(
                            job.status,
                            userRole,
                            job.cancelled,
                            job?.availableFor?.recommendedBy?.role,
                          )}
                    </StatusBadge>
                    <Spacer size="4" />
                    <Text _as="s4" bold>
                      {job.title?.slice(0, 50)}
                      {(job.title || '').length > 50 ? '...' : ''}
                    </Text>
                  </Col>
                </Container>

                <Spacer size={isMobile ? '16' : '32'} />

                <Container flex row width={isMobile ? '100%' : 'fit-content'}>
                  {isMobile ? <Spacer size="28" /> : null}

                  <Col width={isMobile ? '60%' : '163px'}>
                    {userRole === 'client' ? (
                      <>
                        <Text _as="b3" color="black80">
                          Job deadline
                        </Text>
                        <Spacer size="4" />
                        <Text _as="s5" bold>
                          {job.deadline
                            ? moment(job.deadline).format('ddd, D MMM YYYY')
                            : 'Not set'}
                        </Text>
                      </>
                    ) : userRole === 'freelancer' ? (
                      <>
                        <Text _as="b3" color="black80">
                          Job duration
                        </Text>
                        <Spacer size="4" />
                        <Text _as="s5" bold>
                          {job.duration || 'Undefined'}
                        </Text>
                      </>
                    ) : null}
                  </Col>

                  {isMobile ? null : <Spacer size="32" />}

                  <Col width={isMobile ? '40%' : '97px'}>
                    <Text _as="b3" color="black80">
                      Job fees
                    </Text>
                    <Spacer size="4" />
                    <Text _as="s5" bold>
                      {job.budgetCurrency && job.budgetCurrency.toUpperCase()}&nbsp;
                      {job.budget
                        ? `${job.budget.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : 'Not set'}
                    </Text>
                  </Col>
                </Container>
              </NewCard>
            </Link>
            <Spacer size="10" display="block" />
          </React.Fragment>
        );
      })}
    </>
  );
}
