import React from 'react';

import popup_cancel from 'ui/assets/popup_cancel.svg';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

interface DeleteArchivedModalProps {
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

export default function DeleteArchivedModal(props: DeleteArchivedModalProps) {
  const { open, onCancel, onDelete } = props;

  return (
    <ConfirmationModal
      open={open}
      img={{ src: popup_cancel, alt: 'remove folder' }}
      title="Remove folder"
      titleOptions={{ color: 'red' }}
      subtitle={[
        'This action will remove the folder only.',
        'All contained jobs will be moved out from the folder.',
      ]}
      subtitleOptions={{ color: 'black' }}
      primary={{
        text: 'Cancel',
        color: 'red',
        onClick: onCancel,
      }}
      ghost={{
        text: 'Yes, remove',
        color: 'red',
        onClick: onDelete,
      }}
    />
  );
}
