import React from 'react';
import styled from 'styled-components';
import colors from 'ui/colors';

import { Spacer, Container, Divider, Text } from 'ravenry-ui';

/**
 * Panel
 *
 * @param {Object} props - props of the component
 * @param {String} [props.title] - title of the panel
 * @param {React.ComponentElement} [props.action] - action component of the panel
 * @param {String} [props.width] - width of the segment
 * @param {React.CSSProperties} [props.style] -
 */

const Root = styled.div.withConfig({
  // add props to the array to prevent them passed to the element
  // title is prevented because it will be displayed as default html popup
  // action is prevented to remove warning in developer console
  // since it is used only for styled-components styling condition
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['title', 'action'].includes(prop) && defaultValidatorFn(prop),
})`
  margin: 0;
  padding: ${({ padding }) => padding || '19px 24px 24px 24px'};
  background-color: ${colors.backgroundWhite};
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039), 0px 0.85px 3px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
`;

export default function Panel(props) {
  const { children, title, subtitle, action, small } = props;

  return (
    <Root {...props}>
      {title && (
        <>
          <Container flex style={{ justifyContent: 'space-between' }} alignItems="center" fluid>
            <Text _as="s3" fluid bold>
              {title}
            </Text>
            {action && action}
          </Container>
          <Spacer size="19" display="block" />
          {subtitle ? <Text _as="b2">{subtitle}</Text> : null}
          <Divider margin="0px" offset={small ? '24' : '24'} />
          <Spacer size="8" display="block" />
        </>
      )}
      {children}
    </Root>
  );
}
