import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Api from 'helper/api';
import {
  setGlobalError,
  setNotif,
  setTotalUnreadNotifications,
  setGlobalNotif,
} from 'store/reducer/global';
import {
  listenToNotifications,
  listenToTotalUnread as listenToTotalUnreadFirestore,
} from 'helper/firebase';

export const useNotif = () => {
  const api = new Api();
  const dispatch = useDispatch();

  const notifications = useSelector((state) => state.global.notif);

  const [notifJob, setNotifJob] = useState({});
  const [internalLoading, setInternalLoading] = useState(false);

  const fetchNotifications = (params) => {
    listenToNotifications(
      { limit: params._limit * params._page },
      (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          docs.push({
            ...data,
            createdAt: data.createdAt.toDate().getTime(),
            _id: doc.id,
          });
        });

        const payload = {
          ...notifications,
          docs,
        };
        dispatch(setNotif(payload));
      },
      (err) => dispatch(setGlobalError(err)),
    );
  };

  const listenToTotalUnread = () => {
    listenToTotalUnreadFirestore((querySnapshot) => {
      const userData = querySnapshot.data();
      dispatch(setTotalUnreadNotifications(userData ? userData.totalUnread : 0));
    });
  };

  function fetchGlobalNotifications() {
    return api
      .getGlobalNotifications()
      .then((res) => {
        dispatch(setGlobalNotif(res.data));
      })
      .catch((err) => console.error(err));
  }

  const readNotifications = (unreadNotifs) =>
    api
      .readNotifications(unreadNotifs)
      .then(() => {
        fetchGlobalNotifications();
      })
      .catch((err) => {
        console.error('handleError =>', err);
        dispatch(setGlobalError(err));
      });

  const readAllNotifications = () =>
    api
      .readAllNotifications()
      .then(() => {
        fetchGlobalNotifications();
      })
      .catch((err) => {
        console.error('handleError =>', err);
        dispatch(setGlobalError(err));
      });

  function resetNotif() {
    dispatch(setNotif({}));
  }

  function fetchNotifByJobId(jobId) {
    setInternalLoading(true);

    return api
      .getNotifJobById(jobId)
      .then((res) => {
        setNotifJob(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => setInternalLoading(false));
  }

  return {
    notifications,
    fetchNotifications,
    listenToTotalUnread,
    resetNotif,
    readNotifications,
    readAllNotifications,
    fetchGlobalNotifications,
    fetchNotifByJobId,
    notifJob,
    internalLoading,
  };
};
