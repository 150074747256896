import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'rsuite';
import moment from 'moment';
import _ from 'lodash';

import {
  Row,
  Col,
  Dropdown,
  Spacer,
  Span,
  Button,
  UnreadDot,
  ColoredTag,
  Text,
  StatusBadge,
  NewLabel,
  PublicLabel,
} from 'ravenry-ui';
import { NewCard, IconWithTooltip, Empty, IconButton } from '../../molecules';
import {
  snakeCaseToSentenceCase,
  convertStatusNumberToText,
  displayNumberWithSeparator,
  cleanString,
  countExpirationCountdown,
  getStatusColorAndText,
} from '../../../helper/utils';
import Responsive from 'helper/responsive';
import { jobStatusAdmin, jobStatusClient, jobStatusFreelancer } from 'store/constants/Constants';
import useOutsideClickMultiple from '../../../hooks/useOutsideClickMultiple';
import { ColorOptions } from 'ui/colors';
import { Job } from 'store/constants/properties';
import { amplitudeEvent } from 'helper/amplitude';
import { CountDownTimer } from 'ravenry-ui';

const Loading = styled.div`
  position: fixed;
  bottom: 0%;
  /* background-color: white; */
  padding: 16px 0px;
`;

const CustomizedNewCard = styled(NewCard)`
  position: relative;
  align-items: center;
`;

type EligibleRole = 'client' | 'freelancer' | 'admin';

const getTagColor = (userRole: EligibleRole, job: Job) =>
  userRole === 'client'
    ? ['lightBlue', 'green', 'green', 'lightBlue', 'green'][job.status]
    : userRole === 'freelancer'
    ? ['lightBlue', 'lightBlue', 'green', 'lightBlue', 'green', 'lightBlue', 'green', 'lightBlue'][
        job.status
      ]
    : 'lightBlue';

const getTooltipCopy = (userRole: EligibleRole, job: Job) => {
  const status = job.status;

  if (userRole === 'client') {
    switch (status) {
      case 0:
        return 'Your request currently being reviewed. You will be notified once the freelancer recommendation is ready.';
      case 1:
        return 'You are now connected with the freelancer and can start chatting with them.';
      case 2:
        return 'You have accepted the freelancer offers. Check your email to proceed with the payment.';
      case 3:
        return 'This job has started. Currently, the freelancer is working on it.';
      case 4:
        return 'The freelancer has submitted their work. Please review their work and decide if revision is necessary.';
      default:
        return '';
    }
  } else if (userRole === 'freelancer') {
    switch (status) {
      case 1:
        return 'Your profile is currently being reviewed. You will be notified once the client is connected with you.';
      case 2:
        return 'You are now connected with the client and can start chatting with them.';
      case 3:
        return 'You have submitted your offer and are awaiting for the client to respond.';
      case 4:
        return "This job has started. Submit your work once it's ready.";
      case 5:
        return job.review
          ? 'The client has done reviewing your work, and it seems you need to do some revision.'
          : 'Your work in currently under review. Please wait for any next steps from the client.';
      default:
        return '';
    }
  }
};

const getExpiryDate = (job: Job) =>
  job.status === jobStatusAdmin.FREELANCER_NEEDED
    ? countExpirationCountdown(job.expiredAt)
    : job.status === jobStatusAdmin.READY_TO_RECOMMEND
    ? countExpirationCountdown(job.expiredAdminRecommendAt)
    : 0;

interface JobCardProps {
  jobs?: Job[];
  onNext?: () => void;
  length?: number;
  hasMore?: boolean;
  height?: number | string;
  scrollableTarget?: React.ReactNode;
  onReviewClick?: (job: Job, mode?: 'give' | 'view') => void;
  onAddToFolder?: (jobId: string, mode?: string) => void;
  onMoveToFolder?: (jobId: string) => void;
  onRemoveFromFolder?: (jobId: string) => void;
  onResumeClick?: (jobId: string) => void;
  onRestoreClick?: (jobId: string) => void;
  onDeleteDraftClick?: (jobId: string) => void;
  onArchiveJobClick?: (jobId: string) => void;
  onCancelClick?: (jobId: string) => void;
  onDuplicateClick?: (jobId: string) => void;
  onDeleteArchivedClick?: (jobId: string) => void;
  tab?: any;
  userRole?: EligibleRole;
  userId?: string;
  ellipsisOptions?: any;
  cardOptions?: {
    noMargin?: boolean;
    borderRadius?: string;
    borderTop?: string;
    shadow?: string | 'none';
    shadowOnHover?: string | 'none';
  };
  isDropdownAbsolute?: boolean;
  insideFolder?: boolean;
  datacy?: string;
}

export default function JobCard({
  jobs = [],
  onNext,
  length = 0,
  hasMore = false,
  height,
  scrollableTarget,
  onReviewClick,
  onDeleteDraftClick,
  onAddToFolder,
  onRemoveFromFolder,
  onMoveToFolder,
  onRestoreClick,
  onArchiveJobClick,
  onCancelClick,
  onDuplicateClick,
  onDeleteArchivedClick,
  tab,
  userRole = 'client',
  userId = '',
  ellipsisOptions = [],
  cardOptions = {},
  isDropdownAbsolute = false,
  insideFolder = false,
  datacy = '',
}: JobCardProps) {
  const history = useHistory();
  const location = useLocation();
  const refs = useRef<HTMLDivElement[]>([]);
  const { isMobile } = Responsive();
  const [dropdown, setDropdown] = useState<{ open: boolean; id: string | null }>({
    open: false,
    id: null,
  });

  const [dropdownPosition, setDropdownPosition] = useState({ left: 0, top: 0 });

  const getEllipsisPosition = (index: number) => {
    const element = refs.current[index];
    return {
      left: element.offsetLeft,
      top: element.offsetTop,
    };
  };
  const isArchived = (archivedBy: Array<Object>) => {
    for (let i = 0; i < archivedBy?.length; i++) {
      if (archivedBy[i]?.user === userId) return true;
    }
    return false;
  };
  const getEllipsisItems = (
    {
      status,
      cancelled = false,
      archived = false,
    }: { status: number; cancelled?: boolean; archived?: boolean },
    tab: string,
  ) => {
    /**
     * active tab
     * status 0 - 4 "Add to folder", "Duplicate job"
     * status 0 - 1 "Cancel job"
     * status 5 or cancelled, + "Archive job"
     *
     * draft tab
     * "Delete job" and "Duplicate job"
     *
     * archive tab
     * "Delete job" and "Restore job"
     */

    let ellipsisItems: { text: string; onClick: (jobId: string) => void; 'data-cy'?: string }[] =
      [];

    if (tab === 'active') {
      if (userRole === 'freelancer') {
        ellipsisItems = [
          {
            text: 'Archive job',
            onClick: (jobId) => {
              if (typeof onArchiveJobClick === 'function') {
                onArchiveJobClick(jobId);
              }
            },
          },
        ];
        return ellipsisItems;
      }
      if (insideFolder) {
        ellipsisItems = [
          {
            text: 'Move to folder',
            onClick: (jobId) => {
              if (typeof onMoveToFolder === 'function') {
                onMoveToFolder(jobId);
              }
            },
          },
          {
            text: 'Remove from folder',
            onClick: (jobId) => {
              if (typeof onRemoveFromFolder === 'function') {
                onRemoveFromFolder(jobId);
              }
            },
          },
        ];
      } else {
        ellipsisItems = [
          {
            text: 'Add to folder',
            onClick: (jobId) => {
              if (typeof onAddToFolder === 'function') {
                onAddToFolder(jobId);
              }
            },
          },
        ];
      }

      ellipsisItems.push({
        text: 'Duplicate job',
        onClick: (jobId) => {
          if (typeof onDuplicateClick === 'function') {
            onDuplicateClick(jobId);
            amplitudeEvent('Duplicated the job');
          }
        },
      });

      if (
        [jobStatusClient.LOOK_FOR_FREELANCERS, jobStatusClient.CHOOSE_YOUR_FREELANCER].includes(
          status,
        ) &&
        !cancelled
      ) {
        ellipsisItems.push({
          text: 'Cancel job',
          onClick: (jobId) => {
            if (typeof onCancelClick === 'function') {
              onCancelClick(jobId);
            }
          },
        });
      }

      // Hide resume
      // if (cancelled) {
      //   ellipsisItems.push({
      //     text: 'Resume job',
      //     onClick: (jobId) => {
      //       if (typeof onResumeClick === 'function') onResumeClick(jobId);
      //     },
      //   });
      // }

      if ([jobStatusClient.COMPLETED].includes(status) || cancelled) {
        ellipsisItems.push({
          text: 'Archive job',
          onClick: (jobId) => {
            if (typeof onArchiveJobClick === 'function') {
              onArchiveJobClick(jobId);
            }
          },
        });
      }
    }

    if (tab === 'draft') {
      ellipsisItems = [
        {
          text: 'Delete draft',
          onClick: (jobId) => {
            if (typeof onDeleteDraftClick === 'function') {
              onDeleteDraftClick(jobId);
            }
          },
          'data-cy': 'ellipsis-delete-job',
        },
      ];
    }

    if (tab === 'archive') {
      if (userRole === 'freelancer') {
        return [];
      }

      if (insideFolder) {
        ellipsisItems = [
          {
            text: 'Move to folder',
            onClick: (jobId) => {
              if (typeof onMoveToFolder === 'function') {
                onMoveToFolder(jobId);
              }
            },
          },
          {
            text: 'Remove from folder',
            onClick: (jobId) => {
              if (typeof onRemoveFromFolder === 'function') {
                onRemoveFromFolder(jobId);
              }
            },
          },
        ];
      }

      ellipsisItems.push(
        ...[
          {
            text: 'Delete job',
            onClick: (jobId: string) => {
              if (archived && typeof onDeleteArchivedClick === 'function') {
                onDeleteArchivedClick(jobId);
              }
            },
            'data-cy': 'ellipsis-delete-job',
          },
          {
            text: 'Duplicate job',
            onClick: (jobId: string) => {
              if (typeof onDuplicateClick === 'function') {
                onDuplicateClick(jobId);
                amplitudeEvent('Duplicated the job');
              }
            },
          },
          {
            text: 'Restore job',
            onClick: (jobId: string) => {
              if (typeof onRestoreClick === 'function') {
                onRestoreClick(jobId);
              }
            },
          },
        ],
      );
    }

    return ellipsisItems;
  };

  const getActionButton = (userRole: EligibleRole, job: Job) => {
    if (userRole === 'client') {
      return job.status === -1 ? null : job.status === 1 ? (
        <Button
          color="blue"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push({
              pathname: `/${userRole}/jobs/${job._id}`,
              search: '?tab=candidates',
              state: { from: location },
            });
          }}
        >
          See candidates
        </Button>
      ) : job.status === 4 ? (
        <Button
          color="blue"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push({
              pathname: `/${userRole}/jobs/${job._id}`,
              search: '?tab=files',
              state: { from: location },
            });
          }}
        >
          Review work
        </Button>
      ) : job.status === 5 ? (
        !job.review ? (
          <Button
            color="blue"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (typeof onReviewClick === 'function') {
                onReviewClick(job, 'give');
              }
            }}
          >
            Give rating
          </Button>
        ) : (
          <Button
            color="blue"
            variant="text"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (typeof onReviewClick === 'function') {
                onReviewClick(job, 'view');
              }
            }}
          >
            View rating
          </Button>
        )
      ) : null;
    }
    if (userRole === 'freelancer') {
      return job.status === 2 ? (
        <Button
          color="blue"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push({
              pathname: `/${userRole}/jobs/${job._id}`,
              search: '?tab=chat',
              state: { from: location },
            });
          }}
        >
          Start chat
        </Button>
      ) : job.status === 4 ? (
        <Button
          color="blue"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push({
              pathname: `/${userRole}/jobs/${job._id}`,
              search: '?tab=files',
              state: { from: location },
            });
          }}
        >
          Submit your work
        </Button>
      ) : (
        job.status === 6 &&
        !job.review && (
          <Button
            color="blue"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (typeof onReviewClick === 'function') {
                onReviewClick(job);
              }
            }}
          >
            Review client
          </Button>
        )
      );
    }
  };

  const renderStatusBadge = (status: number, isCancelled?: boolean, recommendedBy?: string) => {
    const [text, color] = getStatusColorAndText(userRole, status, isCancelled, recommendedBy);

    return <StatusBadge color={color as ColorOptions}>{text}</StatusBadge>;
  };

  useEffect(() => {
    refs.current = refs.current.slice(0, jobs?.length || 0);
  }, [jobs]);

  useOutsideClickMultiple(refs, () => {
    setDropdown({ open: false, id: null });
  });

  const isEllipsisShown = userRole === 'client' && ['Active', 'Completed'].includes(tab);

  return (
    <InfiniteScroll
      style={{ overflow: 'visible' }}
      height={height}
      dataLength={length}
      next={onNext || (() => {})}
      hasMore={hasMore}
      scrollableTarget={scrollableTarget}
      loader={
        <Loading>
          <Loader />
          Loading...
        </Loading>
      }
    >
      {jobs.length > 0
        ? jobs.map((job, index) => {
            let linkTo: { pathname: string; state?: any; search?: string; hash: string } = {
              pathname: `/client/dashboard/${job._id}`,
              state: {
                notifications: job.notifications,
              },
            };
            if (userRole === 'client') {
              if (
                job.status >= jobStatusClient.LOOK_FOR_FREELANCERS &&
                job.status < jobStatusClient.WORK_IN_PROGRESS
              ) {
                linkTo.pathname = `/${userRole}/jobs/${job._id}`;
                linkTo.search = '?tab=candidates';
                linkTo.state = { ...linkTo.state, from: location };
              }

              if (
                job.status >= jobStatusClient.WORK_IN_PROGRESS &&
                job.status < jobStatusClient.COMPLETED
              ) {
                linkTo.pathname = `/${userRole}/jobs/${job._id}`;
                linkTo.search = '?tab=chat';
                linkTo.state = { ...linkTo.state, from: location };
              }

              if (job.status === jobStatusClient.COMPLETED) {
                linkTo.pathname = `/${userRole}/jobs/${job._id}`;
                linkTo.search = '?tab=brief';
                linkTo.state = { ...linkTo.state, from: location };
              }
            } else if (userRole === 'freelancer') {
              if (job.status <= jobStatusFreelancer.PENDING_MATCH) {
                linkTo.pathname = `/${userRole}/jobs/${job._id}`;
                linkTo.search = '?tab=brief';
                linkTo.state = { ...linkTo.state, from: location };
              }
              if (job.status >= jobStatusFreelancer.MATCHED) {
                linkTo.pathname = `/${userRole}/jobs/${job._id}`;
                linkTo.search = '?tab=chat';
                linkTo.state = { ...linkTo.state, from: location };
              }
            } else if (userRole === 'admin') {
              if (job._clientId) {
                linkTo.pathname = `/god/jobs/${job._id}`;
                linkTo.hash = '#brief';
                linkTo.state = { ...linkTo.state, from: location };
              } else {
                linkTo.pathname = '/god/jobs/choose-client';
                linkTo.search = `?jobId=${job._id}`;
              }
            }

            const isNewBadgeShown =
              userRole === 'freelancer' &&
              job.status === 0 &&
              (job.lastSeen === undefined ||
                job.lastSeen === null ||
                (Array.isArray(job.lastSeen) &&
                  job.lastSeen.filter((ls) => ls.by === userId).length === 0));

            const isPublicBadgeShown = userRole === 'admin' && job.public;

            return userRole === 'client' || userRole === 'freelancer' ? (
              <Link to={linkTo} key={`job-${job._id}-${index}`} data-cy={datacy + `${index}`}>
                <CustomizedNewCard {...cardOptions} data-cy={`${userRole}-${tab}-job-${job._id}`}>
                  {isNewBadgeShown && <NewLabel />}
                  <Col width="20px" alignItems="center" justifyContent="center">
                    {job.unread && <UnreadDot />}
                  </Col>

                  <Spacer size="8" />

                  <Col width="311px">
                    <Text _as="b3" color="black60">
                      {cleanString(job.type || '')}
                    </Text>
                    <Text _as="s5" bold ellipsis width="inherit">
                      {job.title}
                    </Text>
                  </Col>

                  <Spacer size="16" />

                  <Col width="72px">
                    <Text _as="b3" color="black60">
                      Job duration
                    </Text>
                    <Text _as="s5" bold ellipsis width="inherit">
                      {job.duration || '-'}
                    </Text>
                  </Col>

                  <Spacer size="32" />

                  <Col width="77px">
                    <Text _as="b3" color="black60">
                      {userRole === 'client' ? 'Budget' : 'Job fees'}
                    </Text>
                    <Text _as="s5" bold ellipsis width="inherit">
                      {job.budget
                        ? `${job.budgetCurrency?.toLocaleUpperCase()} ${displayNumberWithSeparator(job.budget, { decimalDigit: 0 })}`
                        : 'Not set'}
                    </Text>
                  </Col>

                  <Spacer size={userRole === 'client' ? '32' : '24'} />
                  {userRole === 'freelancer' && <Spacer size="24" />}

                  {renderStatusBadge(
                    job.status,
                    job.cancelled,
                    job?.availableFor?.recommendedBy?.role,
                  )}

                  <Spacer size="24" />
                  {userRole === 'freelancer' && <Spacer size="24" />}

                  {userRole === 'client' && !job.cancelled ? (
                    job.status === jobStatusClient.CHOOSE_YOUR_FREELANCER ? (
                      <Button width="150px">See candidates</Button>
                    ) : job.status === jobStatusClient.DRAFT ? (
                      <Button width="150px">Continue</Button>
                    ) : job.status === jobStatusClient.COMPLETED && _.isEmpty(job.review) ? (
                      <Button
                        width="150px"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          history.push({
                            pathname: `/${userRole}/jobs/${job._id}`,
                            search: '?tab=candidates',
                            state: { from: location },
                          });
                        }}
                      >
                        Give review
                      </Button>
                    ) : (
                      <Col width="150px"></Col>
                    )
                  ) : userRole === 'freelancer' && !job.cancelled ? (
                    job.status === jobStatusFreelancer.INVITATION ? (
                      <Button width="150px">Respond</Button>
                    ) : job.status === jobStatusFreelancer.MATCHED ? (
                      <Button width="150px">Chat</Button>
                    ) : job.status === jobStatusFreelancer.COMPLETED && _.isEmpty(job.review) ? (
                      <Button width="150px">Give review</Button>
                    ) : (
                      <Col width="150px"></Col>
                    )
                  ) : null}

                  {job.cancelled && <Col width="150px"></Col>}

                  {userRole === 'client' && <Spacer size="16" />}

                  {(userRole === 'client' ||
                    (userRole === 'freelancer' &&
                      job.status === jobStatusFreelancer.COMPLETED)) && (
                    <div
                      ref={(el) => (refs.current[index] = el as HTMLDivElement)}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconButton
                        name="ellipsis vertical"
                        tooltipOptions={{
                          content: 'Options',
                          position: { top: '-40px', right: '-22px' },
                          arrowPosition: 'bottom-center',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const ellipsisPosition = getEllipsisPosition(index);
                          if (isDropdownAbsolute) {
                            setDropdownPosition(ellipsisPosition);
                          }
                          setDropdown({
                            ...dropdown,
                            open: !dropdown.open,
                            id: job._id,
                          });
                        }}
                      />
                    </div>
                  )}

                  {dropdown.open && dropdown.id === job._id && (
                    <Dropdown
                      style={
                        isDropdownAbsolute
                          ? { zIndex: 2 }
                          : { top: '21px', left: '-109px', zIndex: 2 }
                      }
                      position={
                        isDropdownAbsolute
                          ? {
                              left: dropdownPosition?.left - 118,
                              top: dropdownPosition?.top + 40,
                            }
                          : undefined
                      }
                      width={insideFolder ? '155px' : undefined}
                      data-cy="dropdown-job-card"
                    >
                      {getEllipsisItems(
                        {
                          status: job.status,
                          cancelled: job.cancelled,
                          archived: isArchived(job.archivedBy)
                            ? isArchived(job.archivedBy)
                            : job?.archived,
                        },
                        tab,
                      ).map((opt: any) => (
                        <Dropdown.Item
                          key={`option-${opt.text}`}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            opt.onClick(job._id);
                          }}
                          data-cy={opt['data-cy']}
                        >
                          {opt.text}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  )}
                </CustomizedNewCard>
              </Link>
            ) : userRole === 'admin' ? (
              <Link to={linkTo} key={`job-${job._id}-${index} `} data-cy={datacy + `${index}`}>
                <CustomizedNewCard {...cardOptions} data-cy={`admin-${tab}-job-${job._id}`}>
                  {isPublicBadgeShown && <PublicLabel />}

                  <Col width="20px" alignItems="center" justifyContent="center">
                    {job.unread && <UnreadDot />}
                  </Col>

                  <Spacer size="8" />

                  <Col width="311px">
                    <Text _as="b3" color="black60">
                      {cleanString(job.type || '')}
                    </Text>
                    <Text _as="s5" bold ellipsis width="inherit">
                      {job.title}
                    </Text>
                  </Col>

                  <Spacer size="16" />

                  <Col width="130px">
                    <Text _as="b3" color="black60">
                      Client name
                    </Text>
                    <Text _as="s5" bold ellipsis width="inherit">
                      {job?.clientData?.firstName
                        ? `${job.clientData.firstName} ${job.clientData.lastName}`
                        : job?.clientData?.fullName || '-'}
                    </Text>
                  </Col>

                  <Spacer size="24" />

                  {renderStatusBadge(
                    job.status,
                    job.cancelled,
                    job?.availableFor?.recommendedBy?.role,
                  )}

                  <Spacer filler />

                  {[jobStatusAdmin.FREELANCER_NEEDED, jobStatusAdmin.READY_TO_RECOMMEND].includes(
                    job.status,
                  ) && (
                    <Col alignItems="center" justifyContent="center">
                      <Row>
                        <Text _as="s6" color="black80">
                          Respond in
                        </Text>
                        <Spacer size="4" />
                        <Text _as="s6" bold>
                          {/* To be changed, waiting for CountDown atom update in Admin platform */}
                          <CountDownTimer
                            timeLeft={getExpiryDate(job) || 0}
                            completeCallback={() => null}
                          />
                        </Text>
                      </Row>

                      <Spacer size="4" display="block" />

                      {job.status === jobStatusAdmin.FREELANCER_NEEDED && (
                        <Button
                          color="blue"
                          width="150px"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push(`/god/jobs/${job._id}#brief`);
                          }}
                        >
                          Find freelancers
                        </Button>
                      )}

                      {job.status === jobStatusAdmin.READY_TO_RECOMMEND && (
                        <Button
                          color="blue"
                          width="150px"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push(`/god/jobs/${job._id}#freelancers`);
                          }}
                        >
                          See freelancers
                        </Button>
                      )}
                    </Col>
                  )}

                  <Spacer size="24" />

                  <div
                    ref={(el) => (refs.current[index] = el as HTMLDivElement)}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconButton
                      name="ellipsis vertical"
                      tooltipOptions={{
                        content: 'Options',
                        position: { top: '-40px', right: '-22px' },
                        arrowPosition: 'bottom-center',
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const ellipsisPosition = getEllipsisPosition(index);
                        if (isDropdownAbsolute) {
                          setDropdownPosition(ellipsisPosition);
                        }
                        setDropdown({
                          ...dropdown,
                          open: !dropdown.open,
                          id: job._id,
                        });
                      }}
                    />
                  </div>

                  {dropdown.open && dropdown.id === job._id && (
                    <Dropdown
                      style={
                        isDropdownAbsolute
                          ? { zIndex: 2 }
                          : { top: '21px', left: '-109px', zIndex: 2 }
                      }
                      position={
                        isDropdownAbsolute
                          ? {
                              left: dropdownPosition?.left - 118,
                              top: dropdownPosition?.top + 40,
                            }
                          : undefined
                      }
                      width={insideFolder ? '155px' : undefined}
                    >
                      {getEllipsisItems(
                        {
                          status: job.status,
                          cancelled: job.cancelled,
                          archived: isArchived(job.archivedBy)
                            ? isArchived(job.archivedBy)
                            : job?.archived,
                        },
                        tab,
                      ).map((opt: any) => (
                        <Dropdown.Item
                          key={`option-${opt.text}`}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            opt.onClick(job._id);
                          }}
                        >
                          {opt.text}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  )}
                </CustomizedNewCard>
              </Link>
            ) : isMobile ? (
              <Link to={linkTo} key={`job-${job._id}`}>
                <NewCard>
                  <Col width="20px" padding="5px" margin="0 4px 0 0">
                    {job.unread && <UnreadDot />}
                  </Col>
                  <Col justifyContent="space-between" grow>
                    <Row>
                      <Col width="240px">
                        <Row>
                          <Span _as="b3" color="black60">
                            Job deadline
                          </Span>
                          <Spacer size="8" />
                          <Span _as="b3" color="black80" bold>
                            {job.deadline
                              ? moment(job.deadline).format('dddd, D MMM Y')
                              : 'Not set'}
                          </Span>
                        </Row>
                      </Col>
                      <Col margin="0 0 0 0px">
                        <Row>
                          <Span _as="b3" color="black60">
                            Job fees
                          </Span>
                          <Spacer size="8" />
                          <Span _as="b3" color="black80" bold>
                            {job.budget ? `USD ${(+job.budget).toLocaleString()}` : 'Not set'}
                          </Span>
                        </Row>
                      </Col>
                    </Row>
                    <Spacer size="8" />
                    <Row>
                      <Col width="calc(100vw - 100px)">
                        <Text _as="b1" ellipsis fluid>
                          {job.title}
                        </Text>
                      </Col>
                    </Row>
                    <Spacer size="4" />
                    <Row>
                      <Span _as="b3" color="black60">
                        {snakeCaseToSentenceCase(job.type)}
                      </Span>
                      {userRole === 'freelancer' ? (
                        <>
                          <Spacer size="4" />
                          <Span _as="b3" color="black60">
                            -
                          </Span>
                          <Spacer size="4" />
                          <Span _as="b3" color="black60">
                            By {job.client?.firstName}
                          </Span>
                        </>
                      ) : null}
                    </Row>

                    <Row>
                      <Col justifyContent="center">
                        {tab === 'Active' && job.status > -1 && (
                          <>
                            <Spacer size="8" />
                            <Row alignItems="center">
                              <ColoredTag
                                width="140px"
                                color={getTagColor(userRole, job) as ColorOptions}
                              >
                                {convertStatusNumberToText(
                                  job.status,
                                  userRole,
                                  job.cancelled,
                                  job?.availableFor?.recommendedBy?.role,
                                )}
                              </ColoredTag>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Spacer size="24" />
                    <Row>
                      <Col alignItems="center" width="150px" justifyContent="center">
                        {getActionButton(userRole, job)}
                      </Col>
                    </Row>
                  </Col>
                </NewCard>
              </Link>
            ) : (
              <Link to={linkTo} key={`job-${job._id}`}>
                <NewCard>
                  <Col width="20px" padding="5px" margin="0 4px 0 0">
                    {job.unread && <UnreadDot />}
                  </Col>
                  <Col justifyContent="space-between" grow>
                    <Row>
                      <Col width="240px">
                        <Row>
                          <Span _as="b3" color="black60">
                            Job deadline
                          </Span>
                          <Spacer size="8" />
                          <Span _as="b3" color="black80" bold>
                            {job.deadline
                              ? moment(job.deadline).format('dddd, D MMM Y')
                              : 'Not set'}
                          </Span>
                        </Row>
                      </Col>
                      <Col margin="0 0 0 40px">
                        <Row>
                          <Span _as="b3" color="black60">
                            Job fees
                          </Span>
                          <Spacer size="8" />
                          <Span _as="b3" color="black80" bold>
                            {job.budget ? `USD ${(+job.budget).toLocaleString()}` : 'Not set'}
                          </Span>
                        </Row>
                      </Col>
                    </Row>
                    <Spacer size="8" />
                    <Row>
                      <Col>
                        <Text _as="b1" ellipsis fluid>
                          {job.title}
                        </Text>
                      </Col>
                    </Row>
                    <Spacer size="4" />
                    <Row>
                      <Span _as="b3" color="black60">
                        {snakeCaseToSentenceCase(job.type)}
                      </Span>
                      {userRole === 'freelancer' ? (
                        <>
                          <Spacer size="4" />
                          <Span _as="b3" color="black60">
                            -
                          </Span>
                          <Spacer size="4" />
                          <Span _as="b3" color="black60">
                            By {job.client?.firstName}
                          </Span>
                        </>
                      ) : null}
                    </Row>
                  </Col>
                  <Col justifyContent="center">
                    {tab === 'Active' && job.status > -1 && (
                      <>
                        <Spacer size="8" />
                        <Row alignItems="center">
                          <ColoredTag
                            width="140px"
                            color={getTagColor(userRole, job) as ColorOptions}
                          >
                            {convertStatusNumberToText(
                              job.status,
                              userRole,
                              job.cancelled,
                              job?.availableFor?.recommendedBy?.role,
                            )}
                          </ColoredTag>
                          <Spacer size="8" />
                          <IconWithTooltip
                            name="question mark"
                            content={getTooltipCopy(userRole, job) || ''}
                            position={{ top: '-40px', right: '-15px' }}
                            arrowPosition="bottom"
                          />
                        </Row>
                      </>
                    )}
                  </Col>
                  <Spacer size="24" />
                  <Col alignItems="center" width="150px" justifyContent="center">
                    {getActionButton(userRole, job)}
                  </Col>
                  {isEllipsisShown ? (
                    <>
                      <Spacer size="8" />

                      <div
                        ref={(el) => (refs.current[index] = el as HTMLDivElement)}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <IconWithTooltip
                          name="ellipsis vertical"
                          content="Options"
                          arrowPosition="bottom center"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDropdown({
                              ...dropdown,
                              open: true,
                              id: job._id,
                            });
                          }}
                          position={{ top: '-40px', right: '-22px' }}
                        />
                      </div>

                      {dropdown.open && dropdown.id === job._id && (
                        <Dropdown style={{ top: '59px', left: '-145px' }}>
                          {ellipsisOptions?.length
                            ? ellipsisOptions.map((opt: any) => (
                                <Dropdown.Item
                                  key={`option-${opt.text}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    opt.onClick(job._id);
                                  }}
                                >
                                  {opt.text}
                                </Dropdown.Item>
                              ))
                            : null}
                        </Dropdown>
                      )}
                    </>
                  ) : null}
                </NewCard>
              </Link>
            );
          })
        : !insideFolder && (
            <>
              <Spacer size="40" />
              <Empty
                text={
                  <Col alignItems="center">
                    {tab === 'active' ? (
                      <>
                        <Text _as="b2" color="black80">
                          You do not have any active jobs right now.
                        </Text>
                        <Text _as="b2" color="black80">
                          Check out available jobs on the Job Board.
                        </Text>
                      </>
                    ) : (
                      tab === 'completed' && (
                        <>
                          <Text _as="b2" color="black80">
                            Currently, there are no completed job listed yet.
                          </Text>
                          <Text _as="b2" color="black80">
                            Check for any available jobs on Job Board.
                          </Text>
                        </>
                      )
                    )}
                  </Col>
                }
                img={tab === 'active' ? 'desert' : tab === 'draft' ? 'island' : 'treasure'}
                action={
                  <Button onClick={() => history.push('/freelancer/job-board')} color="blue">
                    Go to Job Board
                  </Button>
                }
              />
            </>
          )}
    </InfiniteScroll>
  );
}
