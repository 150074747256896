import React from 'react';
import styled, { css } from 'styled-components';

import { Col, Input, Text, Row, Spacer } from 'ravenry-ui';
import colors from 'ui/colors';

/**
 * CheckboxGrid
 *
 * @param {Object} props -
 * @param {{text: String, checked: Boolean}[]} props.values
 * @param {Number} props.column - number of columns of the grid, default to 1
 * @param {Function} props.onChange - callback
 * @param {Boolean} props.error - for error checkbox
 */

const Box = styled(Row)`
  cursor: pointer;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  background: #ffffff;
  border: 1px solid;
  border-color: ${({ borderColor }) => (borderColor ? colors[borderColor] : colors.black10)};
  border-radius: 5px;

  ${({ error }) =>
    error
      ? css`
          border: 1px solid ${colors.red100};
          p {
            color: ${colors.red100};
          }
          div:nth-of-type(1) > svg > rect:first-child {
            stroke: ${colors.red100};
          }
        `
      : css`
          &:hover {
            border: 1px solid ${colors.blue90};
            p {
              color: ${colors.black100};
            }
            div:nth-of-type(1) > svg > rect:first-child {
              stroke: ${colors.blue};
            }
          }
        `}
`;

export default function CheckboxGrid(props) {
  const { values, column = 1, onChange, error } = props;

  const row = Math.ceil(values.length / column);

  return (
    <div>
      {Array.from(Array(row).keys()).map((r, rIndex, { length }) => (
        <React.Fragment key={`checkbox-row-${rIndex}`}>
          <Row>
            {Array.from(Array(column).keys()).map((c, cIndex, { length }) =>
              values.length > rIndex * column + cIndex ? (
                <React.Fragment key={`checkbox-${rIndex}-${cIndex}`}>
                  <Col ratio={1}>
                    <Box
                      alignItems="center"
                      onClick={() =>
                        onChange(
                          values[rIndex * column + cIndex].text,
                          !values[rIndex * column + cIndex].checked,
                        )
                      }
                      error={error}
                    >
                      <Input.Check
                        checked={values[rIndex * column + cIndex].checked}
                        value={values[rIndex * column + cIndex].text}
                        name={values[rIndex * column + cIndex].text}
                        onChange={() =>
                          onChange(
                            values[rIndex * column + cIndex].text,
                            !values[rIndex * column + cIndex].checked,
                          )
                        }
                      />
                      <Spacer size="12" horizontal />
                      <Text
                        _as="btn1"
                        color={values[rIndex * column + cIndex].checked ? 'black100' : 'black80'}
                        hoverColor={error && 'red100'}
                      >
                        {values[rIndex * column + cIndex].text}
                      </Text>
                    </Box>
                  </Col>
                  {cIndex < length - 1 ? <Spacer size="16" horizontal /> : null}
                </React.Fragment>
              ) : null,
            )}
          </Row>
          {rIndex < length - 1 && <Spacer size="16" />}
        </React.Fragment>
      ))}
      {error && (
        <>
          <Spacer size="16" display="block" />
          <Text _as="b3" color="red100">
            {error}
          </Text>
        </>
      )}
    </div>
  );
}
