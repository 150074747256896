import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import colors from 'ui/colors';
import Responsive from 'helper/responsive';
import Header from './Header';
import Content from './Content';
import Actions from './Actions';

const TriangleRoot = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;

  ${({ direction }) =>
    direction === 'up'
      ? `
       top: -12px;
       border-width: 0 15px 12px 15px;
       border-color: transparent transparent ${colors.blue90} transparent;
    `
      : direction === 'left'
      ? `
       left: -13px;
       border-width: 15px 12px 15px 0;
       border-color: transparent ${colors.blue90} transparent transparent;
    `
      : direction === 'down'
      ? `
       bottom: -13px;
       border-width: 12px 15px 0 15px;
       border-color: ${colors.blue90} transparent transparent transparent;
    `
      : direction === 'right'
      ? `
       right: -13px;
       border-width: 15px 0 15px 12px;
       border-color: transparent transparent transparent ${colors.blue90};
    `
      : ''}
`;

const InnerTriangle = styled.div`
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;

  ${({ direction }) =>
    direction === 'up'
      ? `
     top: 1px;
     right: 15px;
     border-width: 0 15px 12px 15px;
     border-color: transparent transparent ${colors.backgroundWhite} transparent;
  `
      : direction === 'left'
      ? `
     top: -15px;
     left: 1px;
     border-width: 15px 12px 15px 0;
     border-color: transparent ${colors.backgroundWhite} transparent transparent;
  `
      : direction === 'down'
      ? `
     top: -13px;
     left: -15px; 
     border-width: 12px 15px 0 15px;
     border-color: ${colors.backgroundWhite} transparent transparent transparent;
  `
      : direction === 'right'
      ? `
     top: -15px;
     right: 13px;  
     border-width: 15px 0 15px 12px;
     border-color: transparent transparent transparent ${colors.backgroundWhite};
  `
      : ''}
`;

function Triangle(props) {
  const { direction, style } = props;

  return (
    <TriangleRoot direction={direction} style={style}>
      <InnerTriangle direction={direction} />
    </TriangleRoot>
  );
}

const Root = styled.div`
  position: fixed;
  width: 500px;
  padding: 24px 24px 16px;
  background: ${colors.white};
  border: 1px solid ${colors.blue90};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`;

const RealRoot = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 13; /* Set it as highest in a page */
`;

const Overlay = styled.div`
  position: absolute;
  background-color: rgb(231, 234, 240); /* Fallback color */
  background-color: rgb(231, 234, 240, 0.7);
  width: 100%;
  height: 100vh;
  z-index: 11; /* Keep it above all elements in a page, except coachmark and the reference */
  top: 0;
  left: 0;
`;

export default function Coachmark(props) {
  const { reference, position = 'bottom', offset, withArrow, arrowPosition } = props;

  const { isMobile } = Responsive();

  const [dimension, setDimension] = useState(null);

  function debounce(fn, time) {
    let timOut = null;

    return function (...args) {
      if (timOut) {
        clearTimeout(timOut);
      }
      timOut = setTimeout(() => {
        // eslint-disable-next-line
        fn.apply(null, args);
        timOut = null;
      }, time);
    };
  }

  function toolTipPlacementCalculator({ dimension, position, offset }) {
    if (position === 'top') {
      return {
        bottom: dimension.bottomSpace + dimension.height + 30 + offset.bottom,
        left: dimension.leftSpace + offset.left,
      };
    }

    if (position === 'right') {
      return {
        left: dimension.width + dimension.leftSpace + 30 + offset.left,
        top: dimension.topSpace + offset.top,
      };
    }

    if (position === 'left') {
      return {
        right: dimension.width + dimension.rightSpace + 30 + offset.right,
        top: dimension.topSpace + offset.top,
      };
    }

    return {
      top: dimension.height + dimension.topSpace + 30 + offset.top,
      left: dimension.leftSpace + offset.left,
    };
  }

  function dimensionSetter({ element, setDimension }) {
    const rect = element.getBoundingClientRect();

    if (!rect) {
      return;
    }

    setDimension({
      height: rect.height,
      width: rect.width,
      leftSpace: rect.left - 5,
      rightSpace: window.innerWidth - rect.right - 5,
      topSpace: rect.top - 5,
      bottomSpace: window.innerHeight - rect.bottom - 5,
    });
  }

  let element = null;

  if (typeof reference === 'string') {
    try {
      element = document.querySelector(reference) || null;
    } catch (e) {
      console.error(`${reference}is not valid in document.querySelector`);
    }
  } else if (reference && reference.current) {
    element = reference.current;
  }

  useEffect(() => {
    if (!(props.active && element)) {
      return () => null;
    }

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    dimensionSetter({
      element,
      setDimension,
    });

    const scrollEvent = function () {
      debounce(dimensionSetter, 300)({ element, setDimension });
    };

    window.addEventListener('scroll', scrollEvent);
    window.addEventListener('resize', scrollEvent);

    return () => {
      window.removeEventListener('scroll', scrollEvent);
      window.removeEventListener('resize', scrollEvent);
    };
    // eslint-disable-next-line
  }, [props.active, element]);

  if (!dimension || !props.active || !element) {
    if (dimension && props.active && !element) {
      console.error('Ref is not passed properly');
    }

    return null;
  }

  function getDirection(tooltipPosition) {
    if (tooltipPosition === 'bottom') {
      return 'up';
    }

    if (tooltipPosition === 'top') {
      return 'down';
    }

    if (tooltipPosition === 'left') {
      return 'right';
    }

    if (tooltipPosition === 'right') {
      return 'left';
    }
  }

  // for now, coachmark is not available in mobile screen
  const tip = isMobile ? null : (
    <>
      <RealRoot>
        <Root
          style={{
            ...toolTipPlacementCalculator({
              dimension,
              position,
              offset: {
                top: offset?.top || 0,
                bottom: offset?.bottom || 0,
                left: offset?.left || 0,
                right: offset?.right || 0,
              },
            }),
          }}
        >
          {withArrow ? <Triangle direction={getDirection(position)} style={arrowPosition} /> : null}
          {props.component}
        </Root>
      </RealRoot>
      <Overlay />
    </>
  );

  return ReactDOM.createPortal(tip, document.body);
}

Coachmark.Header = Header;
Coachmark.Content = Content;
Coachmark.Actions = Actions;
