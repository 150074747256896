import React from 'react';

import popup_cancel from 'ui/assets/popup_cancel.svg';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

interface RemoveSampleWorkModalProps {
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

export default function RemoveSampleWorkModal(props: RemoveSampleWorkModalProps) {
  const { open, onCancel, onDelete } = props;

  return (
    <ConfirmationModal
      open={open}
      img={{ src: popup_cancel, alt: 'remove sample work' }}
      title="Remove sample work"
      titleOptions={{ color: 'red' }}
      subtitle={['Are you sure?', 'You will not be able to retrieve deleted sample work.']}
      subtitleOptions={{ color: 'black' }}
      primary={{
        text: 'Cancel',
        color: 'red',
        onClick: onCancel,
      }}
      ghost={{
        text: 'Remove',
        color: 'red',
        onClick: onDelete,
      }}
    />
  );
}
