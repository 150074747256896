import React from 'react';

import popup_delete from 'ui/assets/popup_delete.svg';
import ConfirmationModal from 'ui/molecules/Modal/Confirmation';

interface DeleteArchivedModalProps {
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

export default function DeleteArchivedModal(props: DeleteArchivedModalProps) {
  const { open, onCancel, onDelete } = props;

  return (
    <ConfirmationModal
      open={open}
      img={{ src: popup_delete, alt: 'delete job' }}
      title="Delete job"
      titleOptions={{ color: 'red' }}
      subtitle={['Are you sure?', 'You will not be able to retrieve deleted jobs.']}
      subtitleOptions={{ color: 'black' }}
      primary={{
        text: 'No, take me back',
        color: 'red',
        onClick: onCancel,
      }}
      ghost={{
        text: 'Yes, I’m sure',
        color: 'red',
        onClick: onDelete,
      }}
    />
  );
}
