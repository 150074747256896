import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Col, Icon, Row, Text, Button, Spacer } from 'ravenry-ui';
import Responsive from 'helper/responsive';
import colors from 'ui/colors';
import { constructFirstNameLastInitial } from 'helper/utils';
import moment from 'moment';
import { useUser } from 'hooks/useUser';

const isOnline = (time) => {
  if (!time) {
    return false;
  }

  const lastOnline = moment(time);
  const fiveMinutesAgo = moment().subtract(5, 'minutes');
  return lastOnline.isAfter(fiveMinutesAgo);
};

/**
 * ChatHeader
 *
 * @param {Object} props
 * @param {String} props.targetUrl
 * @param {String} props.participant
 * @param {Function} props.onClose
 * @param {*} [props.action] - additional action element passed by parent
 */

const Root = styled(Row)`
  position: sticky;
  top: 0;
  background-color: ${colors.white};
  box-sizing: border-box;
  padding: 0 12px 0 24px;
  height: 57px;
  align-items: center;
  border-bottom: 1px solid ${colors.black5};
  border-radius: 0 10px 0 0;
`;

const Status = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-left: 12px;
  background-color: ${({ online }) => (online ? colors.green100 : colors.black10)};
`;

function buttonByStatusNumber(
  statusNumber,
  onViewOfferAndContract,
  onCreateOffer,
  onEditOffer,
  isCancelled,
) {
  switch (statusNumber) {
    // Matched
    case 2:
      return (
        <Button onClick={onCreateOffer} disabled={isCancelled}>
          Create an offer
        </Button>
      );
    // Offer submitted
    case 3:
      return (
        <Row>
          <Button variant="outlined" onClick={onEditOffer} disabled={isCancelled}>
            Edit offer
          </Button>
          <Spacer size={16} />
          <Button width="110px" onClick={onViewOfferAndContract} disabled={isCancelled}>
            View offer
          </Button>
        </Row>
      );
    // Work in progress
    case 4:
      return (
        <Button variant="outlined" onClick={onViewOfferAndContract} disabled={isCancelled}>
          View contract
        </Button>
      );
    default:
      return null;
  }
}

export default function ChatHeader(props) {
  const global = useSelector((state) => state.global);
  const { usersLastOnline, listenLastOnline } = useUser();
  const {
    onClose,
    targetUrl,
    participant,
    action,
    jobStatus,
    onViewOfferAndContract,
    onCreateOffer,
    onEditOffer,
    isCancelled,
  } = props;

  if (!_.isEmpty(props)) {
    useEffect(() => {
      listenLastOnline(participant._id);
    }, []);
  }

  const { isMobile } = Responsive();
  return (
    <Root justifyContent="space-between">
      {!_.isEmpty(props) && (
        <>
          <Row alignItems="center" width="100%">
            {isMobile && (
              <Col>
                <Icon name="chevron left" onClick={onClose} style={{ marginRight: 16 }} />
              </Col>
            )}
            <Col width="100%">
              <Row justifyContent="space-between" alignItems="center" width="100%">
                <Link to={targetUrl} target="_blank">
                  <Row alignItems="center">
                    <Text size="18px">
                      {constructFirstNameLastInitial(
                        participant.name
                          ? participant.name
                          : `${participant.firstName} ${participant.lastName}`,
                      )}
                    </Text>
                    <Status online={isOnline(usersLastOnline[participant?._id])} />
                  </Row>
                  <Text _as="b3" color="black40">
                    {isOnline(usersLastOnline[participant?._id])
                      ? 'Online now'
                      : usersLastOnline[participant?._id]
                      ? `Last online ${moment(usersLastOnline[participant?._id]).fromNow()}`
                      : 'Last online more than a month ago'}
                  </Text>
                </Link>

                {global.user.domain === 'freelancer' &&
                  buttonByStatusNumber(
                    jobStatus,
                    onViewOfferAndContract,
                    onCreateOffer,
                    onEditOffer,
                    isCancelled,
                  )}
                {action}
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Root>
  );
}
