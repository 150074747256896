import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text, Toggle } from 'ravenry-ui';

const Container = styled.div`
  width: 100%;
  height: 68px;
  background: white;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039), 0px 0.85px 3px rgba(0, 0, 0, 0.19);
`;

interface SwitchContentProps {
  text?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: React.FormEventHandler<HTMLInputElement>;
}

/**
 * SwitchContent
 *
 * @param {Object} props
 * @param {String} [props.text]
 * @param {Boolean} [props.checked]
 * @param {Boolean} [props.disabled]
 * @param {Function} [props.onChange]
 */

export default function SwitchContent(props: SwitchContentProps) {
  const { text, checked, onChange, disabled } = props;

  return (
    <Container>
      <Text _as="s5" color="black100" bold>
        {text}
      </Text>
      <Toggle checked={checked} onChange={onChange} disabled={disabled}></Toggle>
    </Container>
  );
}
