import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { UnreadDot, Col, Spacer, Text, Container, NewLabel } from 'ravenry-ui';
import { NewCard } from 'ui/molecules';
import { snakeCaseToSentenceCase, displayNumberWithSeparator } from 'helper/utils';
import { isMobile } from 'react-device-detect';
import { Job } from 'store/constants/properties';
import { jobStatusFreelancer } from 'store/constants/Constants';

const CustomizedNewCard = styled(NewCard)`
  position: relative;
  height: 82px;
  align-items: center;
`;

interface Props {
  jobs: Job[];
  userId?: string;
}

export default function JobBoardDashboardCard({ jobs, userId = '' }: Props) {
  const location = useLocation();

  return (
    <>
      {jobs.map((job) => {
        let linkTo: { pathname: string; state?: any; search?: string } = {
          pathname: `/freelancer/job-board/${job._id}`,
          state: {
            notifications: job.notifications,
          },
        };
        if (job.status <= jobStatusFreelancer.PENDING_MATCH) {
          linkTo.pathname = `/freelancer/job-board/${job._id}`;
          linkTo.search = '?tab=brief';
          linkTo.state = { ...linkTo.state, from: location };
        }
        if (job.status >= jobStatusFreelancer.MATCHED) {
          linkTo.pathname = `/freelancer/job-board/${job._id}`;
          linkTo.search = '?tab=chat';
          linkTo.state = { ...linkTo.state, from: location };
        }
        const isNewBadgeShown =
          job.status === 0 &&
          (job.lastSeen === undefined ||
            job.lastSeen === null ||
            (Array.isArray(job.lastSeen) &&
              job.lastSeen.filter((ls) => ls.by === userId).length === 0));
        return (
          <React.Fragment key={`job-${job._id}`}>
            <Link to={linkTo}>
              <CustomizedNewCard noMargin padding="0px 16px " column={isMobile}>
                {isNewBadgeShown && <NewLabel />}
                <Container flex row alignItems="center">
                  <Col width="20px" alignItems="center" justifyContent="center">
                    {job.unread && <UnreadDot />}
                  </Col>

                  <Spacer size="8" />

                  <Col width={isMobile ? '100%' : '380px'}>
                    <Text _as="b3" color="black60">
                      {snakeCaseToSentenceCase(job.type || '')}
                    </Text>
                    <Text _as="s4" bold>
                      {job.title?.slice(0, 50)}
                      {(job.title || '').length > 50 ? '...' : ''}
                    </Text>
                  </Col>
                </Container>

                <Spacer size={isMobile ? '16' : '32'} />

                <Container flex row alignItems="center" width={isMobile ? '100%' : 'fit-content'}>
                  {isMobile ? <Spacer size="28" /> : null}

                  <Col width={isMobile ? '60%' : '163px'}>
                    <Text _as="b3" color="black60">
                      Job duration
                    </Text>
                    <Text _as="s5" bold>
                      {job.duration || 'Undefined'}
                    </Text>
                  </Col>

                  {isMobile ? null : <Spacer size="32" />}

                  <Col alignItems="center" width={isMobile ? '40%' : '128px'}>
                    <Text _as="s2" bold textAlign="center">
                      {job.budget
                        ? `USD ${displayNumberWithSeparator(job.budget, { decimalDigit: 2 })}`
                        : 'Not set'}
                    </Text>
                    <Text _as="s6" color="black60">
                      before {job?.serviceFee ? job?.serviceFee : "20%" } platform fee
                    </Text>
                  </Col>
                </Container>
              </CustomizedNewCard>
            </Link>
            <Spacer size="10" display="block" />
          </React.Fragment>
        );
      })}
    </>
  );
}
