import { createGlobalStyle } from 'styled-components';
import { isMobile } from 'react-device-detect';
import fonts from './fonts';
import colors from './colors';

const GlobalStyles = createGlobalStyle`
  html {
   box-sizing: border-box;
   font-size: 16px;
   font-family: ${fonts.sansSerif};
  }

  body {
    font-family: ${fonts.sansSerif} !important;  
    background-color : ${colors.backgroundLight}
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }


  * {
    font-family: ${fonts.sansSerif};
  }

  p + p {
    margin-top: 0;
  }

  .ck-editor__editable_inline {
    min-height: 100px !important;
  }

  /* Change the white to any color ;) */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  /* Scrollbar custom */

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: ${isMobile ? '0px' : '8px'};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey;  */

    border-radius: 4px;
    padding :8px;
  }
 
  /* Handle */
  ::-webkit-scrollbar-thumb {
    /* background: ${colors.black10};  */
    background: #e7eaf0; 
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue}; 
  }

  /* end of Scrollbar custom */


  /* Custom Input type="file" */
  .fileUpload {
    position: relative;
    overflow: hidden;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 1.25rem;
    height:40px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  /* End of Custom Input type="file" */


  /* Hide HTML5 Up and Down arrows. */
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
 
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* end of Hide HTML5 Up and Down arrows. */

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:focus {
    text-decoration: none;
  }

  /* #root {
    min-height: 100vh;
  } */

  .cke {
    border: none !important;
  }

  .cke_bottom {
    display: none !important;
  }

  .rcc-Arrow {

    background-color: transparent !important;
}
`;

export default GlobalStyles;
