import React from 'react';
import { Container } from 'ravenry-ui';
import { CardPile } from 'ui/organisms';
import moment from 'moment';
// import { countExpirationCountdown } from 'helper/utils';
import { startCase } from 'lodash';

export default function DetailItems(props) {
  const { items, side } = props;
  // const [timer, setTimer] = useState(null);
  // useEffect(() => {
  //   switch (side) {
  //     case 'freelancer':
  //       setTimer(countExpirationCountdown(items?.availableFor?.expiredAt));
  //       break;

  //     case 'admin':
  //       items.status === 0 ? setTimer(countExpirationCountdown(items.expiredAt)) : setTimer(0);
  //       break;

  //     case 'client':
  //       items.status !== 0 ? setTimer(countExpirationCountdown(items.expiredAt)) : setTimer(0);
  //       break;

  //     default:
  //       setTimer(500000);
  //       break;
  //   }
  // }, [items]);

  return (
    <Container fluid flex start style={{ flexWrap: 'wrap' }}>
      <CardPile.Item compact>
        <CardPile.Label>Job deadline</CardPile.Label>
        <CardPile.Value>
          {items.deadline ? moment(items.deadline).format('dddd, D MMM YYYY') : 'Not set'}
        </CardPile.Value>
      </CardPile.Item>
      <CardPile.Item compact>
        <CardPile.Label>Job budget</CardPile.Label>
        <CardPile.Value>
          {items.budget
            ? `${
                items.budgetCurrency ? items.budgetCurrency.toUpperCase() : 'USD'
              } ${items.budget.toLocaleString()}`
            : 'Not set'}
        </CardPile.Value>
      </CardPile.Item>
      <CardPile.Item compact>
        <CardPile.Label>Job service fees</CardPile.Label>
        <CardPile.Value>
          {items.serviceFee
            ? `${items.budgetCurrency.toUpperCase()} ${(
                (items.budget * items.serviceFee) /
                100
              ).toLocaleString()} (${items.serviceFee.toLocaleString()}%)`
            : 'Not set'}
        </CardPile.Value>
      </CardPile.Item>
      {side !== 'client' && (
        <CardPile.Item compact>
          <CardPile.Label>Client name</CardPile.Label>
          <CardPile.Value>
            {startCase(
              items.clientData.firstName && items.clientData.lastName
                ? `${items.clientData.firstName} ${items.clientData.lastName}`
                : items.clientName,
            )}
          </CardPile.Value>
        </CardPile.Item>
      )}{' '}
      {/* <CardPile.Item compact>
        <CardPile.Label>Time to respond</CardPile.Label>
        <CardPile.Value color="lightBlue">
          {timer !== null && (
            <Timer initialTime={timer} direction="backward">
              {() => (
                <React.Fragment>

                  <Timer.Hours />:
                  <Timer.Minutes />:
                  <Timer.Seconds />
                </React.Fragment>
              )}
            </Timer>
          )}
        </CardPile.Value>
      </CardPile.Item> */}
    </Container>
  );
}
