import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Spacer, Button, Text, Container } from 'ravenry-ui';

import { CardCarousel } from 'ui/molecules';
import { getCookie, translateFreelancerStatus } from 'helper/utils';

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
`;

const DetailContainer = styled.div``;

export default function RecentActivitiesCardCarousel({ items, userRole }) {
  const history = useHistory();
  const userId = getCookie('ID');

  const getTemplateFromStatus = (item) => {
    if (userRole === 'freelancer') {
      const status = translateFreelancerStatus(item.availableFor?.status, item.status);
      switch (status) {
        case 1:
          return {
            description:
              'We are currently reviewing your profile for this job. Update your profile with relevant sample work and portfolio to increase your chances to be matched.',
            action: (
              <Button color="blue" onClick={() => history.push(`/freelancer/profile/${userId}`)}>
                Go to profile
              </Button>
            ),
          };
        case 2:
          return {
            description:
              'Congrats. You have been connected with the client. Start chatting with the client to learn more about the job.',
            action: (
              <Button
                color="blue"
                onClick={() => history.push(`/freelancer/jobs/${item._id}?tab=chat`)}
              >
                Chat
              </Button>
            ),
          };
        case 3:
          return {
            description:
              'The client is currently reviewing your profile and offer. Feel free to ping them via chat to follow up with them.',
            action: (
              <Button
                color="blue"
                onClick={() => history.push(`/freelancer/jobs/${item._id}?tab=chat`)}
              >
                Chat
              </Button>
            ),
          };
        case 4:
          return {
            description:
              'Congratulations! The client has decided to work with you on this job. Start working on this job now.',
            action: (
              <Button
                color="blue"
                onClick={() => history.push(`/freelancer/jobs/${item._id}?tab=brief`)}
              >
                View job
              </Button>
            ),
          };
        case 5:
          return {
            description:
              'You have submitted your work to the client. Chat with the client to follow up on their feedback.',
            action: (
              <Button
                color="blue"
                onClick={() => history.push(`/freelancer/jobs/${item._id}?tab=chat`)}
              >
                Chat
              </Button>
            ),
          };

        default:
          return undefined;
      }
    } else if (userRole === 'client') {
      switch (item.status) {
        case 1:
          return {
            description:
              'We have found some freelancers for you. Chat with them to discuss their capabilities.',
            action: (
              <Button
                color="blue"
                onClick={() => history.push(`/client/jobs/${item._id}?tab=candidates`)}
              >
                See candidates
              </Button>
            ),
          };
        case 2:
          return {
            description:
              'Your invoice is being processed by our team right now and will be sent to your email.',
            action: null,
          };
        case 4:
          return {
            description: 'Your freelancer has submitted their work. Review their work now.',
            action: (
              <Button
                color="blue"
                onClick={() => history.push(`/client/jobs/${item._id}?tab=brief`)}
              >
                Review work
              </Button>
            ),
          };
        default:
          return undefined;
      }
    }
  };

  const renderedItems = items
    .filter((item) => getTemplateFromStatus(item) !== undefined || item.type === 'prompt')
    .map((item) => {
      const template = getTemplateFromStatus(item);
      const isPrompt = item.type === 'prompt';

      return isPrompt ? (
        item.render
      ) : (
        <Container flex column justify fluid key={`carousel-item-${item._id}`}>
          <DetailContainer>
            <Text _as="b3">Job title</Text>
            <Text _as="s5" bold>
              {item.title}
            </Text>
            <Spacer size="10px" />
            <Text _as="b3">{template.description}</Text>
            <Spacer size="10px" />
          </DetailContainer>
          <ActionContainer>{template.action}</ActionContainer>
        </Container>
      );
    });

  return (
    <CardCarousel items={renderedItems} empty="You do not have any recent activity right now." />
  );
}
