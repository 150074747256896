import React, { useState } from 'react';
import styled from 'styled-components';

import ConfirmationModal from 'ui/molecules/Modal/Confirmation';
import { Icon, Text, Spacer, Tooltip } from 'ravenry-ui';
import colors from 'ui/colors';
import popup_cancel from 'ui/assets/popup_cancel.svg';

interface SaveFreelancerProps {
  saved?: boolean;
  onRemove: Function;
  onSave: Function;
  _id: string;
  withText?: boolean;
}

interface ContainerProps {
  withText?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  saved?: boolean;
}

const Container = styled.div<ContainerProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 0;
  position: relative;
  border-radius: 6px;
  height: 28px;
  width: fit-content;

  background: ${({ withText }) => (withText ? colors.backgroundLight : 'none')};

  &:hover {
    background: ${colors.black5};
  }

  &:hover > div > p {
    color: ${colors.black};
  }

  &:hover > div > svg {
    ${({ saved }) => (saved ? '' : `fill: ${colors.black};`)}
  }
`;

export default function SaveFreelancer(props: SaveFreelancerProps) {
  const { saved, onRemove, onSave, _id, withText } = props;

  const [modal, setModal] = useState({ open: false });

  const getLeftPosition = () => {
    if (saved && withText) {
      return '-33px';
    }

    if (saved && !withText) {
      return '-61px';
    }

    if (!saved && withText) {
      return '-27px';
    }

    if (!saved && !withText) {
      return '-51px';
    }
  };

  const position = {
    top: '-40px',
    left: getLeftPosition(),
  };

  return (
    <>
      <Container
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();
          if (saved) {
            setModal({ ...modal, open: true });
          } else {
            onSave(_id);
          }
        }}
        withText={withText}
        saved={saved}
      >
        <Tooltip
          position={position}
          arrowPosition="bottom-center"
          content={saved ? 'Already on My Freelancers' : 'Add to My Freelancers'}
        >
          <Spacer size={withText ? '10' : '4'} horizontal />

          <Icon
            name={saved ? 'favourite' : 'favourite empty'}
            fill={saved ? 'red' : 'black50'}
            hoverFill={saved ? 'red' : 'black'}
          />

          {withText ? (
            <>
              <Spacer size="4" horizontal />
              <Text size="14px" lineHeight="16px" bold color="black50">
                {saved ? 'Saved' : 'Save'}
              </Text>
            </>
          ) : null}

          <Spacer size={withText ? '10' : '4'} horizontal />
        </Tooltip>
      </Container>

      <ConfirmationModal
        open={modal.open}
        title="Remove from My Freelancers"
        titleOptions={{ color: 'red' }}
        subtitle={[
          'Are you sure?',
          'Once a freelancer has been removed,',
          'you might not be able to connect with them easily.',
        ]}
        img={{ src: popup_cancel, alt: 'remove freelancer' }}
        primary={{
          color: 'red',
          text: 'Cancel',
          onClick: (e: React.SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();

            setModal({ ...modal, open: false });
          },
        }}
        ghost={{
          color: 'red',
          text: "Yes, I'm sure",
          onClick: (e: React.SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();

            onRemove(_id);
            setModal({ ...modal, open: false });
          },
        }}
      />
    </>
  );
}
