import styled, { css } from 'styled-components';
import colors from 'ui/colors';

export const Container = styled.div`
  /* width: ${(props) => props.width && props.width}; */
  width: 100%;
  padding: 0;
  position: relative;
  ${(props) =>
    props.inline
      ? css`
          display: flex;
          align-items: center;
        `
      : css``};
`;

export const Label = styled.label`
  text-transform: uppercase;
  line-height: 16px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  display: block;
  margin: 8px 0px;
  ${(props) =>
    props.inline &&
    css`
      width: 200px;
    `};

  color: ${colors.black40};
  &::after {
    margin-top: 20px;
  }
`;

export const InputDisabledContainer = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid rgb(168, 169, 170);
  border-radius: 5px;
  padding: 2px 16px;
  background-color: #f3f9fe;
  width: auto;
  height: 40px;
`;

export const InputDisabled = styled.input`
  border: none;
  font-size: 14px;
  line-height: 14px;
  height: 100%;
  width: 100%;
  color: rgb(37, 40, 43);
  background-color: transparent;
`;

export const Error = styled.div`
  font-size: 10px;
  color: ${colors.red};
  margin-top: 8px;
  left: 0;
  position: absolute;
`;

export const InputContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  width: 250px;
`;

export const Warning = styled.div`
  position: absolute;
  right: 16px;
  top: 10px;
`;

export const CustomInput = styled.input`
  padding: ${(props) => (props.icon ? '11px 47px 11px 16px' : '11px 16px')};
  width: 100%;
  border-radius: 5px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */

  ${(props) =>
    props.error
      ? css`
          border: 1px solid ${colors.red};
          color: ${colors.red};
          &::placeholder {
            color: ${colors.red};
            font-size: 14px;
          }
        `
      : css`
          border: 1px solid ${colors.black10};
          color: ${colors.black};
          &::placeholder {
            color: ${colors.black20};
            font-size: 14px;
          }
        `}

  &:focus {
    outline-color: none;
    outline-style: none;
    outline-width: 1px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
