import { useEffect } from 'react';

/**
 * useOutsideClickMultiple detects click outside specified multiple elements
 * @TODO need to combine this to useOutsideClick that handle single element
 *
 * @param {Object | Array} ref HTML ref
 * @param {Function} callback
 */
const useOutsideClickMultiple = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && ref.current.length) {
      let isInside = false;

      Array.from(ref.current).map((innerRef) => {
        if (innerRef?.contains(e.target)) {
          isInside = true;
        }

        return null;
      });

      if (!isInside) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClickMultiple;
