import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import colors from 'ui/colors';
import { Label, Input, Spacer, Text, Row, Button } from 'ravenry-ui';
import { Fields, AttachmentBox } from 'ui/molecules';
import { regions } from 'store/constants/Constants';
import moment from 'moment';
import _ from 'lodash';

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.data > 4 ? 'row' : 'column')};
  width: 100%;
  margin: 0px -8px;
`;

const GridItem = styled.label`
  margin: 8px;
  display: flex;
  align-items: center;
  width: 47%;
  height: ${({ height }) => height || '32px'};

  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  padding: 8px;
  background-color: #ffffff;
  ${(props) =>
    props.error
      ? css`
          border: 1px solid ${colors.red};
          color: ${colors.black};
        `
      : props.checked
      ? css`
          border: 1px solid ${colors.blue};
          color: ${colors.black};
        `
      : css`
          border: 1px solid ${colors.black10};
          color: ${colors.black40};
        `}

  &:hover {
    border: 1px solid ${colors.blue};
    color: ${colors.black};
    div:first-child div svg rect:first-child {
      stroke: ${colors.blue};
    }
    div:first-child div svg circle:first-child {
      stroke: ${colors.blue};
    }
  }
`;

function Default({ error, errorMessage, answer, mobile, ...rest }) {
  return (
    <Fields.Text
      error={error && errorMessage}
      {...rest}
      _as="s5"
      bold
      color="black100"
      value={answer}
      fluid
      height={mobile ? '50px' : undefined}
      borderRadius={mobile ? '10px' : undefined}
    />
  );
}

function DateComponent({ error, errorMessage, ...rest }) {
  return <Input.DatePicker error={error && errorMessage} {...rest} fluid />;
}

function Currency(props) {
  const {
    answer,
    onChange,
    onFocus,
    name,
    _id,
    placeholder,
    label,
    labelUppercase = true,
    subtitle,
    error,
    errorMessage,
    type,
    required = false,
    dataCy,
    mobile,
  } = props;

  const options = [
    { label: 'Yes, I do', value: 'Yes, I do' },
    { label: "No, I don't", value: "No, I don't" },
  ];

  const [showCurrencyInput, setShowCurrencyInput] = useState(answer && answer !== '-');

  return (
    <div>
      {label && (
        <>
          <Label _as="s4" color="black100" bold required={required}>
            {labelUppercase ? label.toUpperCase() : label}
          </Label>

          {subtitle ? (
            <>
              <Spacer size="4" display="block" />
              <Text _as="s6" color="black80" isHtml>
                {subtitle}
              </Text>
            </>
          ) : null}

          <Spacer size="8" display="block" />
        </>
      )}

      <div className="flex flex-col">
        {/* <Input.Select
          style={{ width: mobile ? '100%' : '300px' }}
          value={showCurrencyInput ? options[0] : options[1]}
          options={options}
          onChange={(e) => {
            setShowCurrencyInput(e.value === options[0].value);
            if (e.value === options[1].value) {
              onChange({ target: { name, value: '-', type, doneFilling: true } });
            } else {
              onChange({ target: { name, value: '' } });
            }
          }}
          name={name}
          fluid
          dataCy={'enable-' + dataCy}
          dropdownCustomStyle={{
            borderRadius: mobile ? '10px' : undefined,
            height: mobile ? '50px' : undefined,
            optionPaddingTop: mobile ? 16 : undefined,
          }}
        />
        {!mobile && <div className="block h-4 w-2"></div>} */}

        {/* {showCurrencyInput && ( */}
          <Row width={mobile ? '100%' : '380px'} className={mobile && 'mt-2'}>
            {/* <Input.Select
              style={{ width: 100 }}
              value={{ label: 'USD' }}
              options={[{ value: 'usd', label: 'USD' }]}
              fluid
              dropdownCustomStyle={{
                borderRadius: mobile ? '10px' : undefined,
                height: mobile ? '50px' : undefined,
                optionPaddingTop: mobile ? 16 : undefined,
              }}
            />
            <Spacer size="16" /> */}
            <div className={mobile && 'flex-grow'}>
              <Fields.Text
                placeholder={placeholder}
                onChange={(e) =>
                  e.target.value >= 0 && onChange({ target: { value: e.target.value, name, type } })
                }
                onBlur={(e) =>
                  +e.target.value >= 0 &&
                  onChange({ target: { value: e.target.value, name, type, doneFilling: true } })
                }
                onFocus={onFocus}
                name={name}
                id={_id}
                width={mobile ? '100%' : '330px'}
                type="number"
                value={answer}
                maxLength="150"
                error={error && errorMessage}
                data-cy={'input-' + dataCy}
                height={mobile ? '50px' : undefined}
                borderRadius={mobile ? '10px' : undefined}
              />
            </div>
          </Row>
        {/* )} */}
      </div>
    </div>
  );
}

function Percentage(props) {
  const {
    answer,
    onChange,
    onFocus,
    name,
    _id,
    placeholder,
    label,
    labelUppercase = true,
    subtitle,
    error,
    errorMessage,
    type,
    required = false,
    dataCy,
    mobile,
  } = props;


  const [showCurrencyInput, setShowCurrencyInput] = useState(answer && answer !== '-');

  return (
    <div>
      {label && (
        <>
          <Label _as="s4" color="black100" bold required={required}>
            {labelUppercase ? label.toUpperCase() : label}
          </Label>

          {subtitle ? (
            <>
              <Spacer size="4" display="block" />
              <Text _as="s6" color="black80" isHtml>
                {subtitle}
              </Text>
            </>
          ) : null}

          <Spacer size="8" display="block" />
        </>
      )}

      <div className="flex flex-col">
    
          <Row width={mobile ? '100%' : '380px'} className={mobile && 'mt-2'}>
          
            <div className={`${mobile && ' flex-grow '}  relative`}>
              <Fields.Text
                placeholder={placeholder}
                onChange={(e) =>
                  e.target.value >= 0 && onChange({ target: { value: e.target.value, name, type } })
                }
                onBlur={(e) =>
                  +e.target.value >= 0 && +e.target.value <= 100 &&
                  onChange({ target: { value: e.target.value, name, type, doneFilling: true } })
                }
                onFocus={onFocus}
                name={name}
                id={_id}
                width={mobile ? '100%' : '330px'}
                type="number"
                value={answer}
                maxLength="150"
                error={error && errorMessage}
                data-cy={'input-' + dataCy}
                height={mobile ? '50px' : undefined}
                borderRadius={mobile ? '10px' : undefined}
              /> <span className='absolute top-2 right-4'>%</span>
            </div>
          </Row>
        
      </div>
    </div>
  );
}


function Options(props) {
  const {
    options,
    answer,
    onChange,
    name,
    _id,
    label,
    labelUppercase = true,
    placeholder,
    subtitle,
    required = false,
    errorMessage,
    error,
    disabled,
    dataCy,
    type,
    mobile,
  } = props;

  return (
    <div style={{ width: name === 'duration' ? '300px' : '100%' }}>
      {label && (
        <>
          <Label _as="s4" bold color="black100" required={required}>
            {labelUppercase ? label.toUpperCase() : label}
          </Label>

          {subtitle ? (
            <>
              <Spacer size="4" display="block" />
              <Text _as="s6" color="black80">
                {subtitle}
              </Text>
            </>
          ) : null}
          <Spacer size="8" display="block" />
        </>
      )}
      <Input.Select
        value={options.filter((opt) => opt.value === answer)}
        options={options}
        onChange={(e) => onChange({ target: { value: e.value, name, type } })}
        _id={_id}
        name={name}
        fluid
        disabled={disabled}
        dataCy={dataCy}
        label={label}
        placeholder={placeholder}
        error={error}
        dropdownCustomStyle={{
          borderRadius: mobile ? '10px' : undefined,
          height: mobile ? '50px' : undefined,
          optionPaddingTop: mobile ? 16 : undefined,
        }}
      />
      {error ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="b3" color="red">
            {errorMessage}
          </Text>
        </>
      ) : null}
    </div>
  );
}

function Location(props) {
  const {
    subtitle,
    required = false,
    error,
    errorMessage,
    label,
    labelUppercase = true,
    mobile,
  } = props;

  const regOptions = regions.map((option) => ({
    label: option.name,
    value: option.value,
  }));

  return (
    <div>
      {label && (
        <>
          <Label _as="s4" color="black100" bold required={required}>
            {labelUppercase ? label.toUpperCase() : label}
          </Label>
          {subtitle ? (
            <>
              <Spacer size="4" display="block" />
              <Text _as="s6" color="black80">
                {subtitle}
              </Text>
            </>
          ) : null}
          <Spacer size="8" display="block" />
        </>
      )}
      <Input.Select
        {...props}
        error={error}
        options={regOptions}
        onChange={(e) => props.onChange({ target: { value: e.value, name: props.name } })}
        dropdownCustomStyle={{
          borderRadius: mobile ? '10px' : undefined,
          height: mobile ? '50px' : undefined,
          optionPaddingTop: mobile ? 16 : undefined,
        }}
      />
      {error ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="b3" color="red">
            {errorMessage}
          </Text>
        </>
      ) : null}
    </div>
  );
}

function RichText(props) {
  return (
    <Fields.FieldArea
      isHtml
      {...props}
      _as="s5"
      withFormik={false}
      bold
      color="black100"
      description={props.subtitle}
      fluid
      error={props.error && props.errorMessage}
      data-cy={'input-' + props.dataCy}
      borderRadius={props.borderRadius}
    >
      {props.answer}
    </Fields.FieldArea>
  );
}

function Choice(props) {
  const {
    options = [],
    answer,
    onChange,
    name,
    label,
    labelUppercase = true,
    subtitle,
    isOtherChecked,
    required = false,
    error,
    errorMessage,
    type,
    mobile,
  } = props;

  const handleClick = (val) => {
    if (val === 'other') {
      const e = {
        target: {
          name,
          value: { type: 'other', value: '' },
          type,
        },
      };

      onChange(e, true);
    } else {
      const e = {
        target: {
          name,
          value: val,
          type,
          doneFilling: true,
        },
      };

      onChange(e);
    }
  };

  console.log(options)
  console.log(answer)

  return (
    <>
      <Label _as="s5" color="black100" bold required={required}>
        {labelUppercase ? label.toUpperCase() : label}
      </Label>
      {subtitle ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="s6" color="black80" isHtml>
            {subtitle}
          </Text>
        </>
      ) : null}
      <Spacer size="8" display="block" />
      <Grid data={options.length}>
        {options.map((opt, i) => (
          <GridItem
            data={options.length}
            checked={answer === opt.value || (opt.value === 'other' && isOtherChecked)}
            key={`opt-${i}`}
            error={error}
            height={mobile ? '50px' : undefined}
            borderRadius={mobile ? '10px' : undefined}
          >
            <Input.Radio
              checked={answer === opt.value || (opt.value === 'other' && isOtherChecked)}
              onChange={() => handleClick(opt.value)}
            />

            <Spacer size="8" />

            {opt.label}
          </GridItem>
        ))}
      </Grid>
      {isOtherChecked ? (
        <>
          <Spacer size="8" display="block" />
          <Fields.Text
            value={answer.value}
            onChange={(e) =>
              onChange(
                {
                  target: {
                    name,
                    value: { type: 'other', value: e.target.value },
                    type,
                  },
                },
                true,
              )
            }
            onBlur={(e) => {
              onChange(
                {
                  target: {
                    name,
                    value: { type: 'other', value: e.target.value },
                    type,
                    doneFilling: true,
                  },
                },
                true,
              );
            }}
            fluid
            height={mobile ? '50px' : undefined}
            borderRadius={mobile ? '10px' : undefined}
          />
        </>
      ) : null}
      {error ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="b3" color="red">
            {errorMessage}
          </Text>
        </>
      ) : null}
    </>
  );
}

function Multiple(props) {
  const {
    options = [],
    answer = [],
    onChange,
    name,
    label,
    labelUppercase = true,
    subtitle,
    isOtherChecked,
    maxLimit = 3,
    required = false,
    error,
    errorMessage,
    type,
    mobile,
  } = props;

  const handleClick = (val) => {
    const isOtherClicked = val === 'other';
    const isAlreadyChecked = isOtherClicked
      ? answer.find((ans) => ans.type === 'other')
      : answer.includes(val);

    if (answer.length < maxLimit || isAlreadyChecked) {
      if (isOtherClicked) {
        const updatedAnswer = isAlreadyChecked
          ? answer.filter((ans) => ans.type !== 'other')
          : [...answer, { type: 'other', value: '' }];

        onChange(
          {
            target: { name, value: updatedAnswer, type, doneFilling: true },
          },
          !isOtherChecked,
        );
      } else {
        const updatedAnswer = isAlreadyChecked
          ? answer.filter((ans) => ans !== val)
          : [...answer, val];

        onChange(
          {
            target: { name, value: updatedAnswer, type, doneFilling: true },
          },
          isOtherChecked,
        );
      }
    }
  };

  const handleOtherType = (e, final) => {
    const updatedAnswer = [...answer];
    const otherAnswerIndex = updatedAnswer.findIndex((ans) => ans.type === 'other');
    updatedAnswer[otherAnswerIndex] = {
      ...updatedAnswer[otherAnswerIndex],
      value: e.target.value,
    };

    onChange(
      {
        target: {
          name,
          value: updatedAnswer,
          type,
          doneFilling: final,
        },
      },
      true,
    );
  };

  const otherAnswer = answer.find((ans) => ans.type === 'other')?.value || '';

  return (
    <>
      <Label _as="s5" color="black100" bold required={required}>
        {labelUppercase ? label.toUpperCase() : label}
      </Label>
      {subtitle ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="s6" color="black80">
            {subtitle}
          </Text>
        </>
      ) : null}
      <Spacer size="8" display="block" />
      <Grid data={options.length}>
        {options.map((dat, i) => {
          const selected = answer.filter((ans) => ans === dat.value);

          return (
            <GridItem
              onClick={(e) => {
                e.preventDefault();
                handleClick(dat.value);
              }}
              data={options.length}
              checked={selected[0] || (dat.value === 'other' && isOtherChecked)}
              key={`opt-${i}`}
              error={error}
              height={mobile ? '50px' : undefined}
              borderRadius={mobile ? '10px' : undefined}
            >
              <Input.Check checked={selected[0] || (dat.value === 'other' && isOtherChecked)} />
              <Spacer size="8" />
              <div>{dat.label}</div>
            </GridItem>
          );
        })}
      </Grid>
      {isOtherChecked ? (
        <>
          <Spacer size="8" display="block" />
          <Fields.Text
            value={otherAnswer}
            onChange={(e) => {
              handleOtherType(e, false);
            }}
            onBlur={(e) => {
              handleOtherType(e, true);
            }}
            fluid
            height={mobile ? '50px' : undefined}
            borderRadius={mobile ? '10px' : undefined}
          />
        </>
      ) : null}
      {error ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="b3" color="red">
            {errorMessage}
          </Text>
        </>
      ) : null}
    </>
  );
}

function Subtask(props) {
  const {
    answer = [],
    onChange,
    name,
    label,
    labelUppercase = true,
    subtitle,
    required = false,
    error,
    errorMessage,
    type,
    mobile,
  } = props;

  const setValue = (e) => {
    const temp_e = {
      target: {
        name,
        value: e,
        type,
      },
    };

    if (!_.isEqual(e, answer)) {
      onChange(temp_e);
    }
  };
  return (
    <div>
      <Label _as="s5" color="black100" bold required={required}>
        {labelUppercase ? label.toUpperCase() : label}
      </Label>
      {subtitle ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="s6" color="black80">
            {subtitle}
          </Text>
        </>
      ) : null}
      <Spacer size="8" display="block" />

      <Input.Multiple
        {...props}
        list="data"
        fluid
        value={answer || []}
        editable
        onGetValue={(e) => setValue(e)}
        inputHeight={mobile ? '50px' : undefined}
        inputBorderRadius={mobile ? '10px' : undefined}
      />
      {error ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="b3" color="red">
            {errorMessage}
          </Text>
        </>
      ) : null}
    </div>
  );
}

function Tags(props) {
  const {
    answer = [],
    onChange,
    name,
    label,
    labelUppercase = true,
    subtitle,
    required = false,
    error,
    errorMessage,
    type,
    mobile,
  } = props;

  const setValue = (e) => {
    const temp_e = {
      target: {
        name,
        value: e,
        type,
      },
    };

    if (!_.isEqual(e, answer)) {
      onChange(temp_e);
    }
  };
  return (
    <div>
      <Label _as="s5" color="black100" bold required={required}>
        {labelUppercase ? label.toUpperCase() : label}
      </Label>
      {subtitle ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="s6" color="black80">
            {subtitle}
          </Text>
        </>
      ) : null}
      <Spacer size="8" display="block" />

      <Input.Multiple
        {...props}
        list="data"
        fluid
        value={answer || []}
        editable
        onGetValue={(e) => setValue(e)}
        inputHeight={mobile ? '50px' : undefined}
        inputBorderRadius={mobile ? '10px' : undefined}
      />
      {error ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="b3" color="red">
            {errorMessage}
          </Text>
        </>
      ) : null}
    </div>
  );
}

function FileUpload(props) {
  const {
    name,
    label,
    labelUppercase = true,
    subtitle,
    answer,
    documents,
    required = false,
    onBlur,
    error,
    errorMessage,
    dataCy,
    fluid,
    mobile,
  } = props;

  // to accommodate multiple files in single question
  const cleanedAnswer = typeof answer === 'string' ? [answer] : Array.isArray(answer) ? answer : [];

  const documentsForThisQuestion =
    documents?.filter((document) => cleanedAnswer.includes(document._id)) || [];

  const [filesToUpload, setFilesToUpload] = useState([]);

  const textTypeAnswer = cleanedAnswer.find((ans) => ans.type === 'text') || {
    value: '',
    type: 'text',
  };

  const text = textTypeAnswer.value;

  const handleChange = (newFiles) => {
    if (newFiles.length > 0) {
      const newFilesToUpload = newFiles.map((newFile) => ({
        name: newFile.name,
        mimeType: newFile.type,
        file: newFile,
      }));

      setFilesToUpload(newFilesToUpload);

      const temp_e = {
        target: {
          name,
          filesToUpload: newFilesToUpload,
          type: 'file',
        },
      };

      props.onChange(temp_e);
      setFilesToUpload([]);
    }
  };

  const handleRemove = (attachmentId) => {
    const temp_e = {
      target: {
        name,
        fileToDelete: attachmentId,
        type: 'file',
      },
    };

    props.onChange(temp_e);
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeText = (e) => {
    const temp_e = {
      target: {
        name,
        value: e.target.value,
        type: 'file',
      },
    };

    props.onChange(temp_e);
  };

  const combinedDocuments = mobile
    ? cleanedAnswer
    : [...documentsForThisQuestion, ...filesToUpload];

  return (
    <>
      {label ? (
        <Label _as="s5" color="black100" bold required={required}>
          {labelUppercase ? label.toUpperCase() : label}
        </Label>
      ) : null}
      {subtitle ? (
        <>
          <Spacer size="4" display="block" />
          <Text _as="b3" color="black80" isHtml>
            {subtitle}
          </Text>
        </>
      ) : null}

      <Spacer size="8" display="block" />

      {!mobile && (
        <>
          <Fields.Text
            placeholder="Please enter the document URL"
            fluid
            error={error && errorMessage}
            value={text}
            onChange={handleChangeText}
            onBlur={(e) =>
              onBlur({
                target: {
                  name,
                  value: e.target.value,
                  type: 'file',
                },
              })
            }
            data-cy={dataCy + '-url'}
          />
          <Spacer size="16" display="block" />
          <Text _as="s6" color="black80" isHtml>
            And/or attach the reference document
          </Text>
          <Spacer size="16" />
        </>
      )}

      {combinedDocuments.length > 0 && (
        <>
          {combinedDocuments.map((doc) =>
            doc._id ? (
              <AttachmentBox
                customMargin="0 0 8px 0"
                key={`attachment-${doc._id}`}
                title={doc.name}
                linkUrl={doc.url}
                fileSize={doc.size}
                onDelete={() => handleRemove(doc._id)}
                mimeType={doc.mimeType}
                backgroundColor="white"
              />
            ) : (
              <AttachmentBox
                customMargin="0 0 8px 0"
                key={`attachment-${doc.name}`}
                title={doc.name}
                linkUrl={URL.createObjectURL(doc.file)}
                fileSize={doc.file.size}
                onDelete={() => handleRemove(doc._id)}
                mimeType={doc.mimeType}
                backgroundColor="white"
              />
            ),
          )}
          <Spacer size="16" display="block" />
        </>
      )}

      <Button
        startIcon="plus"
        color="purple"
        width="204px"
        startIcon={mobile ? 'upload' : undefined}
        iconFill="white"
        height={mobile ? '50px' : undefined}
        fluid={fluid}
        onClick={handleClick}
        rounded={mobile ? '10px' : undefined}
      >
        {mobile ? 'Upload file' : 'Attach file'}
      </Button>

      <input
        type="file"
        ref={hiddenFileInput}
        onClick={(e) => {
          e.target.value = null;
        }}
        style={{ display: 'none' }}
        onChange={(e) => handleChange([...e.target.files])}
        multiple
      />
    </>
  );
}

function Deadline(props) {
  const {
    label,
    labelUppercase = true,
    name,
    placeholder,
    answer,
    onChange,
    required = false,
    type,
    mobile,
    subtitle,
  } = props;
  const options = [
    { label: 'Yes, I do', value: 'Yes, I do' },
    { label: "No, I don't", value: "No, I don't" },
  ];

  const showDatePicker = answer && answer !== '-';

  return (
    <div>
      {label && (
        <>
          <Label _as="s4" bold required={required}>
            {labelUppercase ? label.toUpperCase() : label}
          </Label>
          {subtitle && (
            <>
              <Spacer size="4" display="block" />
              <Text _as="s6" color="black80">
                {subtitle}
              </Text>
            </>
          )}

          <Spacer size="8" display="block" />
        </>
      )}

      <div className="flex flex-col">
        <Input.Select
          style={{ width: mobile ? '100%' : '300px' }}
          value={showDatePicker ? options[0] : options[1]}
          options={options}
          onChange={(e) => {
            const newAnswer =
              e.value === 'Yes, I do' ? moment().add(2, 'days').format('dddd, D MMM YYYY') : '-';
            onChange({ target: { name, value: newAnswer, type } });
          }}
          name={name}
          fluid
          dropdownCustomStyle={{
            borderRadius: mobile ? '10px' : undefined,
            height: mobile ? '50px' : undefined,
            optionPaddingTop: mobile ? 16 : undefined,
          }}
        />
        {!mobile && <div className="block h-4 w-2"></div>}

        {showDatePicker && (
          <div style={{ width: mobile ? '100%' : '340px' }} className={mobile && 'mt-2'}>
            <DateComponent
              style={{ margin: 0 }}
              height={mobile ? '50px' : undefined}
              borderRadius={mobile ? '10px' : undefined}
              iconMarginTop={mobile ? '8px' : undefined}
              name={name}
              onChange={onChange}
              value={answer}
              position="center"
              placeholder={placeholder}
              atLeast={moment().add(2, 'days')} // at least +2 days from today
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default function InputSelector(props) {
  const { key, answer, type, options, brancher } = props.input;

  const {
    onChange,
    onBlur,
    onFocus,
    handleChangeBranch,
    settings = {},
    documents,
    typeQuestion,
    _id,
    label,
    labelUppercase = true,
    placeholder,
    height,
    fluid,
    mobile,
  } = props;

  const handleChange = (...e) => {
    if (brancher) {
      handleChangeBranch(...e);
    }
    if (onChange) {
      onChange(...e);
    }
  };

  const handleBlur = (...e) => {
    if (onBlur) {
      onBlur(...e, props.input?.required);
    }
  };

  const handleFocus = (...e) => {
    if (onFocus) {
      onFocus(...e, props.input?.required);
    }
  };

  return (
    <div data-cy={typeQuestion}>
      {type === 'date' ? (
        <DateComponent
          name={key}
          _id={_id}
          onChange={onChange}
          value={answer}
          label={label}
          labelUppercase={labelUppercase}
          position="left center"
          placeholder={placeholder}
          atLeast={new Date()} // at least today
        />
      ) : type === 'choice' ? (
        <Choice
          mobile={mobile}
          name={key}
          {...props.input}
          options={options}
          onChange={handleChange}
          labelUppercase={labelUppercase}
        />
      ) : type === 'number' ? (
        <Currency
          name={key}
          {...props.input}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          dataCy={key}
          mobile={mobile}
          labelUppercase={labelUppercase}
        />
      ) : type === 'percentage' ? (
        <Percentage
          name={key}
          {...props.input}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          dataCy={key}
          mobile={mobile}
          labelUppercase={labelUppercase}
        />
      ) : type === 'options' || type === 'dropdown' ? (
        <Options
          name={key}
          dataCy={key.replace('_', '-')}
          {...props.input}
          onChange={handleChange}
          labelUppercase={labelUppercase}
          mobile={mobile}
        />
      ) : type === 'yesno' ? (
        ''
      ) : type === 'file' ? (
        <FileUpload
          name={key}
          answer={answer}
          documents={documents}
          {...props.input}
          onChange={handleChange}
          onBlur={handleBlur}
          dataCy={key}
          height={height}
          fluid={fluid}
          labelUppercase={labelUppercase}
          mobile={mobile}
        />
      ) : type === 'multiple' ? (
        <Multiple
          name={key}
          {...props.input}
          options={options}
          onChange={handleChange}
          maxLimit={settings.multiple?.maxLimit}
          mobile={mobile}
          labelUppercase={labelUppercase}
        />
      )  : type === 'subtask' ? (
        <Subtask
          name={key}
          {...props.input}
          options={options}
          onChange={handleChange}
          maxLimit={settings.multiple?.maxLimit}
          mobile={mobile}
          labelUppercase={labelUppercase}
        />
      ) : type === 'tags' ? (
        <Tags
          name={key}
          {...props.input}
          onChange={handleChange}
          onBlur={undefined}
          onFocus={handleFocus}
          labelUppercase={labelUppercase}
          mobile={mobile}
        />
      ) : type === 'richtext' ? (
        <RichText
          name={key}
          {...props.input}
          onChange={handleChange}
          onBlur={handleBlur}
          dataCy={key}
          labelUppercase={labelUppercase}
          borderRadius={mobile ? '10px' : undefined}
        />
      ) : type === 'location' ? (
        <Location
          name={key}
          {...props.input}
          onChange={handleChange}
          onBlur={handleBlur}
          labelUppercase={labelUppercase}
          mobile={mobile}
        />
      ) : type === 'deadline' ? (
        <Deadline
          mobile={mobile}
          name={key}
          {...props.input}
          onChange={handleChange}
          onBlur={handleBlur}
          labelUppercase={labelUppercase}
        />
      ) : (
        <Default
          name={key}
          {...props.input}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          data-cy={`input-${key}`}
          labelUppercase={labelUppercase}
          mobile={mobile}
        />
      )}

      <Spacer size="24" display="block" />
    </div>
  );
}
