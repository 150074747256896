import React, { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth, logout, login } from './helper/firebase';
import IamApi from 'helper/iamApi';
import './App.css';

export default function FirebaseWrapper(props: PropsWithChildren<{}>) {
  const [user, loading, error] = useAuthState(auth);
  const global = useSelector((state: any) => state.global);
  const iamApi = new IamApi();

  useEffect(() => {
    if (!loading) {
      if (user) {
        if (!global.isLoggedIn || user.uid !== global.user.userId) {
          logout();
        }
      } else {
        if (global.isLoggedIn) {
          iamApi
            .getFirebaseToken()
            .then((res) => {
              login(res.data.token);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    }
  }, [user, loading, error, global.isLoggedIn]);

  return <>{props.children}</>;
}
